// @flow

import { deleteApi, getApi, patchRaw, postRaw } from './api.core'

export const getActivities = params => getApi('api/pipeline/activity/', params)
export const getActivity = id => getApi(`api/pipeline/activity/${id}/`)
export const createActivity = params =>
  postRaw('api/pipeline/activity/', params)
export const updateActivity = (id, params) =>
  patchRaw(`api/pipeline/activity/${id}/`, params)

export const getActivityTypes = params =>
  getApi('api/pipeline/activity-type/', params)
export const getSalutation = params => getApi('api/salutation/', params)
export const createSalutation = params => postRaw('api/salutation/', params)
export const updateSalutation = (id, params) =>
  patchRaw(`api/salutation/${id}/`, params)
export const deleteSalutation = id => deleteApi(`api/salutation/${id}/`)
export const getActivityType = id => getApi(`api/pipeline/activity-type/${id}/`)
export const createActivityType = params =>
  postRaw('api/pipeline/activity-type/', params)
export const updateActivityType = (id, params) =>
  patchRaw(`api/pipeline/activity-type/${id}/`, params)

export const getActivityPriorities = params =>
  getApi('api/pipeline/activity-priority/', params)
export const getActivityPriority = id =>
  getApi(`api/pipeline/activity-priority/${id}/`)
export const updateActivityPriority = (id, params) =>
  patchRaw(`api/pipeline/activity-priority/${id}/`, params)
export const createActivityPriority = params =>
  postRaw('api/pipeline/activity-priority/batch/', params)

export const getActivityTypeIcons = () =>
  getApi('api/pipeline/activity-type-icon/', { page_size: 100500 })

export const getRequestPipeline = requestId =>
  getApi(`api/request/${requestId}/pipeline/`)

export const getPhase = phaseId => getApi(`api/pipeline/phase/${phaseId}/`)

export const getPhaseActivities = phaseId =>
  getApi(`api/pipeline/phase/${phaseId}/activity/`)

export const togglePhaseVisibility = id =>
  postRaw(`api/pipeline/phase/${id}/toggle-is-visible-to-tenant/`)

export const populatePhase = id => postRaw(`api/pipeline/phase/${id}/populate/`)

export const getPhaseFiles = id => getApi(`api/pipeline/phase/${id}/files/`)
export const updatePhase = (id, params) =>
  patchRaw(`api/pipeline/phase/${id}/`, params)
export const getActivityFiles = id =>
  getApi(`api/pipeline/activity/${id}/files/`)

export const getPipelineTemplates = params =>
  getApi('api/pipeline/pipeline-template/', params)
export const getPipelineTemplate = pipelineId =>
  getApi(`api/pipeline/pipeline-template/${pipelineId}/`)
export const createPipelineTemplate = params =>
  postRaw('api/pipeline/pipeline-template/', params)
export const updatePipelineTemplate = (pipelineId, params) =>
  patchRaw(`api/pipeline/pipeline-template/${pipelineId}/`, params)
export const deletePipelineTemplate = pipelineId =>
  deleteApi(`api/pipeline/pipeline-template/${pipelineId}/`)

export const getPhaseTemplates = pipelineId =>
  getApi(`api/pipeline/pipeline-template/${pipelineId}/phase/`)
export const getAllPhaseTemplates = params =>
  getApi('api/pipeline/phase-template/', params)
export const createPhaseTemplate = params =>
  postRaw('api/pipeline/phase-template/', params)
export const updatePhaseTemplate = (phaseId, params) =>
  patchRaw(`api/pipeline/phase-template/${phaseId}/`, params)
export const deletePhaseTemplate = phaseId =>
  deleteApi(`api/pipeline/phase-template/${phaseId}/`)

export const getActivityTemplates = ({ phaseId, page_size }) =>
  getApi(`api/pipeline/phase-template/${phaseId}/activity/`, { page_size })
export const createActivityTemplate = params =>
  postRaw('api/pipeline/activity-template/', params)
export const updateActivityTemplate = (activityId, params) =>
  patchRaw(`api/pipeline/activity-template/${activityId}/`, params)
export const deleteActivityTemplate = activityId =>
  deleteApi(`api/pipeline/activity-template/${activityId}/`)

export const getRequestTypes = params =>
  getApi('api/pipeline/pipeline-template/request-category/', params)
