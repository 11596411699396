// @flow

import React from 'react'
import { push } from 'connected-react-router'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { isNumber, keys, values, has } from 'lodash-es'

import { getLocation } from '../../../utils/commonSelectors'
import {
  formatPathname,
  getCurrentFilters,
  getNewQueryString,
} from '../../../utils/routing'
import {
  FILTERS_ALIASES,
  MAIL_PATH_KEY,
  PAGES_ALIASES,
  SUBMENU_COUNTERS,
} from '../Menu.constants'
import { updateCountersAction } from '../../Layout/Layout.actions'

import styles from './MenuSubListItem.module.scss'

const MenuSubListItem = props => {
  const {
    active,
    counters,
    isPseudo,
    item,
    location,
    menuName,
    currentTab,
    mailFolder,
  } = props
  const textClass = classnames(styles.text, 'menu__filter-text', {
    'menu__filter-text--active': active,
  })

  const conterItems = SUBMENU_COUNTERS[menuName]

  const counter =
    conterItems &&
    !(has(conterItems, 'tab') && currentTab !== conterItems.tab) &&
    counters[conterItems[item.name]]

  const itemClassNames = classnames(styles.item, 'menu__filter')
  const counterClass = classnames(
    styles.counter,
    'menu__filter-text',
    'menu__filter-counter-text'
  )

  const handleClick = () => {
    const filters = getCurrentFilters(location)
    let resolvedMenuName = menuName
    let pathname = formatPathname(location.pathname)

    if (isPseudo) {
      resolvedMenuName = PAGES_ALIASES[menuName]
      pathname = resolvedMenuName

      if (filters.tab) {
        pathname = `${pathname}/${filters.tab}`
      }
    }

    keys(
      values(FILTERS_ALIASES[resolvedMenuName]).reduce((acc, key) => {
        return { ...acc, ...key }
      }, {})
    ).forEach(item => (filters[item] = undefined))

    // HACK
    let credentials = {}

    if (resolvedMenuName === MAIL_PATH_KEY && filters.credentials) {
      credentials = { credentials: filters.credentials }
    }

    let account = {}

    if (resolvedMenuName === MAIL_PATH_KEY && filters.account) {
      account = { account: filters.account }
    }

    let folder_external_ids = {}

    if (resolvedMenuName === MAIL_PATH_KEY && mailFolder.id) {
      folder_external_ids =
        item.name === mailFolder.display_name
          ? { folder_external_ids: mailFolder.external_id }
          : { exclude_folder_external_ids: mailFolder.external_id }
    }

    const search = getNewQueryString({
      page: 1,
      sub: item.name,
      ...FILTERS_ALIASES[resolvedMenuName][item.name],
      ...credentials,
      ...account,
      ...folder_external_ids,
    })

    props.push(`/${pathname}/?${search}`)

    if (isNumber(counter)) {
      props.updateCountersAction()
    }
  }

  const proccessedCounter =
    counter &&
    (counters[conterItems[item.name]] > 99
      ? '99+'
      : counters[conterItems[item.name]])

  return (
    <li className={itemClassNames} onClick={handleClick}>
      <span className={textClass}>{item.text}</span>
      {!!counter && <span className={counterClass}>{proccessedCounter}</span>}
    </li>
  )
}

const mapStateToProps = state => ({
  location: getLocation(state),
  counters: state.counters,
  mailFolder: state.threadsList.currentFolder,
})

const mapDispatchToPorps = { push, updateCountersAction }

export default connect(mapStateToProps, mapDispatchToPorps)(MenuSubListItem)
