// @flow

import React, { Fragment, memo, useState } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import Author from './Cells/Author'
import Cell from './Cell'
import Info from './Cells/Info'
import { EntityIcon } from '../../../Icon'
import { getFormattedDate } from '../../../../utils/utils'
import ListItemContext from './ListItem.context'
import RequestManagers from '../../../TableCellUsers'

import styles from './ListItem.module.scss'

type Props = {
  buildingGroupView?: boolean,
  canCheck?: boolean,
  checked?: boolean,
  data: Object,
  profileView?: boolean,
  push: string => void,
  renderSelectStatus: (Object, Object) => void,
  status: Object,
  toggle: number => void,
}

const ListItem = memo<Props>(props => {
  const {
    checked,
    canCheck,
    data,
    isDweller,
    profileView,
    status,
    buildingGroupView,
    requestView,
  } = props

  const {
    id,
    updated,
    private_updated,
    archived,
    is_unviewed: unviewed,
    address_obj: address,
    flat_obj: flat,
    permissions: { can_edit_status: canEditStatus },
    assignees,
  } = data

  const { t } = useTranslation('Request')

  const open = () => props.push(`/request/${id}`)

  const [labelsHeight, setLabelsHeight] = useState(0)
  const [titleHeight, setTitleHeight] = useState(0)

  const itemClass = classnames('table__row request-list-item', styles.row, {
    'table__row--attention': checked,
    'table__row--archived':
      archived && (profileView || buildingGroupView || requestView),
    'table__row--not-viewed': unviewed,
  })

  const addressValue = address ? address.value : ''
  const flatNumber = flat ? flat.number : ''

  return (
    <ListItemContext.Provider value={{ labelsHeight, titleHeight }}>
      <div className={itemClass} onClick={open}>
        <div
          className={classnames(styles['cell-box'], {
            [styles.archivedCell]:
              (profileView || buildingGroupView) && archived && !isDweller,
          })}
        >
          <Info
            canCheck={canCheck}
            checked={checked}
            data={data}
            hideLabels={profileView}
            setLabelsHeight={setLabelsHeight}
            setTitleHeight={setTitleHeight}
            onClick={open}
            onToggle={props.toggle}
          />
        </div>
        {profileView ? (
          <Fragment>
            <Cell>
              <div className='table-item__address'>
                <span title={addressValue}>{addressValue}</span>
              </div>
            </Cell>
            <Cell>
              <EntityIcon id='apartment' />
              <span
                title={`${t('flat_number_title')} ${flatNumber}`}
                className={styles.flatNumber}
              >
                {t('flat_number_title')} {flatNumber}
              </span>
            </Cell>
          </Fragment>
        ) : (
          <Cell>
            <div className='table-item__address'>
              <span title={addressValue}>{addressValue}</span>
              <span>{flat && `${t('FlatNumber')}: ${flatNumber}`}</span>
            </div>
          </Cell>
        )}
        <Cell>
          <Author user={data.owner} />
        </Cell>
        {buildingGroupView && (
          <Cell>
            <RequestManagers items={assignees} />
          </Cell>
        )}
        <Cell>
          <div
            title={t(status.title)}
            className={`table-item__status table-item__status--${status.css}`}
          >
            <span>{t(status.title)}</span>
          </div>
        </Cell>
        <Cell>
          {canEditStatus && props.renderSelectStatus(data, status)}
          <div
            className='table-item__text'
            title={getFormattedDate(isDweller ? updated : private_updated)}
          >
            <span style={{ fontSize: '14px', color: '#5F6368' }}>
              {getFormattedDate(isDweller ? updated : private_updated)}
            </span>
          </div>
        </Cell>
      </div>
    </ListItemContext.Provider>
  )
})

const mapDispatchToProps = { push }

export default connect(null, mapDispatchToProps)(ListItem)
