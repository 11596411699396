// @flow

import { getApi, postApi, deleteApi, patchApi } from './api.core'

export const getChatrooms = params => getApi(`api/chatroom/`, params)
export const getMessages = (chatroomUuid, limit, messageUuid, isGetOld, page) =>
  getApi(
    `api/chatroom/${chatroomUuid}/messages/?limit=${limit}${
      messageUuid ? `&from=${messageUuid}` : ''
    }${isGetOld ? '&get_prev=1' : ''}${page ? '&page=' + page : ''}`
  )
export const getArchivedInfo = (chatroomUuid, messageUuid, pageSize) =>
  getApi(
    `api/chatroom/${chatroomUuid}/archived_info/?from=${messageUuid}&page_size=${pageSize}`
  )
export const readMessages = chatroomUuid =>
  postApi(`api/chatroom/${chatroomUuid}/read/`)
export const getMembers = uuid => getApi(`api/chatroom/${uuid}/members/`)
export const addMembers = (uuid, params) =>
  postApi(`api/chatroom/${uuid}/members/`, params)
export const removeMembers = (uuid, users) =>
  deleteApi(`api/chatroom/${uuid}/members/?ids=${users}`)
export const getChatroomInfo = uuid => getApi(`api/chatroom/${uuid}/`)
export const getChatroomFiles = uuid => getApi(`api/chatroom/${uuid}/files/`)
export const createChatroom = params => postApi('api/chatroom/', params)
export const sendMessage = params => postApi('api/pm/', params)
export const updateChatroom = (uuid, params) =>
  patchApi(`api/chatroom/${uuid}/`, params)
export const addLikeToMessage = uuid => postApi(`api/pm/${uuid}/like/`)
export const addDislikeToMessage = uuid => postApi(`api/pm/${uuid}/dislike/`)
export const removeDislikeFromMessage = uuid =>
  deleteApi(`api/pm/${uuid}/dislike/`)
export const deleteMessage = uuid => deleteApi(`api/pm/${uuid}/`)
export const updateMessage = (uuid, params) =>
  patchApi(`api/pm/${uuid}/`, params)
export const getCounters = () => getApi('api/chatroom/counters/')
