export const filterArrayTypes = [
  'status',
  'role',
  'id',
  'exclude_id',
  'building',
  'external_id',
  'phone',
  'email',
]

export const formatFilters = params => {
  let paramsKeys = Object.keys(params)
  let filters = { ...params }
  paramsKeys.forEach(i => {
    if (filterArrayTypes.includes(i) && !Array.isArray(params[i])) {
      filters[i] = [params[i]]
    }
  })

  return filters
}
