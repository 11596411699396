// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import type { Node } from 'react'

import { CellsBar, Cell } from '../../../Block'
import DateTime from '../../../DateTime'
import RatingCell from '../RatingCell'
import { parseMpPrice } from '../../../../utils/utils'

import styles from './Main.module.scss'

import StatusSelect from '../StatusSelect'

type Props = {
  budget_plan: Object,
  created: boolean,
  onChange: Object => void,
  onEdit: () => void,
  permissions: { can_edit_status: boolean },
  requestId: number,
  statusId: number,
}

const Main = (props: Props): Node => {
  const {
    statusId,
    permissions: { can_edit_status: canEditStatus, can_view_budget_plan },
    created,
    budget_plan,
    requestId,
    onChange,
  } = props

  const { t } = useTranslation('Request')
  const requestStatuses = useSelector(state => state.init.request_status)

  const status = requestStatuses.find(s => s.id === statusId)

  const budgetPlanLink =
    budget_plan && budget_plan.id
      ? `/budget-plan/${budget_plan.id}/`
      : { pathname: '/budget-plan/', search: `?requestId=${requestId}` }

  const budgetPlanLinkText = budget_plan
    ? budget_plan.total_invoices_amount
      ? `€ ${parseMpPrice(budget_plan.total_invoices_amount, 'de')}`
      : t('NoInvoices')
    : t('NoBudgetPlan')

  const totalEstimatedAmount =
    budget_plan && budget_plan.total_estimated_amount
      ? `${t('TotalEstimatedAmount')}: € ${parseMpPrice(
          budget_plan.total_estimated_amount,
          'de'
        )}`
      : ''

  return (
    <>
      <CellsBar>
        <Cell title={t('CreationDate')} className='order-date-create'>
          <DateTime dateTime={created} />
        </Cell>
        <Cell
          title={t('Status')}
          className='order-status'
          valueClassName={`bar__cell-value--${status.css}`}
        >
          {t(status.body || status.title)}
          {canEditStatus && (
            <StatusSelect
              statusId={statusId}
              requestId={requestId}
              statuses={requestStatuses}
              onChange={onChange}
              onEdit={props.onEdit}
            />
          )}
        </Cell>
        {can_view_budget_plan && (
          <Cell title={t('BudgetPlunning')}>
            <div className={styles.total}>
              <Link to={budgetPlanLink}>{budgetPlanLinkText}</Link>
              <span>{totalEstimatedAmount}</span>
            </div>
          </Cell>
        )}
        <RatingCell />
      </CellsBar>
    </>
  )
}

export default Main
