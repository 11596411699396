// @flow

import {
  getApi,
  fileUpload,
  deleteApi,
  postRaw,
  patchRaw,
  deleteRaw,
} from './api.core'

export const getById = (fileId: number, params = null) =>
  getApi(`api/v2/document/${fileId}/download/`, params)
export const getList = (params: Object) => getApi(`api/v2/file/`, params)
export const getPermissions = (directoryId: number) =>
  getApi(`api/budget-plan/invoice-file-marking/?directory=${directoryId}`)
export const getFile = (fileId: number, params = null) =>
  getApi(`api/v2/document/${fileId}/`, params)
export const removeFile = (fileId: number) =>
  deleteApi(`api/v2/document/${fileId}/`)
export const renameFile = (fileId: number, params: Object) =>
  patchRaw(`api/v2/document/${fileId}/`, params)
export const createFile = (params: Object) =>
  fileUpload('api/v2/document/', params)
export const createDirectory = (params: Object) =>
  postRaw('api/v2/directory/', params)
export const removeDirectory = (directoryId: number) =>
  deleteRaw(`api/v2/directory/${directoryId}/`)
export const changeDirectory = (directoryId: number, params: Object) =>
  patchRaw(`api/v2/directory/${directoryId}/`, params)
export const getFileById = params => getApi('api/file/scoped/', params)

export const getFileScope = (id, params: Object) =>
  getApi(`api/v2/file/${id}/`, params)
