// @flow

import { clone } from 'lodash-es'

import * as actions from './AddManagerPopUp.actionTypes'

const getEmptyUser = () => ({
  name: '',
  second_name: '',
  email: '',
  role: 'manager',
})

const userFromInvite = profile => ({
  name: profile.name,
  second_name: profile.second_name,
  email: profile.email || '',
  role: profile.role,
})

const initialState = {
  buildingId: null,
  users: [],
  message: '',
  reinvite: null,
  working: false,
  saving: false,
}

export default (state = initialState, action) => {
  let users = []
  switch (action.type) {
    case actions.ADD_MANAGER_POPUP_INITIATE:
      return {
        ...initialState,
        users: [
          action.profile ? userFromInvite(action.profile) : getEmptyUser(''),
        ],
        message: action.message,
        reinvite: action.profile,
        buildingId: action.building,
      }
    case actions.ADD_MANAGER_POPUP_RESET:
      return initialState
    case actions.ADD_MANAGER_POPUP_SET_BUILDING:
      return {
        ...state,
        buildingId: action.id,
      }
    case actions.ADD_MANAGER_POPUP_LOAD_FLATS:
      return {
        ...state,
        working: true,
      }
    case actions.ADD_MANAGER_POPUP_UPDATE_FLATS:
      return {
        ...state,
        working: false,
      }
    case actions.ADD_MANAGER_POPUP_ADD_MANAGER: {
      return {
        ...state,
        users: [...state.users, getEmptyUser()],
      }
    }
    case actions.ADD_MANAGER_POPUP_UPDATE_USER: {
      const { index, key, value } = action
      users = clone(state.users)
      users[index][key] = value

      return {
        ...state,
        users,
      }
    }

    case actions.ADD_MANAGER_POPUP_SAVE:
      return {
        ...state,
        saving: true,
      }
    case actions.ADD_MANAGER_POPUP_SAVED:
      return {
        ...initialState,
        users: [getEmptyUser()],
      }
    case actions.ADD_MANAGER_POPUP_SET_MESSAGE:
      return {
        ...state,
        message: action.value,
      }
    case actions.DELETE_USER_POPUP: {
      users = clone(state.users)
      users.splice(action.index, 1)

      if (users.length === 0) users.push(getEmptyUser(''))

      return {
        ...state,
        users,
      }
    }
    case actions.ADD_MANAGER_POPUP_ERROR:
      state.users.forEach((user, index) => {
        if (action.result[index].errors) {
          users.push({
            ...user,
            errors: action.result[index].errors,
          })
        }
      })

      return {
        ...state,
        users,
        working: false,
        saving: false,
        error: true,
      }
    default:
      return state
  }
}
