// @flow

import React, { memo, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import AdminInfo from './AdminInfo'
import Checkbox from './Checkbox'
import Labels from './Labels'
import { EntityIcon } from '../../../../../Icon'
import Rating from '../../../../../Rating'
import { REQUEST_TYPES_WITHOUT_RATING } from '../../../../../../constants'
import { useComponentSize } from '../../../../../../hooks'
import OrderLink from './OrderLink'
import { showMarketplace } from '../../../../../../utils/commonSelectors'
import ProviderLink from './ProviderLink'

import styles from './Info.module.scss'

type Props = {
  canCheck: boolean,
  checked: boolean,
  data: Object,
  hideLabels?: boolean,
  onToggle: number => void,
  setLabelsHeight: number => void,
  setTitleHeight: number => void,
}

const Info = memo<Props>(props => {
  const { data, canCheck, checked, hideLabels } = props
  const {
    id,
    title,
    request_no: number,
    permissions: {
      can_view_labels: canViewLabels,
      can_view_request_order_link: canViewRequestOrderLink,
    },
    label_objs: labels,
    rating,
    order_ids: orderIds,
    has_contractors,
    status,
  } = data

  const { t } = useTranslation('Request')

  const isMpActive = useSelector(state => showMarketplace(state))

  const titleRef = useRef(title)
  const { height: titleHeight } = useComponentSize(titleRef)
  useEffect(() => {
    props.setTitleHeight(titleHeight)
  })

  const enabledCheckbox = canCheck && props.onToggle
  const isRatingEnabled =
    rating && !REQUEST_TYPES_WITHOUT_RATING.includes(status)
  const hasLabels = canViewLabels && labels && !!labels.length

  const cellClass = classnames(styles['info-cell'], {
    [styles['info-cell-checkable']]: canCheck,
  })

  return (
    <div className={cellClass}>
      {hasLabels && !hideLabels && (
        <Labels isShowTitle items={labels} setHeight={props.setLabelsHeight} />
      )}
      <div className={styles['title-box']} ref={titleRef}>
        {enabledCheckbox && (
          <Checkbox checked={checked} request={id} onChange={props.onToggle} />
        )}
        <div className={styles.iconBounding}>
          <EntityIcon id='request' className={styles.entityIcon} />
        </div>
        {!!isMpActive &&
          canViewRequestOrderLink &&
          !!orderIds &&
          !!orderIds.length && <OrderLink orderIds={orderIds} />}
        {has_contractors && <ProviderLink />}
        <span className={styles.title} title={title}>
          {isRatingEnabled && <Rating readonly value={rating} />}
          <Link
            to={`/request/${id}`}
            target='_blank'
            rel='noreferrer'
            onClick={e => e.stopPropagation()}
          >
            <span className='requestlist__request-title'>
              {t('request_number_title')} {number}
            </span>
          </Link>
          <span className='requestlist__request-text'>{title}</span>
        </span>
      </div>
      <AdminInfo data={data} />
    </div>
  )
})

export default Info
