// @flow

import { isNil, omitBy } from 'lodash-es'
import { all, put, call, fork, takeEvery } from 'redux-saga/effects'

import { serverError, modalError } from '../../Layout/Layout.actions'
import api from '../../../core/api'
import * as actions from './AttachToRequestPopup.actionTypes'

function* watchCreate() {
  yield takeEvery(actions.ATTACH, create)
}

function* watchMove() {
  yield takeEvery(actions.MOVE, moveThread)
}

function* create(action) {
  try {
    const {
      params: {
        requestId,
        emailMessageId,
        chatMessageId,
        requestTitle,
        requestNo,
        requestStatus,
        requestText,
      },
    } = action

    const formattedParams = omitBy(
      {
        requestId,
        email_message: emailMessageId,
        chat_message: chatMessageId,
      },
      isNil
    )

    yield call(api.request.attachMessage, formattedParams)

    yield put({
      type: actions.ATTACHED,
      request: {
        title: requestTitle,
        id: requestId,
        message_id: emailMessageId,
        request_no: requestNo,
        text: requestText,
        status_obj: requestStatus,
      },
    })
  } catch (error) {
    yield put({ type: actions.ERROR, error })

    if (error.message.response.status === 400) {
      yield put(modalError(error.message.response.data))

      return
    }

    yield put(serverError(error))
  }
}

function* moveThread(action) {
  try {
    const {
      params: { uuid, oldRequestId, newRequestId },
    } = action
    yield call(api.request.moveThread, uuid, oldRequestId, newRequestId)
    yield put({
      type: actions.MOVED,
    })
  } catch (error) {
    yield put({ type: actions.ERROR, error })

    if (error.message.response.status === 400) {
      yield put(modalError(error.message.response.data))

      return
    }

    yield put(serverError(error))
  }
}

export default function* watch() {
  yield all([fork(watchCreate), fork(watchMove)])
}
