// @flow

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Textarea from 'react-textarea-autosize'
import Joi from 'joi-browser'
import validation from 'react-validation-mixin'
import validationStrategy from 'joi-browser-validation-strategy'
import classnames from 'classnames'
import { join, isEmpty, omit, omitBy, isNil } from 'lodash-es'
import { compose } from 'redux'

import Button from '../../Button'
import Nbsp from '../../NBSP'
import Select from '../../Select/SelectAsync'
import SelectType from '../../Select/SelectRequestType'
import SelectUser from '../../Select/SelectUser'
import * as actions from './ConverToRequestPopup.actionTypes'
import { getUser } from '../../../utils/commonSelectors'
import { isStaffUser } from '../../../utils/utils'

type Props = {
  dispatch: Object => void,
  onClose: () => void,
  onReload: () => void,
  t: string => string,
}

type State = {
  text: string,
  type: any,
}

class ConvertToRequestPopup extends Component<Props, State> {
  constructor(props) {
    super(props)
    const reqText = `!! ${this.props.t('Common:FieldIsRequired')}`
    const messages = {
      base: reqText,
      empty: reqText,
      required: reqText,
    }
    this.validationMsg = {
      language: {
        any: {
          invalid: reqText,
          empty: reqText,
        },

        string: messages,
        object: messages,
        number: messages,
      },
    }

    this.validatorTypes = {
      owner: Joi.number(),
      type: Joi.object().required().options(this.validationMsg),
      text: Joi.string().required().options(this.validationMsg),
      building: Joi.when(Joi.ref('owner'), {
        is: Joi.number(),
        then: Joi.number().required().options(this.validationMsg),
      }),
      flat: Joi.when(Joi.ref('owner'), {
        is: Joi.number(),
        then: Joi.number().required().options(this.validationMsg),
      }),
    }
  }

  state = {
    text: '',
    type: null,
    owner: null,
    building: null,
    flat: null,
  }

  componentDidMount() {
    this.props.dispatch({
      type: actions.INIT,
    })

    document.body.style.overflowY = 'hidden'
  }

  componentWillUnmount = () => {
    document.body.style.overflowY = 'scroll'
  }

  componentDidUpdate = () => {
    if (this.props.created) {
      this.props.onClose()
      this.props.onReload && this.props.onReload()
    }
  }

  onChangetext = event => {
    const text = event.target.value
    this.setState({ text })
  }

  onSelectType = type => {
    const { validate } = this.props
    this.setState({ type }, () => validate('type'))
  }

  getValidatorData() {
    return this.state
  }

  getLineClass = field => {
    const { isValid } = this.props

    return classnames('def_form_line', {
      elem_error: !isValid(field),
    })
  }

  convert = () => {
    const { text, type, flat, building, owner } = this.state
    const { validate, emailMessageId, chatMessageId, user } = this.props

    validate(err => {
      const clearedErrors = isStaffUser(user) ? omit(err, ['owner']) : err

      if (isEmpty(clearedErrors)) {
        const params = {
          email_message: emailMessageId,
          chat_message: chatMessageId,
          text,
          request_type: type.id,
          flat,
          owner: owner || user.owner || user.id,
        }

        if (!flat) {
          params.building = building
        }

        this.props.dispatch({
          type: actions.REQUEST_CREATE,
          params: omitBy(params, isNil),
        })
      }
    })
  }

  getBuildingParams = () => {
    const { owner } = this.state

    return {
      with_flats: 1,
      is_promo: 0,
      dweller: owner,
    }
  }

  updateBuilding = value => {
    const building = value ? value.id : null
    this.setState({ building, flat: null })
  }

  updateFlat = value => {
    const flat = value ? value.id : null
    this.setState({ flat })
  }

  updateOwner = value => {
    const owner = value ? value.owner : null
    this.setState({ owner })
  }

  renderError = field => {
    const { getValidationMessages } = this.props
    const errors = getValidationMessages(field)
    const html = join(errors, '<br/>')

    return errors.length ? (
      <div className='error' dangerouslySetInnerHTML={{ __html: html }} />
    ) : null
  }

  render() {
    const { handleValidation, isValid, working, user } = this.props

    const { text, building, flat, owner } = this.state

    const cssTextAream = classnames(
      'textarea-2 textarea-2--large textarea-2--block textarea-2--default',
      {
        'textarea-2--error': !isValid('text'),
      }
    )

    const ownerSelectParams = {
      group: 'dweller',
      status: 'active',
      building_id: building,
    }

    const flatSelectParams = { building, owner }
    const flatSelectMonitor = Object.values(flatSelectParams).toString()

    return (
      <div className='modal__content convert-to-request-popup'>
        <Button.Close onClick={this.props.onClose} />
        <div className='modal__title modal__title--big-bottom-margin'>
          {this.props.t('Title')}
        </div>
        <div className='modal__text'>
          {isStaffUser(user) && (
            <Fragment>
              <div>
                <div className='modal__row'>
                  {this.props.t('DwellerOrLandlord')}:
                  <Nbsp />
                </div>
                <div className='modal__row input input--medium input--default input--block'>
                  <SelectUser
                    fullWidth
                    cleanable
                    withDisableOnLoad
                    btnPlaceholder='SelectDwellerOrLandlord'
                    id='request-initiator'
                    initialValueId={owner}
                    monitor={building}
                    params={ownerSelectParams}
                    onChange={this.updateOwner}
                  />
                </div>
              </div>
              <div>
                <div className='modal__row'>
                  {this.props.t('Address')}:
                  <Nbsp />
                </div>
                <div className='modal__row input input--medium input--default input--block'>
                  <Select
                    fullWidth
                    cleanable
                    withDisableOnLoad
                    apiFn='building.getListTiny'
                    id='request-create-building'
                    initialValueId={building}
                    labelKey='address'
                    monitor={owner}
                    inputError={!this.props.isValid('building')}
                    params={this.getBuildingParams()}
                    placeholder='selectAddress'
                    onChange={this.updateBuilding}
                  />
                </div>
              </div>
              <div>
                <div className='modal__row'>
                  {this.props.t('Flat')}:
                  <Nbsp />
                </div>
                <div className='modal__row input input--medium input--default input--block'>
                  <Select
                    fullWidth
                    apiFn='flat.getListTiny'
                    disabled={!building}
                    filterAfter={1}
                    id='request-create-flat'
                    initialValueId={flat}
                    cleanable={false}
                    inputError={!this.props.isValid('flat')}
                    labelKey='number'
                    monitor={flatSelectMonitor}
                    params={flatSelectParams}
                    placeholder='selectFlat'
                    onChange={this.updateFlat}
                  />
                </div>
              </div>
            </Fragment>
          )}
          <div className='modal__row'>{this.props.t('RequestTypeTitle')}</div>
          <div className='modal__row input input--medium input--default input--block'>
            <SelectType
              fullWidth
              value={this.state.type}
              inputError={!this.props.isValid('type')}
              onChange={this.onSelectType}
            />
            {this.renderError('type')}
          </div>
          <div className={this.getLineClass('text')}>
            <div className={cssTextAream}>
              <Textarea
                name='text'
                value={text}
                minRows={4}
                maxRows={4}
                className='textarea-2__input modal-adduser__comment'
                placeholder={this.props.t('textPlaceHolder')}
                onChange={this.onChangetext}
                onBlur={handleValidation('text')}
              />
            </div>
            {this.renderError('text')}
          </div>
        </div>
        <div className='modal__submit'>
          <Button.Save
            working={working}
            disabled={!isValid}
            onClick={this.convert}
          >
            {this.props.t('Common:Add')}
          </Button.Save>
          <Button.Cancel onClick={this.props.onClose}>
            {this.props.t('Common:Cancel')}
          </Button.Cancel>
        </div>
        {working && <div className='overlay' />}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.convertToRequestPopup,
  user: getUser(state),
})

export default compose(
  withTranslation('ConvertToRequestPopup'),
  connect(mapStateToProps),
  validation(validationStrategy)
)(ConvertToRequestPopup)
