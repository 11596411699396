// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { isEqual } from 'lodash-es'
import { compose } from 'redux'

import EmptyWidget from '../EmptyWidget'
import Loader from '../../Loader'
import Widget from '../../../containers/Widget'
import { WIDGET_TYPES } from '../../../containers/widgets/widgets'
import {
  getUrlForAvatar,
  cutText,
  isNoProfile,
  getUserName,
} from '../../../utils/utils'
import {
  WIDGET_PARTICIPANTS_LIST_INITIATING,
  WIDGET_REQUEST_PARTICIPANTS_LIST_INITIATING,
  WIDGET_MAIL_PARTICIPANTS_LIST_INITIATING,
  CLEAR_WIDGET_PARTICIPANTS_LIST,
} from './WidgetParticipantsList.actionTypes'
import { getRequestParticipants } from './WidgetParticipantsList.selectors'
import { getUser } from '../../../utils/commonSelectors'

type Props = {
  data: Array<Object>,
  dispatch: Object => void,
  isMail: boolean,
  params: Object,
  requestId: string,
  t: string => string,
}
type State = {
  requestParticipants: Array<Object>,
}

class WidgetParticipantsList extends Component<Props, State> {
  state = {
    requestParticipants: [],
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      requestId,
      requestParticipants,
      params: { chatroomUuid, uuid },
      isActive,
      isMail,
      isSingle,
    } = this.props

    if (isActive && !prevProps.isActive) {
      if (isMail) {
        this.props.dispatch({
          type: WIDGET_MAIL_PARTICIPANTS_LIST_INITIATING,
          uuid,
          isSingle,
        })

        return
      }

      if (chatroomUuid && chatroomUuid !== 'create') {
        this.props.dispatch({
          type: WIDGET_PARTICIPANTS_LIST_INITIATING,
          chatroomUuid,
        })
      } else if (requestId) {
        this.props.dispatch({
          type: WIDGET_REQUEST_PARTICIPANTS_LIST_INITIATING,
          requestId,
        })
      }

      this.setState({
        requestParticipants: this.props.data,
      })

      return
    }

    if (!isEqual(requestParticipants, prevState.requestParticipants)) {
      this.setState({
        requestParticipants,
      })
    }

    if (
      chatroomUuid !== 'create' &&
      prevProps.params.chatroomUuid !== chatroomUuid
    ) {
      this.props.dispatch({
        type: WIDGET_PARTICIPANTS_LIST_INITIATING,
        chatroomUuid,
      })
    }
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: CLEAR_WIDGET_PARTICIPANTS_LIST,
    })
  }

  userItem = (participant, index) => {
    const { owner, id } = participant
    const userId = owner || id
    const avatar = getUrlForAvatar(participant)
    const userName = getUserName(participant)
    const { requestId } = this.props
    const linkContent = (
      <span>
        <span className='widget-new-usermeta__userpic'>
          <img src={avatar} alt={userName} />
        </span>
        <span className='widget-new-usermeta__username'>
          <span title={userName}>{cutText(userName, 24)}</span>
        </span>
      </span>
    )

    return (
      <div className='widget-new-usermeta' key={index}>
        {isNoProfile(participant) ||
        !participant.available_to_manager ||
        (!requestId && !participant.is_visible) ? (
          <span>{linkContent}</span>
        ) : (
          <Link to={`/profile/${userId}`}>{linkContent}</Link>
        )}
      </div>
    )
  }

  render() {
    const {
      t,
      data,
      params: { chatroomUuid, uuid },
      requestId,
      working,
    } = this.props

    if (working) return <Loader text={false} type='medium' />

    let participantsList

    if (chatroomUuid || uuid) {
      participantsList = data.filter(user => user.active)
      participantsList =
        data.length === 0 ? (
          <EmptyWidget
            icon='user'
            text={this.props.t('ThereAreNoParticipantsYet')}
          />
        ) : (
          participantsList.map(this.userItem)
        )
    }

    if (requestId) {
      const { requestParticipants } = this.state
      participantsList = requestParticipants
        .filter(user => !user.archived)
        .map(this.userItem)
    }

    return (
      <Widget name={WIDGET_TYPES.participants} title={t('Common:Participants')}>
        {participantsList}
      </Widget>
    )
  }
}

const mapStateToProps = state => ({
  ...state.WidgetParticipantsList,
  user: getUser(state),
  requestParticipants: getRequestParticipants(state),
})

export default compose(
  connect(mapStateToProps),
  withTranslation('WidgetParticipantsList')
)(WidgetParticipantsList)
