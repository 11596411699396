// @flow

import { postApi, getApi, patchApi, patchRaw } from './api.core'

export const getFlatInfo = ({ id, params }) =>
  getApi(`api/flat/${id}/info/`, params)
export const updateFlatInfo = (id, params) =>
  patchRaw(`api/flat/${id}/info/`, params)
export const getFlat = ({ id, params }) => getApi(`api/flat/${id}/`, params)
export const getListTiny = params => getApi('api/flat/', params)
export const getArchivedListTiny = params =>
  getApi('api/archived/flat/', params)
export const updateFlat = (id, params) => patchApi(`api/flat/${id}/`, params)
export const addFlat = params => postApi('api/flat/', params)
export const getListLiveSearch = params =>
  getApi('api/flat/live-search/', params)
export const getListSearch = params =>
  getApi('api/flat/search/', { ...params, query: params.search })
export const getProfileList = params =>
  getApi('api/inhabitancy/dwellers/', params)
export const getAllProfileList = params =>
  getApi('api/inhabitancy/all-dwellers/', params)
export const getArchivedProfileList = params =>
  getApi('api/inhabitancy/archived-dwellers/', params)
export const setContractData = (id, params) =>
  patchRaw(`api/inhabitancy/dwellers/${id}/`, params)
