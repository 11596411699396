// @flow

import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import type { Node } from 'react'

import Block, { Cell, CellsBar, Header } from '../Block'
import DateTime from '../DateTime'
import { EntityIcon } from '../Icon'
import Modal from '../Modal'
import Assignees from '../Request/RequestInfo/Assignees'
import OwnerAddress from '../Request/RequestInfo/OwnerAddress'
import RequestOwnerName from '../Request/RequestInfo/RequestOwnerName'
import RequestType from '../Request/RequestInfo/RequestType'
import { getEntityGoBackCallback } from '../../utils/routing'
import { isStaffUser } from '../../utils/utils'
import Orders from '../Request/RequestInfo/Orders'
import { USER_STATUS, NOT_CONFIRMED_STATUS } from '../../constants'
import RequestProviders from '../RequestProviders'
import DueDate from '../Request/RequestInfo/DueDate'
import LabelsRow from '../Labels/LabelsRow'
import RelatedRequests from '../Request/RequestInfo/RelatedRequests'

type Props = {
  assigneeList: Array<Object>,
  building: Object,
  contractors: Array<Object>,
  flat: Object,
  flatError: boolean,
  history: Object,
  isMarketplaceEnabled: boolean,
  onChange: Object => void,
  onChangeOwner: Object => void,
  onChangeType: Object => void,
  order_ids: Array<string>,
  owner: Object,
  title: string,
  type: Object,
  user: Object,
  working: boolean,
}

const RequestCreateInfo = (props: Props): Node => {
  const {
    type,
    assigneeList,
    working,
    dueDate,
    owner,
    building,
    flat,
    user,
    history,
    order_ids,
    contractors,
    flatError,
    isMarketplaceEnabled,
    labels,
    relatedRequests,
  } = props

  const { t } = useTranslation('Request')

  const [modal, setModal] = useState(null)

  const hideModal = () => setModal(null)

  const getHandleBackCallback = () => {
    return getEntityGoBackCallback(history, 'requests')
  }

  return (
    <Fragment>
      <Modal contentLabel='' isOpen={Boolean(modal)} onRequestClose={hideModal}>
        {modal}
      </Modal>
      <Block working={working}>
        <Header
          header
          icon={<EntityIcon large id='request' />}
          goBack={getHandleBackCallback()}
        >
          <span className='bar__title-text'>{t('Ticket')}</span>
        </Header>
        <CellsBar>
          <Cell title={t('CreationDate')} className='order-date-create'>
            <DateTime dateTime={moment().format()} />
          </Cell>
        </CellsBar>
        <DueDate
          editing
          value={dueDate}
          user={user}
          permissions={{ can_edit_due_date: true }}
          onChange={val => props.onChange({ dueDate: val })}
        />
        <RequestType editing value={type} onChange={props.onChangeType} />
        <RequestOwnerName
          editing
          owner={owner}
          building={building}
          flat={flat}
          onChange={props.onChangeOwner}
        />
        {USER_STATUS[user.status] !== NOT_CONFIRMED_STATUS ? (
          <OwnerAddress
            editing
            flat={flat}
            building={building}
            owner={owner}
            flatError={flatError}
            onChange={props.onChange}
          />
        ) : null}
        <Assignees
          building={building && building.id}
          canEditAssignee={isStaffUser(user)}
          value={assigneeList}
          onEdit={setModal}
          onChange={props.onChange}
        />
        {isStaffUser(user) && (
          <RequestProviders
            contractors={contractors}
            onChange={props.onChange}
            onChangeContractors={props.onChangeContractors}
          />
        )}
        {isStaffUser(user) && (
          <LabelsRow
            infoData={{ label_objs: labels }}
            value={labels}
            permissions={{ can_edit_labels: true }}
            onEdit={setModal}
            onChange={lab =>
              props.onChange({ labels: lab.filter(l => !!l.checked) })
            }
          />
        )}
        {isMarketplaceEnabled && (
          <Orders
            orderIds={order_ids}
            permissions={{
              can_view_request_order_link: isStaffUser(user),
              can_edit_request_order_link: isStaffUser(user),
            }}
            onEdit={setModal}
            onChange={props.onChange}
          />
        )}
        {isStaffUser(user) && (
          <RelatedRequests
            editing
            relatedRequests={relatedRequests}
            onEdit={setModal}
            onChange={props.onChange}
          />
        )}
      </Block>
    </Fragment>
  )
}

export default RequestCreateInfo
