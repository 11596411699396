// @flow

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import seapig, { OPTIONAL } from 'seapig'
import { push } from 'connected-react-router'
import { Link } from 'react-router-dom'
import { get } from 'lodash-es'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import classNames from 'classnames'
import type { ElementType } from 'react'

import {
  getUrlForAvatar,
  getUserName,
  isNoProfile,
  isStaffUser,
} from '../../../utils/utils'
import { getFullDateFormat, getUk } from '../../../utils/commonSelectors'
import DateTime from '../../DateTime'
import Label from '../../Label'
import {
  isExpired,
  isUpcoming,
  isAppropriateInterval,
} from '../PostInfo/PostInfo.utils'

import styles from './PostHeader.module.scss'

type Props = {
  children: ElementType,
  date: string,
  dateFormat: string,
  isUnread: boolean,
  onClick: () => void,
  owner?: ?Object,
  uk: Object,
}

class PostHeader extends Component<Props> {
  handleClick = (e: Object) => {
    e.stopPropagation()
  }

  renderOwner = () => {
    const { owner, uk, isUnread } = this.props

    const ownerInfo = (
      <Fragment>
        <img alt='author' src={getUrlForAvatar(owner)} />
        <span>{getUserName(owner)}</span>
      </Fragment>
    )

    const ownerClass = classNames(styles.owner, { [styles.bold]: isUnread })

    if (owner) {
      return isNoProfile(owner) ? (
        <span className={ownerClass}>{ownerInfo}</span>
      ) : (
        <Link
          className={ownerClass}
          to={`/profile/${owner.id}`}
          onClick={this.handleClick}
        >
          {ownerInfo}
        </Link>
      )
    }

    const avatar = get(uk, ['logo_mobile_obj', 'origin'])

    return (
      <Link className={ownerClass} to='/uk' onClick={this.handleClick}>
        <img alt='uk' src={avatar} />
        <span>{uk.name}</span>
      </Link>
    )
  }

  render() {
    const {
      date,
      dateFormat,
      children,
      date_to,
      date_from,
      spam,
      showInfo,
      user,
      isUnread,
    } = this.props

    const { actionsChildren } = seapig(children, { actions: OPTIONAL })

    const dateClass = classNames({ [styles.bold]: isUnread })

    return (
      <div className={styles.header} onClick={this.props.onClick}>
        <div className={styles.info}>
          {this.renderOwner()}
          <DateTime
            dateTime={date}
            dateFormat={dateFormat}
            className={dateClass}
          />
          {!spam &&
            showInfo &&
            isStaffUser(user) &&
            isAppropriateInterval(date_from, date_to) && (
              <Label
                text={this.props.t('PostIsActive')}
                style={{ marginLeft: '8px' }}
                color='success'
              />
            )}
          {showInfo && isExpired(date_to) && (
            <Label
              text={this.props.t('DateToIsExpired')}
              style={{ marginLeft: '8px' }}
            />
          )}
          {showInfo && isUpcoming(date_from) && (
            <Label
              text={this.props.t('DateFromIsPlanned')}
              style={{ marginLeft: '8px' }}
              color='warning'
            />
          )}
          {showInfo && spam && (
            <Label
              text={this.props.t('Blocked')}
              style={{ marginLeft: '8px' }}
              type='outlined'
            />
          )}
        </div>
        <div>{actionsChildren}</div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  dateFormat: getFullDateFormat(state),
  uk: getUk(state),
})

export default compose(
  withTranslation('Post'),
  connect(mapStateToProps, { push })
)(PostHeader)
