// @flow

import React, { useEffect, useMemo, useState } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import EmptyFilesList from './EmptyFilesList'
import FileListItem from './FileListItem'
import FilesDragLayer from './FilesDragLayer'
import NewTable from '../../NewTable'
import NewTableHeader from '../../NewTable/NewTableHeader'
import NewTableHeaderRow from '../../NewTable/NewTableHeaderRow'
import NewTableHeaderCell from '../../NewTable/NewTableHeaderCell'
import addRedirectToFirstPage from '../../../hocs/addRedirectToFirstPage'
import Loader from '../../Loader'
import UploadingFileItem from './UploadingFileItem'
import Lightbox from '../../LightboxGallery'
import { FOLDER_TYPE } from '../Files.constants'

type Props = {
  can_write?: boolean,
  canAddInInvoice: boolean,
  files: Array<Object>,
  filesAddedInInvoce: Array<number>,
  folderId?: number,
  loading: boolean,
  removing: boolean,
  selected: Array<number>,
  uploading: boolean,
}

const FileList = (props: Props) => {
  const {
    files,
    folderId,
    loading,
    uploading,
    removing,
    selected,
    can_write,
    checkedFiles,
    setOpenPdf,
    location,
    subject,
  } = props

  const { t } = useTranslation('Files')
  const [activeIndex, setActiveIndex] = useState(null)

  useEffect(() => {
    setActiveIndex(null)
  }, [location])

  const FilesNoFolder = useMemo(() => {
    return files.filter(f => f.type !== FOLDER_TYPE)
  }, [files])

  const tableClass = classnames({
    'working-overlay': loading || uploading || removing,
  })

  const closeLightbox = () => {
    setActiveIndex(null)
  }

  const openPreview = id => {
    setActiveIndex(FilesNoFolder.findIndex(f => f.id === id))
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <FilesDragLayer selected={selected} />
      <NewTable className={tableClass}>
        <NewTableHeader>
          <NewTableHeaderRow>
            <NewTableHeaderCell
              title={t('FileNameSort')}
              sortKey='name'
              style={{ width: '50%' }}
            />
            <NewTableHeaderCell
              title={t('FileOwnerSort')}
              sortKey='owner'
              style={{ width: '20%' }}
            />
            <NewTableHeaderCell
              title={t('FileAccessSort')}
              sortKey='users_access'
              style={{ width: '10%' }}
            />
            <NewTableHeaderCell
              title={t('FileSizeSort')}
              sortKey='size'
              style={{ width: '10%' }}
            />
            <NewTableHeaderCell
              title={t('FileCreateSort')}
              sortKey='created'
              style={{ width: '10%' }}
            />
          </NewTableHeaderRow>
        </NewTableHeader>
        {!!files.length &&
          files.map(file => (
            <FileListItem
              setLoading={props.setLoading}
              setUploading={props.setUploading}
              files={files}
              selected={selected}
              file={file}
              can_write={can_write}
              key={file.id}
              canAddInInvoice={props.canAddInInvoice}
              filesAddedInInvoce={props.filesAddedInInvoce}
              handleClick={props.handleClick}
              handleChange={props.handleChange}
              openRenameModal={props.openRenameModal}
              openChangeTypeModal={props.openChangeTypeModal}
              openRemoveModal={props.openRemoveModal}
              openReplaceModal={props.openReplaceModal}
              openInvoiceModal={props.openInvoiceModal}
              setError={props.setError}
              setOpenPdf={setOpenPdf}
              setActiveFile={openPreview}
            />
          ))}
        {!!checkedFiles.length &&
          checkedFiles.map(file => (
            <UploadingFileItem
              file={file}
              key={file.name}
              openRemoveModal={props.openRemoveModal}
              handleChange={props.handleChange}
            />
          ))}
      </NewTable>
      {!files.length && loading && <Loader type='big' text={false} />}
      {!files.length &&
        !checkedFiles.length &&
        !loading &&
        !uploading &&
        !removing && (
          <EmptyFilesList
            icon='folder'
            title={t('FilesNotFound')}
            folderId={folderId}
            canDrop={can_write}
            setLoading={props.setLoading}
            setUploading={props.setUploading}
          />
        )}
      <Lightbox
        newGallery
        activeIndex={activeIndex}
        images={FilesNoFolder}
        scope={props.scope || 'tree'}
        object={props.object}
        subject={subject}
        onClose={closeLightbox}
      />
    </DndProvider>
  )
}

FileList.defaultProps = {
  loading: false,
  uploading: false,
  removing: false,
}

export default addRedirectToFirstPage(FileList)
