// @flow

import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import PhaseItemContext from '../../../PhaseItem.context'
import RequestActivityContext from '../../../../../RequestActivity.context'
import { populatePhase } from '../../../../../../../../core/api/api.pipeline'
import Icon from '../../../../../../../../components/Icon'

import styles from './Main.module.scss'

const Main = (): Node => {
  const {
    phase: {
      name,
      progress,
      is_populatable,
      id,
      activities,
      request_thread_count,
      unread_request_thread_count,
      file_count,
    },
  } = useContext(PhaseItemContext)

  const {
    setReloading,
    isDweller,
    setThreadPhase,
    setFileActivityModal,
    setPhaseId,
  } = useContext(RequestActivityContext)

  const { t } = useTranslation('Pipeline')

  const [isLoading, setLoading] = useState(false)

  const handleClick = e => {
    e.stopPropagation()

    setLoading(true)

    populatePhase(id).finally(() => {
      setLoading(false)
      setPhaseId(id)
      setReloading(true)
    })
  }

  const handleOpenModal = e => {
    e.stopPropagation()

    setThreadPhase({ name, id, activities })
  }

  const handleOpenFileModal = e => {
    e.stopPropagation()
    setPhaseId(id)
    setFileActivityModal({ phaseId: id })
  }

  return (
    <div className={styles.main}>
      <span>
        {name}
        {!!request_thread_count && (
          <>
            <span className={styles.icon} onClick={handleOpenModal}>
              <Icon id='emailActivity' />
              {!!unread_request_thread_count && (
                <span className={styles.unread}>
                  {unread_request_thread_count}
                </span>
              )}
            </span>
            <span className={styles.count}>{request_thread_count}</span>
          </>
        )}
        {!!file_count && (
          <>
            <span className={styles.icon} onClick={handleOpenFileModal}>
              <Icon id='attach' />
            </span>
            <span className={styles.count}>{file_count}</span>
          </>
        )}
      </span>
      {is_populatable && !isDweller && (
        <div className={styles.populate}>
          <button type='button' disabled={isLoading} onClick={handleClick}>
            {t('PopulateButton')}
          </button>
        </div>
      )}
      {!isDweller && activities && !!activities.length && (
        <span>{`${progress} %`}</span>
      )}
    </div>
  )
}

export default Main
