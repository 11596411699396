// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { get, map, partial } from 'lodash-es'

import ValueItem from '../../../Select/ValueItem'
import { Row } from '../../../Block'
import Nbsp from '../../../NBSP'
import RequestInitatorSelect from './RequestInitatorSelect'
import {
  getUrlForAvatar,
  getUserName,
  isStaffUser,
} from '../../../../utils/utils'
import { getUser } from '../../../../utils/commonSelectors'
import { getProfile } from '../../../../core/api/api.profile'

import styles from './RequestOwnerName.module.scss'

type Props = {
  building: ?Object,
  editing: boolean,
  flat: ?Object,
  onChange: (?{ avatar: string, id: number, name: string }) => void,
  onEdit?: () => void,
  owner: Object,
  permissions: Object,
}

const RequestOwnerName = (props: Props) => {
  const { editing, owner } = props

  const ownerGetter = partial(get, owner)

  const fullname = getUserName(owner)

  const [id, name, avatar, group, deleted, availableToManager, status] = map(
    [
      'id',
      'name',
      'avatar',
      'group',
      'deleted',
      'availableToManager',
      'status',
    ],
    ownerGetter
  )

  const { t } = useTranslation('Request')

  const user = useSelector(getUser)

  const handleUpdate = async value => {
    let owner = null

    if (value) {
      const id = value.id || value.owner

      const profile = await getProfile(id)

      const avatar = getUrlForAvatar(profile)
      const name = getUserName(profile)
      const group = profile.group
      const deleted = profile.deleted
      const flatsCount = profile.flats_count

      owner = {
        id,
        name,
        avatar,
        group,
        deleted,
        flatsCount,
        availableToManager: true,
      }
    }

    props.onChange(owner)
  }

  const title = t('Owner')

  if (!editing) {
    const {
      permissions: { can_edit_owner: canEditOwner },
    } = props

    const profileId = !deleted ? id : null

    const rowProps = Object.assign(
      {},
      { title },
      canEditOwner && props.onEdit ? { onEdit: props.onEdit } : undefined
    )

    return (
      <Row {...rowProps}>
        <div className={styles.owner}>
          <ValueItem
            inline
            profile
            round
            profileId={profileId}
            group={group}
            avatar={avatar}
            text={name}
            role={t(`Role_${group}`)}
            isLink={availableToManager}
            status={status}
            statusClass={styles.status}
          />
        </div>
      </Row>
    )
  }

  const { building, flat } = props

  return isStaffUser(user) ? (
    <RequestInitatorSelect
      valueId={id}
      building={building}
      flat={flat}
      onUpdate={handleUpdate}
    />
  ) : (
    <Row title={title}>
      <span className='bar__userbar bar__userbar-round'>
        <img alt='assignee' className='bar__userbar-image' src={avatar} />
        <Nbsp />
        <span className='bar__userbar-text'>
          <Nbsp />
          {fullname}
        </span>
      </span>
    </Row>
  )
}

RequestOwnerName.defaultProps = {
  permissions: { can_edit_owner: false },
}

export default RequestOwnerName
