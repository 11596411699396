// @flow

import type { Location } from 'react-router-dom'

import { UUID_KEY } from './NewWizard.constants'

export function saveWizardUuid(uuid: string): void {
  localStorage.setItem(UUID_KEY, uuid)
}

export function clearWizardUuid(): void {
  localStorage.removeItem(UUID_KEY)
}

export function getWizardPrevUuid(): ?string {
  return localStorage.getItem(UUID_KEY)
}

export function getNewPath({ pathname }: Location): string {
  if (pathname.includes('search')) {
    return '/requests/search/:search'
  }

  if (pathname.includes('flat')) {
    return '/flat/:flatId/'
  }

  if (pathname.includes('building')) {
    return '/building/:buildingId/'
  }

  if (pathname.includes('profile')) {
    return '/profile/:profileId/:tab'
  }

  return ''
}
