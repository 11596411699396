// @flow

import type { Node } from 'react'
import React, { useState, useEffect } from 'react'
import setFormikError from '../../../utils/setFormikError'
import { isValid } from 'iban'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { bicRegExp } from '../../../utils/utils'
import FieldRow from '../../FieldRow'
import Button from '../../Button'

import {
  IBAN,
  BIC,
  BANK_NAME,
  TYPE_PAYMENT,
  OPEN_AMOUNT,
  DEPOSIT,
  MAX_BANK_NAME_LENGTH,
  CURRENCY_LABELS,
  MAX_AMOUNT,
  MAX_TYPE_OF_PAYMENT_LENGTH,
} from './ProfileInformationFinancial.constants'
import * as Yup from 'yup'

import NewSelectSimple from '../../NewSelectSimple'

import styles from './ProfileInformation.module.scss'

import NumberFormat from 'react-number-format'
import InputField from '../../InputField'
import { getCurrency } from '../../../core/api/api.uk'

import type { IFinancial } from '../ProfileData'

type Props = {
  active: boolean,
  handleEditClose: () => void,
  initialValues: IFinancial,
  isOpen: boolean,
  updateProfileFinancial: (data: {
    financial: IFinancial,
  }) => Promise<IFinancial>,
}

const FinancialForm = ({
  initialValues,
  isOpen,
  active,
  handleEditClose,
  updateProfileFinancial,
}: Props): Node => {
  const { t } = useTranslation('Profile')
  const [currency, setCurrency] = useState([])
  const [working, setWorking] = useState(false)

  useEffect(() => {
    getCurrency()
      .then(data => {
        setCurrency([
          ...data.map(variable => ({
            ...variable,
            value: variable.code,
            label: CURRENCY_LABELS[variable.code],
          })),
        ])
      })

      .finally(() => setWorking(false))
  }, [])

  const onSubmit = values => {
    const financial: IFinancial = { ...values }
    updateProfileFinancial({ financial })
      .then(() => handleEditClose())
      .catch(error => setFormikError(error, setFieldError))
  }

  const {
    values,
    errors,
    dirty,
    resetForm,
    isSubmitting,
    handleSubmit,
    setFieldError,
    handleChange,
    setFieldValue,
  } = useFormik({
    validateOnChange: true,
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      [IBAN]: Yup.string().test({
        message: '${path} is not valid',
        test: (value: string) => !value || isValid(value),
      }),
      [BIC]: Yup.string().matches(bicRegExp, t('ValidСharacters')),
      [BANK_NAME]: Yup.string().max(MAX_BANK_NAME_LENGTH, t('MaxInputLength')),
      [TYPE_PAYMENT]: Yup.string().max(
        MAX_TYPE_OF_PAYMENT_LENGTH,
        t('MaxInputLength')
      ),
      [DEPOSIT]: Yup.object().shape({
        currency: Yup.string(),
        amount: Yup.number().max(MAX_AMOUNT, t('MaxValueAmount')),
      }),
      [OPEN_AMOUNT]: Yup.object().shape({
        currency: Yup.string(),
        amount: Yup.number().max(MAX_AMOUNT, t('MaxValueAmount')),
      }),
    }),
    onSubmit,
  })

  useEffect(() => {
    if (!values[OPEN_AMOUNT].currency) {
      setFieldValue(`${OPEN_AMOUNT}.currency`, 'EUR')
    }

    if (!values[DEPOSIT].currency) {
      setFieldValue(`${DEPOSIT}.currency`, 'EUR')
    }
  }, [currency])

  const currencyAmountOption = currency.find(
    option => option.value === values[OPEN_AMOUNT].currency
  )

  const handleCurrencyAmount = option =>
    setFieldValue(`${OPEN_AMOUNT}.currency`, option.value)

  const currencyDepositOption = currency.find(
    option => option.value === values[DEPOSIT].currency
  )

  const handleCurrencyDeposit = option =>
    setFieldValue(`${DEPOSIT}.currency`, option.value)

  const handleChangeDeposit = values => {
    setFieldValue(`${DEPOSIT}.amount`, values.value || 0)
  }

  const handleChangeAmount = values =>
    setFieldValue(`${OPEN_AMOUNT}.amount`, values.value || 0)

  const handleChangeIBAN = e => {
    setFieldValue(IBAN, e.target.value.replace(/\s/g, ''))
  }

  const handleChangeBIC = e => {
    setFieldValue(BIC, e.target.value.replace(/\s/g, ''))
  }

  const onClosed = () => {
    handleEditClose()
    resetForm()
  }

  return (
    <form name='profile_information' onSubmit={handleSubmit}>
      {(values[IBAN] || isOpen) && (
        <FieldRow
          name={IBAN}
          label={'IBAN'}
          value={values[IBAN]}
          error={errors[IBAN]}
          active={active}
          placeholder={t('EnterIBAN')}
          handleChange={handleChangeIBAN}
        />
      )}
      {(values[BIC] || isOpen) && (
        <FieldRow
          name={BIC}
          active={active}
          label={'BIC'}
          error={errors[BIC]}
          value={values[BIC]}
          placeholder={t('EnterBIC')}
          handleChange={handleChangeBIC}
        />
      )}
      {(values[BANK_NAME] || isOpen) && (
        <FieldRow
          name={BANK_NAME}
          active={active}
          label={'BankName'}
          error={errors[BANK_NAME]}
          value={values[BANK_NAME]}
          placeholder={t('EnterBankName')}
          maxLength={MAX_BANK_NAME_LENGTH}
          handleChange={handleChange}
        />
      )}
      {(values[TYPE_PAYMENT] || isOpen) && (
        <FieldRow
          name={TYPE_PAYMENT}
          active={active}
          label={'TypePayment'}
          value={values[TYPE_PAYMENT]}
          placeholder={t('EnterTypePayment')}
          maxLength={MAX_TYPE_OF_PAYMENT_LENGTH}
          handleChange={handleChange}
        />
      )}
      <FieldRow
        label={'OpenAmount'}
        value={
          values[OPEN_AMOUNT]?.amount &&
          `${values[OPEN_AMOUNT]?.currency} ${values[OPEN_AMOUNT]?.amount}`
        }
        error={errors[OPEN_AMOUNT]?.amount}
        active={active}
      >
        <div className={styles['amount-block']}>
          <NewSelectSimple
            loading={working}
            className={styles['amount-type']}
            buttonClass={styles['amount-type-button']}
            name={`${OPEN_AMOUNT}.currency`}
            options={currency}
            value={currencyAmountOption}
            onChange={handleCurrencyAmount}
          />

          <NumberFormat
            fixedDecimalScale
            isNumericString
            allowNegative
            placeholder='0,00'
            customInput={InputField}
            decimalSeparator=','
            thousandSeparator='.'
            decimalScale={2}
            showErrorText={false}
            value={values[OPEN_AMOUNT].amount}
            name={`${OPEN_AMOUNT}.amount`}
            error={errors[OPEN_AMOUNT]}
            className={styles['retained-amount']}
            onValueChange={handleChangeAmount}
          />
        </div>
      </FieldRow>
      <FieldRow
        label={'Deposit'}
        value={
          values[DEPOSIT]?.amount &&
          `${values[DEPOSIT]?.currency} ${values[DEPOSIT]?.amount}`
        }
        error={errors[DEPOSIT]?.amount}
        active={active}
      >
        <div className={styles['amount-block']}>
          <NewSelectSimple
            loading={working}
            className={styles['amount-type']}
            buttonClass={styles['amount-type-button']}
            value={currencyDepositOption}
            name={`${DEPOSIT}.currency`}
            options={currency}
            onChange={handleCurrencyDeposit}
          />
          <NumberFormat
            fixedDecimalScale
            isNumericString
            allowNegative
            placeholder='0,00'
            customInput={InputField}
            decimalSeparator=','
            thousandSeparator='.'
            decimalScale={2}
            value={values[DEPOSIT]?.amount}
            name={`${DEPOSIT}.amount`}
            error={errors[DEPOSIT]?.amount}
            showErrorText={false}
            className={styles['retained-amount']}
            onValueChange={handleChangeDeposit}
          />
        </div>
      </FieldRow>
      {active && (
        <div className={styles.buttonWrapper}>
          <Button.Save disabled={!dirty || isSubmitting} type='submit'>
            {t('Common:Save')}
          </Button.Save>
          <Button.Cancel onClick={onClosed}>{t('Common:Cancel')}</Button.Cancel>
        </div>
      )}
    </form>
  )
}

export default FinancialForm
