// @flow

import { getApi, postApi, postRaw } from './api.core'

const getSessionHeader = uuid => ({
  WORKFLOWSESSION: uuid,
})

export const initWizard = (params): Promise<Object> =>
  postRaw('api/workflow/wizard/init/', params)
export const dryRun = (workflowId: number, params): Promise<Object> =>
  postRaw(`api/workflow/wizard/${workflowId}/dry-run/`, params)
export const prolong = (uuid: string): Promise<Object> =>
  postApi('api/workflow/wizard/prolong/', null, getSessionHeader(uuid))
export const getChunk = (uuid: string): Promise<Object> =>
  getApi('api/workflow/wizard/chunk/', null, getSessionHeader(uuid))
export const sendPayloadChunk = (uuid, payload) =>
  postApi('api/workflow/wizard/chunk/', payload, getSessionHeader(uuid))
export const publish = workflow => postApi(`api/workflow/${workflow}/publish/`)
