// @flow

import React from 'react'

type Params = {|
  counterKey?: string,
  exact?: boolean,
  icon: string,
  translationKey: string,
  unviewedCounterKey?: string,
  url: string,
|}

type PagesParams = {
  [string]: Params,
}

type PagesAliases = {
  [string]: string,
}

type FiltersParams = {
  [string]: Object,
}

type SubmenuCounters = {
  [string]: { [string]: string },
}

// MAIN MENU
export const MAIN_PATH_KEY = 'main'
export const MARKETPLACE_PATH_KEY = 'marketplace'
export const MAIL_PATH_KEY = 'mails'
export const CHAT_PATH_KEY = 'messages'
export const BUILDING_PATH_KEY = 'buildings'
export const BUILDING_GROUPS_PATH_KEY = 'groups'
export const CONTRACTORS_PATH_KEY = 'contractors'
export const FLAT_PATH_KEY = 'flats'
export const REQUEST_PATH_KEY = 'requests'
export const CONNECTED_REQUESTS_PATH_KEY = 'connected_requests'
export const ACTIVITY_PATH_KEY = 'activities'
export const FRIENDS_PATH_KEY = 'friends'
export const FAQ_PATH_KEY = 'faq'
export const RESIDENTS_PATH_KEY = 'residents'
export const USERS_PATH_KEY = 'users'
export const UK_PATH_KEY = 'uk'
export const FILES_PATH_KEY = 'files'
export const DWELLERS_FILES_PATH_KEY = 'dwellersFiles'
export const INVOICES_PATH_KEY = 'invoices'

// SETTINGS MENU
export const PERSONAL_PATH_KEY = 'settings/personal'
export const CORPORATE_PATH_KEY = 'settings/corporate'
export const SETTING_USERS_PATH_KEY = 'settings/users'
export const PERMISSIONS_PATH_KEY = 'settings/permissions'
export const INTEGRATIONS_PATH_KEY = 'settings/integrations'
export const DOMONDA_PATH_KEY = 'settings/domonda'
export const IMPORT_PATH_KEY = 'settings/import'
export const FIELDS_PATH_KEY = 'settings/fields'
export const WIZARD_PATH_KEY = 'settings/workflows'
export const MARKETPLACE_SETTINGS_PATH_KEY = 'settings/marketplace'
export const SETTINGS_EMAIL_PATH_KEY = 'settings/email'
export const SETTINGS_EMAIL_INTEGRATION_PATH_KEY = 'settings/email-integration'
export const SETTINGS_PIPELINE_PATH_KEY = 'settings/pipeline'
export const SETTINGS_AUTOMATE_PATH_KEY = 'settings/automate'
export const TEMPLATES_PATH_KEY = 'settings/templates'
export const DOC_TEMPLATES_PATH_KEY = 'settings/doc-templates'

// PSEUDO MENU
export const POST_ALIAS_KEY = 'post'
export const MAIL_ALIAS_KEY = 'mail'
export const CHATROOM_ALIAS_KEY = 'chatroom'
export const FLAT_ALIAS_KEY = 'flat'
export const MY_FLAT_ALIAS_KEY = 'my_flat'
export const MY_FLATS_ALIAS_KEY = 'my_flats'
export const BUILDINGS_ALIAS_KEY = 'building'
export const BUILDING_GROUPS_ALIAS_KEY = 'groups'
export const MANAGER_ALIAS_KEY = 'manager'
export const DWELLER_ALIAS_KEY = 'dweller'
export const REQUEST_ALIAS_KEY = 'request'
export const ACTIVITY_ALIAS_KEY = 'activities'
export const PROFILE_ALIAS_KEY = 'profile'
export const NEWSBANDS_ALIAS_KEY = 'newsbands'
export const WORKFLOW_ALIAS_KEY = 'settings/workflow'

// SUB MENU
export const SUBMENU_ALL_KEY = 'all'
export const SUBMENU_VIEWED_KEY = 'viewed'
export const SUBMENU_UNVIEWED_KEY = 'unviewed'
export const SUBMENU_NOT_ASSIGNED_KEY = 'not_assigned'
export const SUBMENU_ADMIN_KEY = 'admin'
export const SUBMENU_MANAGER_KEY = 'manager'
export const SUBMENU_DWELLER_KEY = 'dweller'
export const SUBMENU_LANDLORD_KEY = 'landlord'
export const SUBMENU_DWELLERS_LANDLORDS_KEY = 'dwellers_landlords'
export const SUBMENU_ADMINS_MANAGERS_KEY = 'admins_managers'
export const SUBMENU_EXTERNAL_KEY = 'all_external_contacts'
export const SUBMENU_SENT_EMAILS_KEY = 'sent_emails'
export const SUBMENU_INBOX_EMAILS_KEY = 'inbox_emails'
export const SUBMENU_DELETED_INBOX_EMAILS_KEY = 'inbox_deleted_emails'
export const SUBMENU_DELETED_SENT_EMAILS_KEY = 'sent_deleted_emails'
export const SUBMENU_VIEWED_INBOX_EMAILS_KEY = 'inbox_viewed_emails'
export const SUBMENU_UNVIEWED_INBOX_EMAILS_KEY = 'inbox_unviewed_emails'
export const SUBMENU_LINKED_INBOX_EMAILS_KEY = 'inbox_linked_emails'
export const SUBMENU_UNLINKED_INBOX_EMAILS_KEY = 'inbox_unlinked_emails'
export const SUBMENU_ARCHIVED_KEY = 'soft_archived'
export const SUBMENU_PRIVATE_KEY = 'private'
export const SUBMENU_TEMPLATE_PRIVATE_KEY = 'private_template'
export const SUBMENU_PUBLIC_KEY = 'public'
export const SUBMENU_TEMPLATE_PUBLIC_KEY = 'public_template'
export const SUBMENU_PROMO_KEY = 'promo'
export const SUBMENU_TEMPLATE_DEFAULT_KEY = 'default_template'
export const SUBMENU_FAVORITE_KEY = 'favorites'
export const SUBMENU_MY_KEY = 'my'
export const SUBMENU_B2B_KEY = 'b2b'
export const SUBMENU_B2C_KEY = 'b2c'
export const SUBMENU_DONE_KEY = 'done'
export const SUBMENU_NOT_DONE_KEY = 'not_done'

// ---EXPANDED FILTERS---

// MAIL
export const MAIL_CREDENTIAL_FILTER_KEY = 'mailCredentialFilter'

// ---FILTERS---

// MAIL
export const MAIL_TITLE_FILTER_KEY = 'mailTitleFilter'
export const MAIL_USER_FILTER_KEY = 'mailUserFilter'
export const MAIL_DATE_FILTER_KEY = 'mailDateFilter'
export const MAIL_FILE_FILTER_KEY = 'mailFileFilter'

// REQUEST
export const REQUEST_TITLE_FILTER_KEY = 'requestTitleFilter'
export const REQUEST_BUILDING_FILTER_KEY = 'requestBuildingFilter'

export const REQUEST_GROUP_FILTER_KEY = 'requestGroupFilter'
export const REQUEST_OWNER_FILTER_KEY = 'requestOwnerFilter'
export const REQUEST_ASSIGNEE_FILTER_KEY = 'requestAssigneeFilter'
export const REQUEST_PROVIDER_FILTER_KEY = 'requestProviderFilter'
export const REQUEST_RATING_FILTER_KEY = 'requestRatingFilter'
export const REQUEST_STATUS_FILTER_KEY = 'requestStatusFilter'
export const REQUEST_TYPE_FILTER_KEY = 'requestTypeNameFilter'
export const REQUEST_LABEL_FILTER_KEY = 'requestLabelFilter'
export const REQUEST_DATE_FILTER_KEY = 'requestDateFilter'
export const REQUEST_PRIVATE_DATE_FILTER = 'requestPrivateUpdatedFilter'
export const REQUEST_DUE_DATE_FILTER_KEY = 'requestDueDateFilter'
export const REQUEST_ARCHIVED_FILTER_KEY = 'requestArchivedFilter'

// ACTIVITY
export const ACTIVITY_REQUEST_FILTER_KEY = 'activityRequestFilter'
export const ACTIVITY_BUILDING_FILTER_KEY = 'activityBuildingFilter'
export const ACTIVITY_MANAGER_FILTER_KEY = 'activityManagerFilter'
export const ACTIVITY_TYPE_FILTER_KEY = 'activityTypeFilter'
export const ACTIVITY_PRIORITY_FILTER_KEY = 'activityPriorityFilter'
export const ACTIVITY_DONE_FILTER_KEY = 'activityDoneFilter'
export const ACTIVITY_DATE_FILTER_KEY = 'activityDateFilter'
export const ACTIVITY_GROUP_FILTER_KEY = 'activityGroupFilter'

// FILES
export const FILE_NAME_DIR_FILTER = 'nameDirFilter'
export const FILE_NAME_FILTER = 'nameFilter'
export const FILE_DATE_FILTER_KEY = 'dateFilter'

// FLAT
export const FLAT_NUMBER_FILTER_KEY = 'flatNumberFilter'
export const FLAT_DWELLER_FILTER_KEY = 'flatDwellerFilter'
export const FLAT_BUILDING_FILTER_KEY = 'flatBuildingFilter'
export const FLAT_DATE_FILTER_KEY = 'flatDateFilter'
export const FLAT_MOVE_OUT_DATE_FILTER_KEY = 'flatMoveOutDateFilter'
export const FLAT_ARCHIVED_FLAG_FILTER = 'flatArchivedFlagFilter'

export const FLAT_GROUP_FILTER = 'flatGroupFilter'

// BUILDING
export const BUILDING_ADDRESS_FILTER_KEY = 'buildingAddressFilter'
export const BUILDING_MANAGER_FILTER_KEY = 'buildingManagerFilter'
export const BUILDING_DATE_FILTER_KEY = 'buildingDateFilter'
export const BUILDING_PROPERTY_OWNER_FILTER_KEY = 'buildingPropertyOwnerFilter'
export const BUILDING_GROUP_FILTER_KEY = 'buildingGroupFilter'

//BUILDING GROUPS
export const BUILDING_GROUP_BUILDING_FILTER_KEY = 'buildingGroupBuildingFilter'
export const BUILDING_GROUP_MANAGER_FILTER_KEY = 'buildingGroupManagerFilter'
export const BUILDING_GROUP_NAME_FILTER_KEY = 'buildingGroupNameFilter'
export const BUILDING_GROUP_DWELLER_FILTER_KEY = 'buildingGroupDwellerFilter'
export const BUILDING_GROUP_DWELLERS_DWELLER_FILTER_KEY =
  'buildingGroupDwellersDwellerFilter'
export const BUILDING_GROUP_DWELLERS_BUILDING_FILTER_KEY =
  'buildingGroupDwellersBuildingFilter'

// MESSAGE
export const MESSAGE_DATE_FILTER_KEY = 'messageDateFilter'

// USER
export const USER_FULLNAME_FILTER_KEY = 'userFullNameFilter'
export const USER_BUILDING_FILTER_KEY = 'userBuildingFilter'
export const USER_EMAIL_FILTER_KEY = 'userEmailFilter'
export const USER_PHONE_FILTER_KEY = 'userPhoneFilter'
export const USER_STATUS_FILTER_KEY = 'userStatusFilter'
export const USER_DATE_FILTER_KEY = 'userDateFilter'
export const USER_ROLE_FILTER_KEY = 'userRoleFilter'
export const USER_MOVE_OUT_DATE_FILTER_KEY = 'userMoveOutDateFilter'
export const USER_GROUP_FILTER = 'userGroupFilter'

// USER STAFF SETTINGS
export const SETTINGS_STAFF_FILTER_KEY = 'settingsStaffFilter'
export const SETTINGS_STAFF_CATEGORIES_FILTER_KEY =
  'settingsStaffCategoryFilter'
export const SETTINGS_STAFF_BUILDING_FILTER_KEY = 'settingsStaffBuildingFilter'
export const SETTINGS_STAFF_GROUP_FILTER_KEY = 'settingsStaffGroupFilter'
export const SETTINGS_STAFF_EMAIL_FILTER_KEY = 'settingsStaffEmailFilter'
export const SETTINGS_STAFF_STATUS_FILTER_KEY = 'settingsStaffStatusFilter'
export const SETTINGS_STAFF_DATE_FILTER_KEY = 'settingsStaffDateFilter'

// USER DWELLER SETTINGS
export const SETTINGS_DWELLER_FILTER_KEY = 'settingsDwellerFilter'
export const SETTINGS_DWELLER_GROUP_FILTER_KEY = 'settingsDwellerGroupFilter'
export const SETTINGS_DWELLER_BUILDING_FILTER_KEY =
  'settingsDwellerBuildingFilter'
export const SETTINGS_DWELLER_EMAIL_FILTER_KEY = 'settingsDwellerEmailFilter'
export const SETTINGS_DWELLER_PHONE_FILTER_KEY = 'settingsDwellerPhoneFilter'
export const SETTINGS_DWELLER_STATUS_FILTER_KEY = 'settingsDwellerStatusFilter'
export const SETTINGS_DWELLER_DATE_FILTER_KEY = 'settingsDwellerDateFilter'

// USER EXTERNAL CONTACTS SETTINGS
export const SETTINGS_EXTERNAL_FILTER_KEY = 'settingsExternalContactsFilter'
export const SETTINGS_EXTERNAL_EMAIL_FILTER_KEY =
  'settingsExternalContactsEmailFilter'
export const SETTINGS_EXTERNAL_PHONE_FILTER_KEY =
  'settingsExternalContactsPhoneFilter'
export const SETTINGS_EXTERNAL_DATE_FILTER_KEY =
  'settingsExternalContactsDateFilter'
export const SETTINGS_EXTERNAL_ORIGIN_FILTER_KEY =
  'settingsExternalContactsOriginFilter'
// ---COUNTERS---
export const REQUEST_VIEWED_COUNTER_KEY = 'viewed'
export const REQUEST_UNVIEWED_COUNTER_KEY = 'unviewed'
export const REQUEST_ALL_COUNTER_KEY = 'all'
export const MAIL_VIEWED_COUNTER_KEY = 'viewed'
export const MAIL_UNVIEWED_COUNTER_KEY = 'unviewed'
export const MAIL_INBOX_COUNTER_KEY = 'inbox_emails'
export const MAIL_SENT_COUNTER_KEY = 'sent_emails'
export const CHAT_VIEWED_COUNTER_KEY = 'viewed'
export const CHAT_UNVIEWED_COUNTER_KEY = 'unviewed'
export const CHAT_ALL_COUNTER_KEY = 'all'
export const DWELLERS_COUNTER_KEY = 'dweller'
export const LANDLORDS_COUNTER_KEY = 'landlord'
export const DWELLERS_LANDLORDS_COUNTER_KEY = 'dwellers_landlords'
export const ADMINS_COUNTER_KEY = 'admin'
export const MANAGERS_COUNTER_KEY = 'manager'
export const ADMINS_MANAGERS_COUNTER_KEY = 'admins_managers'

// TEMPLATE
export const TEMPLATE_FULLNAME_FILTER_KEY = 'fullname'
export const TEMPLATE_TAG_FILTER_KEY = 'tag'
export const TEMPLATE_BUILDING_GROUP_FILTER_KEY = 'building_group'

// POST TEMPLATE
export const POST_TEMPLATE_FULLNAME_FILTER_KEY = 'post_fullname'

// EMAIL TEMPLATE
export const EMAIL_TEMPLATE_FULLNAME_FILTER_KEY = 'email_fullname'
export const EMAIL_TEMPLATE_TAG_FILTER_KEY = 'email_tag'

// ALL PROVIDERS
export const ALL_PROVIDERS_NAME_FILTER_KEY = 'allProvidersNameFilter'
export const ALL_PROVIDERS_EMAIL_FILTER_KEY = 'allProvidersEmailFilter'
export const ALL_PROVIDERS_PHONE_FILTER_KEY = 'allProvidersPhoneFilter'
export const ALL_PROVIDERS_REQUEST_CATEGORY_FILTER_KEY =
  'allProvidersRequestCategoryFilter'
export const ALL_PROVIDERS_BUILDING_FILTER_KEY = 'allProvidersBuildingFilter'
export const ALL_PROVIDERS_B2B_CATEGORY_FILTER_KEY =
  'allProvidersB2bCategoryFilter'
export const ALL_PROVIDERS_B2C_CATEGORY_FILTER_KEY =
  'allProvidersB2cCategoryFilter'
export const ALL_PROVIDERS_OFFER_CATEGORY_FILTER_KEY =
  'allProvidersOfferCategoryFilter'

// FAVORITE PROVIDERS
export const FAVORITE_PROVIDERS_NAME_FILTER_KEY = 'favoriteProvidersNameFilter'
export const FAVORITE_PROVIDERS_EMAIL_FILTER_KEY =
  'favoriteProvidersEmailFilter'
export const FAVORITE_PROVIDERS_PHONE_FILTER_KEY =
  'favoriteProvidersPhoneFilter'
export const FAVORITE_PROVIDERS_REQUEST_CATEGORY_FILTER_KEY =
  'favoriteProvidersRequestCategoryFilter'
export const FAVORITE_PROVIDERS_BUILDING_FILTER_KEY =
  'favoriteProvidersBuildingFilter'
export const FAVORITE_PROVIDERS_B2B_CATEGORY_FILTER_KEY =
  'favoriteProvidersB2bCategoryFilter'
export const FAVORITE_PROVIDERS_B2C_CATEGORY_FILTER_KEY =
  'favoriteProvidersB2cCategoryFilter'
export const FAVORITE_PROVIDERS_OFFER_CATEGORY_FILTER_KEY =
  'favoriteProvidersOfferCategoryFilter'

// OFFERS
export const OFFER_B2B_CATEGORY_FILTER_KEY = 'offerB2bCategoryFilter'
export const OFFER_B2C_CATEGORY_FILTER_KEY = 'offerB2cCategoryFilter'
export const OFFER_PROVIDER_NAME_FILTER_KEY = 'offerProviderNameFilter'
export const OFFER_CATEGORY_NAME_FILTER_KEY = 'offerCategoryNameFilter'

// ORDERS
export const ORDER_NUMBER_FILTER_KEY = 'orderNumberFilter'
export const ORDER_STATUS_FILTER_KEY = 'orderStatusFilter'
export const ORDER_PROVIDER_NAME_FILTER_KEY = 'orderProviderNameFilter'
export const ORDER_B2B_CATEGORY_FILTER_KEY = 'orderB2bCategoryFilter'
export const ORDER_B2C_CATEGORY_FILTER_KEY = 'orderB2cCategoryFilter'
export const ORDER_OFFER_CATEGORY_FILTER_KEY = 'orderOfferCategoryFilter'

// CONTRACTORS

export const CONTRACTOR_NAME_FILTER_KEY = 'contractorNameFilter'
export const CONTRACTOR_EMAIL_FILTER_KEY = 'contractorEmailFilter'
export const CONTRACTOR_PHONE_FILTER_KEY = 'contractorPhoneFilter'
export const CONTRACTOR_CATEGORY_FILTER_KEY = 'contractorCategoryFilter'
export const CONTRACTOR_BUILDING_FILTER_KEY = 'contractorBuildingFilter'

// AUTOMATION

export const AUTOMATE_NAME_FILTER_KEY = 'automateNameFilter'
export const AUTOMATE_STATUS_FILTER_KEY = 'automateStatusFilter'
export const AUTOMATE_INITIATOR_FILTER_KEY = 'automateInitiatorFilter'
export const AUTOMATE_DATE_FILTER_KEY = 'automateDateFilter'

// ---EXTENDED FILTER COMPONENTS---

// MAIL
export const MailCredentialFilter = React.lazy(() =>
  import('./filters/MailsCredentialFilter')
)

// ---FILTER COMPONENTS---

// MAIL
export const MailTitleFilter = React.lazy(() =>
  import('./filters/MailsTitleFilter')
)
export const MailUserFilter = React.lazy(() =>
  import('./filters/ThreadUserFilter')
)
export const MailDateFilter = React.lazy(() => import('./filters/DateFilter'))
export const MailFileFilter = React.lazy(() =>
  import('./filters/MailsFilesFilter')
)

// FILE
export const FileNameFilter = React.lazy(() =>
  import('./filters/FileNameFilter')
)
export const FileDateFilter = React.lazy(() => import('./filters/DateFilter'))

// MESSAGE
export const MessageDateFilter = React.lazy(() =>
  import('./filters/DateFilter')
)

// REQUEST
export const RequestTitleFilter = React.lazy(() =>
  import('./filters/RequestTitleFilter')
)
export const RequestBuildingFilter = React.lazy(() =>
  import('./filters/RequestBuildingFilter')
)
export const RequestGroupFilter = React.lazy(() =>
  import('./filters/RequestGroupFilter')
)
export const RequestOwnerFilter = React.lazy(() =>
  import('./filters/OwnerFilter')
)
export const RequestAssigneeFilter = React.lazy(() =>
  import('./filters/AssigneeFilter')
)
export const RequestProviderFilter = React.lazy(() =>
  import('./filters/RequestProviderFilter')
)
export const RequestLabelFilter = React.lazy(() =>
  import('./filters/RequestLabelFilter')
)
export const RequestRatingFilter = React.lazy(() =>
  import('./filters/RequestRatingFilter')
)
export const RequestStatusFilter = React.lazy(() =>
  import('./filters/RequestStatusFilter')
)
export const RequestTypeFilter = React.lazy(() =>
  import('./filters/RequestTypeFilter')
)
export const RequestDateFilter = React.lazy(() =>
  import('./filters/DateFilter')
)
export const RequestContractorFilter = React.lazy(() =>
  import('./filters/RequestContractorFilter')
)
export const RequestDueDateFilter = React.lazy(() =>
  import('./filters/DueDateFilter')
)

// ACTIVITY
export const ActivityRequestFilter = React.lazy(() =>
  import('./filters/ActivityRequestFilter')
)
export const ActivityBuildingFilter = React.lazy(() =>
  import('./filters/ActivityBuildingFilter')
)
export const ActivityManagerFilter = React.lazy(() =>
  import('./filters/ActivityManagerFilter')
)
export const ActivityTypeFilter = React.lazy(() =>
  import('./filters/ActivityTypeFilter')
)
export const ActivityPriorityFilter = React.lazy(() =>
  import('./filters/ActivityPriorityFilter')
)
export const ActivityDoneFilter = React.lazy(() =>
  import('./filters/ActivityDoneFilter')
)
export const ActivityDateFilter = React.lazy(() =>
  import('./filters/DateFilter')
)
export const ActivityGroupFilter = React.lazy(() =>
  import('./filters/ActivityGroupFilter')
)

// FLAT
export const FlatNumberFilter = React.lazy(() =>
  import('./filters/NumberFilter')
)
export const FlatDwellerFilter = React.lazy(() =>
  import('./filters/DwellersFilter')
)
export const FlatBuildingFilter = React.lazy(() =>
  import('./filters/BuildingFilter')
)
export const FlatDateFilter = React.lazy(() => import('./filters/DateFilter'))
export const FlatMoveOutDateFilter = React.lazy(() =>
  import('./filters/DateFilter')
)

export const FlatArchivedFlagFilter = React.lazy(() =>
  import('./filters/FlatArchivedFlagFilter')
)

export const FlatGroupFilter = React.lazy(() =>
  import('./filters/GroupFlatFilter')
)

// BUILDING
export const BuildingAddressFilter = React.lazy(() =>
  import('./filters/AddressFilter')
)
export const BuildingManagerFilter = React.lazy(() =>
  import('./filters/ManagerFilter')
)

export const BuildingPropertyOwnerFilter = React.lazy(() =>
  import('./filters/PropertyOwnerFilter')
)

export const BuildingDateFilter = React.lazy(() =>
  import('./filters/DateFilter')
)

//BUILDING GROUPS

export const BuildingGroupFilter = React.lazy(() =>
  import('./filters/GroupFilter')
)

export const BuildingByGroupFilter = React.lazy(() =>
  import('./filters/GroupBuildingFilter')
)

export const BuildingGroupDwellerFilter = React.lazy(() =>
  import('./filters/GroupDwellersFilter')
)

// USER
export const UserFullNameFilter = React.lazy(() =>
  import('./filters/UserFullNameFilter')
)

export const UserGroupFilter = React.lazy(() =>
  import('./filters/GroupFilterProfile')
)
export const UserBuildingFilter = React.lazy(() =>
  import('./filters/UserBuildingFilter')
)
export const UserEmailFilter = React.lazy(() =>
  import('./filters/UserEmailFilter')
)
export const UserPhoneFilter = React.lazy(() =>
  import('./filters/UserPhoneFilter')
)
export const UserStatusFilter = React.lazy(() =>
  import('./filters/UserStatusFilter')
)
export const UserDateFilter = React.lazy(() => import('./filters/DateFilter'))
export const UserRoleFilter = React.lazy(() => import('./filters/RoleFilter'))
export const UserMoveOutDateFilter = React.lazy(() =>
  import('./filters/DateFilter')
)

// USER STAFF SETTINGS
export const SettingsStaffFilter = React.lazy(() =>
  import('./filters/SettingsStaffFilter')
)
export const SettingsStaffCategoryFilter = React.lazy(() =>
  import('./filters/SettingsCategoryStaffFilter')
)
export const SettingsStaffBuildingFilter = React.lazy(() =>
  import('./filters/SettingsBuildingStaffFilter')
)
export const SettingsStaffEmailFilter = React.lazy(() =>
  import('./filters/SettingsEmailStaffFilter')
)
export const SettingsStaffStatusFilter = React.lazy(() =>
  import('./filters/UserStatusFilter')
)
export const SettingsStaffDateFilter = React.lazy(() =>
  import('./filters/DateFilter')
)

// USER DWELLER SETTINGS
export const SettingsDwellerFilter = React.lazy(() =>
  import('./filters/SettingsDwellersFilter')
)
export const SettingsDwellerBuildingFilter = React.lazy(() =>
  import('./filters/SettingsBuildingDwellersFilter')
)
export const SettingsDwellerEmailFilter = React.lazy(() =>
  import('./filters/SettingsEmailDwellersFilter')
)
export const SettingsDwellerPhoneFilter = React.lazy(() =>
  import('./filters/SettingsPhoneDwellersFilter')
)
export const SettingsDwellerStatusFilter = React.lazy(() =>
  import('./filters/UserStatusFilter')
)
export const SettingsDwellerDateFilter = React.lazy(() =>
  import('./filters/DateFilter')
)

// EXTERNAL CONTACTS SETTINGS
export const SettingsExternalFilter = React.lazy(() =>
  import('./filters/SettingsExternalFilter')
)
export const SettingsExternalEmailFilter = React.lazy(() =>
  import('./filters/SettingsEmailExternalFilter')
)
export const SettingsExternalPhoneFilter = React.lazy(() =>
  import('./filters/SettingsPhoneExternalFilter')
)
export const SettingsExternalOriginFilter = React.lazy(() =>
  import('./filters/SettingsExternalOriginFilter')
)
export const SettingsDateExternalFilter = React.lazy(() =>
  import('./filters/DateFilter')
)

// TEMPLATE
export const TemplateFullnameFilter = React.lazy(() =>
  import('./filters/TemplateFullnameFilter')
)
export const TemplateTagFilter = React.lazy(() =>
  import('./filters/TemplateTagFilter')
)

export const TemplateGroupFilter = React.lazy(() =>
  import('./filters/TemplateGroupFilter')
)

// POST TEMPLATE
export const PostTemplateFullnameFilter = React.lazy(() =>
  import('./filters/PostTemplateFullnameFilter')
)

// EMAIL TEMPLATE
export const EmailTemplateFullnameFilter = React.lazy(() =>
  import('./filters/EmailTemplateFullnameFilter')
)
export const EmailTemplateTagFilter = React.lazy(() =>
  import('./filters/EmailTemplateTagFilter')
)

// All PROVIDERS
export const AllProvidersNameFilter = React.lazy(() =>
  import('./filters/AllProvidersNameFilter')
)
export const AllProvidersEmailFilter = React.lazy(() =>
  import('./filters/AllProvidersEmailFilter')
)
export const AllProvidersPhoneFilter = React.lazy(() =>
  import('./filters/AllProvidersPhoneFilter')
)
export const AllProvidersRequestCategoryFilter = React.lazy(() =>
  import('./filters/AllProvidersRequestCategoryFilter')
)
export const AllProvidersBuildingFilter = React.lazy(() =>
  import('./filters/AllProvidersBuildingFilter')
)
export const AllProvidersOfferCategoryFilter = React.lazy(() =>
  import('./filters/OfferCategoryFilter')
)

// FAVORITE PROVIDERS
export const FavoriteProvidersNameFilter = React.lazy(() =>
  import('./filters/FavoriteProvidersNameFilter')
)
export const FavoriteProvidersEmailFilter = React.lazy(() =>
  import('./filters/FavoriteProvidersEmailFilter')
)
export const FavoriteProvidersPhoneFilter = React.lazy(() =>
  import('./filters/FavoriteProvidersPhoneFilter')
)
export const FavoriteProvidersBuildingFilter = React.lazy(() =>
  import('./filters/FavoriteProvidersBuildingFilter')
)
export const FavoriteProvidersRequestCategoryFilter = React.lazy(() =>
  import('./filters/FavoriteProvidersRequestCategoryFilter')
)
export const FavoriteProvidersOfferCategoryFilter = React.lazy(() =>
  import('./filters/FavoriteProvidersOfferCategoryFilter')
)

// OFFERS
export const OfferProviderNameFilter = React.lazy(() =>
  import('./filters/OfferProviderNameFilter')
)

// ORDERS
export const OrderNumberFilter = React.lazy(() =>
  import('./filters/OrderNumberFilter')
)
export const OrderStatusFilter = React.lazy(() =>
  import('./filters/OrderStatusFilter')
)
export const OrderProviderNameFilter = React.lazy(() =>
  import('./filters/OrderProviderNameFilter')
)

// CONTRACTORS

export const ContractorFilter = React.lazy(() =>
  import('./filters/ContractorFilter')
)

export const ContractorBuildingFilter = React.lazy(() =>
  import('./filters/ContractorBuildingFilter')
)

export const ContractorCategoryFilter = React.lazy(() =>
  import('./filters/ContractorCategoryFilter')
)

// AUTOMATIONS

export const AutomateFilter = React.lazy(() =>
  import('./filters/AutomateFilter')
)

export const AutomateDateFilter = React.lazy(() =>
  import('./filters/DateFilter')
)

export const AutomateStatusFilter = React.lazy(() =>
  import('./filters/AutomateStatusFilter')
)

export const FILTERS_ALIASES = {
  [REQUEST_PATH_KEY]: {
    unviewed: { is_unviewed: true },
    viewed: { is_unviewed: false },
    soft_archived: { soft_archived: true },
    not_assigned: { not_assigned: true },
    done: { done: true },
    not_done: { done: false },
    all: {},
  },
  [ACTIVITY_PATH_KEY]: {
    unviewed: { is_unviewed: true },
    viewed: { is_unviewed: false },
    soft_archived: { soft_archived: true },
    not_assigned: { not_assigned: true },
    done: { done: true },
    not_done: { done: false },
    all: {},
  },
  [MAIL_PATH_KEY]: {
    inbox_unviewed_emails: { unviewed_msg: true },
    inbox_viewed_emails: { unviewed_msg: false },
    sent_deleted_emails: { soft_archived: true },
    inbox_deleted_emails: { soft_archived: true },
    inbox_linked_emails: { linked_requests: true },
    inbox_unlinked_emails: { linked_requests: false },
    all: {},
  },
  [CHAT_PATH_KEY]: {
    unviewed: { unviewed_msg: true },
    viewed: { unviewed_msg: false },
    soft_archived: { soft_archived: true },
    all: {},
  },
  [FLAT_PATH_KEY]: {
    soft_archived: { soft_archived: true },
    all: {},
  },
  [BUILDING_PATH_KEY]: {
    soft_archived: { soft_archived: true },
    promo: { is_promo: 1 },
    all: {},
  },
  [BUILDING_GROUPS_PATH_KEY]: {
    soft_archived: { soft_archived: true },
    all: {},
  },
  [CONTRACTORS_PATH_KEY]: {
    soft_archived: { soft_archived: true },
    all: {},
  },
  [RESIDENTS_PATH_KEY]: {
    all: {},
  },
  [SETTING_USERS_PATH_KEY]: {
    all: {},
    soft_archived: { soft_archived: true },
    admin: { group: 'admin' },
    manager: { group: 'manager' },
    dweller: { is_landlord: false },
    landlord: { is_landlord: true },
  },
  [USERS_PATH_KEY]: {
    all: {},
    soft_archived: { soft_archived: true },
    dweller: { is_landlord: false },
    landlord: { is_landlord: true },
  },
  [FILES_PATH_KEY]: {
    all: {},
    private: { users_access: 'deny' },
    public: { users_access: 'allow' },
    soft_archived: { soft_archived: true },
  },
  [DWELLERS_FILES_PATH_KEY]: {
    all: {},
    private: { users_access: 'deny' },
    public: { users_access: 'allow' },
    soft_archived: { soft_archived: true },
  },
  [TEMPLATES_PATH_KEY]: {
    all: {},
    default_template: { default: true },
    private_template: { privacy: true },
    public_template: { privacy: false },
  },
  [MARKETPLACE_PATH_KEY]: {
    all: { all: true },
    b2b: { store: 'b2b' },
    b2c: { store: 'b2c' },
    favorites: { all: false },
    my: { all: false },
  },
}

export const PAGES_PARAMS: PagesParams = {
  [MAIN_PATH_KEY]: {
    url: '',
    icon: 'home',
    translationKey: 'Home',
    exact: true,
    unviewedCounterKey: 'unread_posts',
  },
  [MARKETPLACE_PATH_KEY]: {
    url: 'marketplace',
    icon: 'offer',
    translationKey: 'Marketplace',
  },
  [CONTRACTORS_PATH_KEY]: {
    url: 'contractors',
    icon: 'provider',
    translationKey: 'Contractors',
  },
  [MAIL_PATH_KEY]: {
    url: 'mails',
    icon: 'email',
    translationKey: 'MailMenuTitle',
    counterKey: 'all_emails',
    unviewedCounterKey: 'unread_emails',
  },
  [CHAT_PATH_KEY]: {
    url: 'messages',
    icon: 'chat',
    translationKey: 'Messages',
    counterKey: 'all_chats',
    unviewedCounterKey: 'unread_chats',
  },
  [BUILDING_PATH_KEY]: {
    url: 'buildings',
    icon: 'building',
    translationKey: 'Buildings',
  },
  [BUILDING_GROUPS_PATH_KEY]: {
    url: 'groups',
    icon: 'groups',
    translationKey: 'BuildingGroups',
  },
  [FLAT_PATH_KEY]: {
    url: 'flats',
    icon: 'apartment',
    translationKey: 'Flats',
  },
  [MY_FLAT_ALIAS_KEY]: {
    url: 'flats',
    icon: 'apartment',
    translationKey: 'MyApartment',
  },
  [MY_FLATS_ALIAS_KEY]: {
    url: 'flats',
    icon: 'apartment',
    translationKey: 'MyApartments',
  },
  [REQUEST_PATH_KEY]: {
    url: 'requests',
    icon: 'request',
    translationKey: 'Request',
    counterKey: 'all_requests',
    unviewedCounterKey: 'unviewed_requests',
  },
  [CONNECTED_REQUESTS_PATH_KEY]: {
    url: 'connected_requests',
    icon: 'connect',
    translationKey: 'ConnectedRequests',
  },
  [ACTIVITY_PATH_KEY]: {
    url: 'activities',
    icon: 'ActivityMenu',
    translationKey: 'Activity',
    counterKey: 'all_activity',
    unviewedCounterKey: 'unviewed_activity',
  },
  [FRIENDS_PATH_KEY]: {
    url: 'friends',
    icon: 'friends',
    translationKey: 'MyNeighbours',
    counterKey: 'friend_invites',
    unviewedCounterKey: 'friend_invites',
  },
  [FAQ_PATH_KEY]: {
    url: 'faq',
    icon: 'faq',
    translationKey: 'FAQ',
  },
  [RESIDENTS_PATH_KEY]: {
    url: 'residents',
    icon: 'people',
    translationKey: 'Dwellers',
  },
  [UK_PATH_KEY]: {
    url: 'uk',
    icon: 'about',
    translationKey: 'AboutUs',
  },
  [FILES_PATH_KEY]: {
    url: 'files',
    icon: 'folder',
    translationKey: 'Files',
  },
  [DWELLERS_FILES_PATH_KEY]: {
    url: 'dwellers-files',
    icon: 'folder',
    translationKey: 'DwellersFiles',
  },
  [INVOICES_PATH_KEY]: {
    url: 'invoices',
    icon: 'folder',
    translationKey: 'Invoices',
  },
  [PERSONAL_PATH_KEY]: {
    url: 'settings/personal',
    icon: 'info',
    translationKey: 'Personal',
  },
  [CORPORATE_PATH_KEY]: {
    url: 'settings/corporate',
    icon: 'settings',
    translationKey: 'Corporate',
  },
  [USERS_PATH_KEY]: {
    url: 'users/',
    icon: 'user',
    translationKey: 'Users',
  },
  [SETTING_USERS_PATH_KEY]: {
    url: 'settings/users',
    icon: 'people',
    translationKey: 'SettingsUsers',
  },
  [PERMISSIONS_PATH_KEY]: {
    url: 'settings/permissions',
    icon: 'lock',
    translationKey: 'UserPermissions',
  },
  [INTEGRATIONS_PATH_KEY]: {
    url: 'settings/integrations',
    icon: 'integrations',
    translationKey: 'integrations',
  },
  [DOMONDA_PATH_KEY]: {
    url: 'settings/domonda',
    icon: 'integrations',
    translationKey: 'DomondaIntegration',
  },
  [IMPORT_PATH_KEY]: {
    url: 'settings/import',
    icon: 'upload',
    translationKey: 'Import',
  },
  [FIELDS_PATH_KEY]: {
    url: 'settings/fields',
    icon: 'objects',
    translationKey: 'ObjectsAndFields',
  },
  [WIZARD_PATH_KEY]: {
    url: 'settings/workflows',
    icon: 'constructor',
    translationKey: 'Workflows',
  },
  [TEMPLATES_PATH_KEY]: {
    url: 'settings/templates',
    icon: 'templates',
    translationKey: 'Templates',
  },
  [DOC_TEMPLATES_PATH_KEY]: {
    url: 'settings/doc-templates',
    icon: 'request',
    translationKey: 'DocumentTemplates',
  },
  [MARKETPLACE_SETTINGS_PATH_KEY]: {
    url: 'settings/marketplace',
    icon: 'marketplace',
    translationKey: 'marketplace',
  },
  [SETTINGS_EMAIL_PATH_KEY]: {
    url: 'settings/email',
    icon: 'email',
    translationKey: 'EmailIntegration',
  },
  [SETTINGS_EMAIL_INTEGRATION_PATH_KEY]: {
    url: 'settings/email-integration',
    icon: 'email',
    translationKey: 'EmailIntegrationNew',
  },
  [SETTINGS_PIPELINE_PATH_KEY]: {
    url: 'settings/pipeline',
    icon: 'pipeline',
    translationKey: 'Pipelines',
  },
  [SETTINGS_AUTOMATE_PATH_KEY]: {
    url: 'settings/automate',
    icon: 'automate',
    translationKey: 'Automate',
  },
}

export const PAGES_ALIASES: PagesAliases = {
  [POST_ALIAS_KEY]: 'main',
  [MAIL_ALIAS_KEY]: 'mails',
  [CHATROOM_ALIAS_KEY]: 'messages',
  [FLAT_ALIAS_KEY]: 'flats',
  [BUILDINGS_ALIAS_KEY]: 'buildings',
  [BUILDING_GROUPS_ALIAS_KEY]: 'groups',
  [MANAGER_ALIAS_KEY]: 'uk',
  [DWELLER_ALIAS_KEY]: 'residents',
  [REQUEST_ALIAS_KEY]: 'requests',
  [ACTIVITY_ALIAS_KEY]: 'activities',
  [PROFILE_ALIAS_KEY]: 'users',
  [NEWSBANDS_ALIAS_KEY]: 'main',
  [WORKFLOW_ALIAS_KEY]: 'settings/workflows',
}

export const SUBMENU_TRANSLATIONS = {
  [SUBMENU_ALL_KEY]: 'All',
  [SUBMENU_VIEWED_KEY]: 'Viewed',
  [SUBMENU_UNVIEWED_KEY]: 'Unviewed',
  [SUBMENU_NOT_ASSIGNED_KEY]: 'Unassigned',
  [SUBMENU_ADMIN_KEY]: 'Admin',
  [SUBMENU_MANAGER_KEY]: 'Manager',
  [SUBMENU_DWELLER_KEY]: 'Dweller',
  [SUBMENU_LANDLORD_KEY]: 'Landlord',
  [SUBMENU_DWELLERS_LANDLORDS_KEY]: 'All',
  [SUBMENU_ADMINS_MANAGERS_KEY]: 'All',
  [SUBMENU_EXTERNAL_KEY]: 'All',
  [SUBMENU_SENT_EMAILS_KEY]: 'SentEmails',
  [SUBMENU_INBOX_EMAILS_KEY]: 'InboxEmails',
  [SUBMENU_VIEWED_INBOX_EMAILS_KEY]: 'Viewed',
  [SUBMENU_UNVIEWED_INBOX_EMAILS_KEY]: 'Unviewed',
  [SUBMENU_DELETED_INBOX_EMAILS_KEY]: 'Deleted',
  [SUBMENU_DELETED_SENT_EMAILS_KEY]: 'Deleted',
  [SUBMENU_LINKED_INBOX_EMAILS_KEY]: 'WithLinks',
  [SUBMENU_UNLINKED_INBOX_EMAILS_KEY]: 'WithoutLinks',
  [SUBMENU_ARCHIVED_KEY]: 'Deleted',
  [SUBMENU_PRIVATE_KEY]: 'Private',
  [SUBMENU_TEMPLATE_PRIVATE_KEY]: 'PrivateTemplate',
  [SUBMENU_TEMPLATE_DEFAULT_KEY]: 'DefaultTemplate',
  [SUBMENU_PUBLIC_KEY]: 'Public',
  [SUBMENU_TEMPLATE_PUBLIC_KEY]: 'PublicTemplate',
  [SUBMENU_PROMO_KEY]: 'Promo',
  [SUBMENU_FAVORITE_KEY]: 'Favorites',
  [SUBMENU_MY_KEY]: 'My',
  [SUBMENU_B2B_KEY]: 'B2b',
  [SUBMENU_B2C_KEY]: 'B2c',
  [SUBMENU_DONE_KEY]: 'IsDone',
  [SUBMENU_NOT_DONE_KEY]: 'IsNotDone',
}

export const MENU_LOCALE_STORAGE_KEY: string = 'menu-collapsed'

export const EXPANDED_FILTERS_PARAMS: FiltersParams = {
  [MAIL_CREDENTIAL_FILTER_KEY]: {
    component: MailCredentialFilter,
    translationKey: 'MailsCredentialFilter',
    filterKey: 'credentials',
  },
}

export const FILTERS_PARAMS: FiltersParams = {
  // MAIL

  [MAIL_TITLE_FILTER_KEY]: {
    component: MailTitleFilter,
    translationKey: 'MailsTitleFilter',
    filterKey: 'title',
    valueKey: 'title_search_value',
    labelKey: 'title',
    searchKey: 'title',
  },
  [MAIL_USER_FILTER_KEY]: {
    component: MailUserFilter,
    translationKey: 'ThreadUserFilter',
    filterKey: 'thread_user',
    valueKey: 'id',
    labelKey: 'fullname',
    descriptionKey: 'email',
    avatarKey: 'avatar',
    searchKey: 'fullname_or_email',
  },
  [MAIL_DATE_FILTER_KEY]: {
    component: MailDateFilter,
    translationKey: 'DateFilter',
    filterKey: { from: 'created_gte', to: 'created_lte' },
  },
  [MAIL_FILE_FILTER_KEY]: {
    component: MailFileFilter,
    translationKey: 'MailsFilesFilter',
    filterKey: 'attached_filename',
    searchKey: 'filename',
    valueKey: 'filename',
    labelKey: 'filename',
  },

  // MESSAGE
  [MESSAGE_DATE_FILTER_KEY]: {
    component: MessageDateFilter,
    translationKey: 'DateFilter',
  },

  // REQUEST
  [REQUEST_TITLE_FILTER_KEY]: {
    component: RequestTitleFilter,
    translationKey: 'RequestTitleFilter',
    valueKey: 'id',
    filterKey: 'id',
    searchKey: 'search',
  },
  [REQUEST_BUILDING_FILTER_KEY]: {
    component: RequestBuildingFilter,
    translationKey: 'BuildingFilter',
    filterKey: 'building_address',
    valueKey: 'building_address',
    searchKey: 'building_address',
    labelKey: 'building_address',
  },
  [REQUEST_GROUP_FILTER_KEY]: {
    component: RequestGroupFilter,
    translationKey: 'GroupFilter',
    filterKey: 'building_group',
    valueKey: 'value',
    labelKey: 'label',
    searchKey: 'building_group_name',
  },
  [REQUEST_OWNER_FILTER_KEY]: {
    component: RequestOwnerFilter,
    translationKey: 'OwnerFilter',
    filterKey: 'owner_fullname',
    valueKey: 'owner_fullname',
    searchKey: 'owner_fullname',
    labelKey: 'owner_fullname',
  },
  [REQUEST_ASSIGNEE_FILTER_KEY]: {
    component: RequestAssigneeFilter,
    translationKey: 'AssigneeFilter',
    filterKey: 'assignee_fullname',
    searchKey: 'fullname',
    valueKey: 'fullname',
    labelKey: 'fullname',
  },
  [REQUEST_PROVIDER_FILTER_KEY]: {
    component: RequestContractorFilter,
    translationKey: 'RequestProviderFilter',
    valueKey: 'id',
    filterKey: 'contractor',
    labelKey: 'fullname',
    searchKey: 'fullname',
  },
  [REQUEST_RATING_FILTER_KEY]: {
    component: RequestRatingFilter,
    translationKey: 'RatingFilter',
    filterKey: 'rating',
    valueKey: 'value',
    labelKey: 'label',
  },
  [REQUEST_STATUS_FILTER_KEY]: {
    component: RequestStatusFilter,
    translationKey: 'StatusFilter',
    filterKey: 'status',
    valueKey: 'value',
    labelKey: 'label',
  },
  [REQUEST_TYPE_FILTER_KEY]: {
    component: RequestTypeFilter,
    translationKey: 'RequestTypeFilter',
    filterKey: 'request_type_name',
    searchKey: 'request_type_name',
    labelKey: 'request_type_name',
    valueKey: 'request_type_name',
  },
  [REQUEST_LABEL_FILTER_KEY]: {
    component: RequestLabelFilter,
    translationKey: 'RequestLabelFilter',
    filterKey: 'label_name',
    searchKey: 'name',
    labelKey: 'name',
    valueKey: 'name',
  },
  [REQUEST_DATE_FILTER_KEY]: {
    component: RequestDateFilter,
    translationKey: 'DateFilter',
  },
  [REQUEST_PRIVATE_DATE_FILTER]: {
    component: RequestDateFilter,
    translationKey: 'DateFilter',
    filterKey: { from: 'private_updated_gte', to: 'private_updated_lte' },
  },
  [REQUEST_DUE_DATE_FILTER_KEY]: {
    component: RequestDateFilter,
    translationKey: 'DueDateFilter',
    filterKey: { from: 'due_date_gte', to: 'due_date_lte' },
  },
  [REQUEST_ARCHIVED_FILTER_KEY]: {
    component: FlatArchivedFlagFilter,
    translationKey: 'FlatArchivedFlagFilter',
    filterKey: 'archived',
    valueKey: 'value',
    labelKey: 'label',
  },

  // ACTIVITY
  [ACTIVITY_REQUEST_FILTER_KEY]: {
    component: ActivityRequestFilter,
    translationKey: 'ActivityRequestFilter',
    valueKey: 'id',
    filterKey: 'request',
    searchKey: 'search',
  },
  [ACTIVITY_BUILDING_FILTER_KEY]: {
    component: ActivityBuildingFilter,
    translationKey: 'ActivityBuildingFilter',
    filterKey: 'building',
    searchKey: 'address',
  },
  [ACTIVITY_MANAGER_FILTER_KEY]: {
    component: ActivityManagerFilter,
    translationKey: 'ActivityManagerFilter',
    filterKey: 'manager',
    searchKey: 'fullname',
    avatarKey: 'avatar',
  },
  [ACTIVITY_TYPE_FILTER_KEY]: {
    component: ActivityTypeFilter,
    translationKey: 'ActivityTypeFilter',
    labelKey: 'name',
    searchKey: 'name',
    filterKey: 'type',
    valueKey: 'name',
  },
  [ACTIVITY_PRIORITY_FILTER_KEY]: {
    component: ActivityPriorityFilter,
    translationKey: 'ActivityPriorityFilter',
    labelKey: 'value',
    searchKey: 'search',
    filterKey: 'priority',
    valueKey: 'id',
  },
  [ACTIVITY_DONE_FILTER_KEY]: {
    component: ActivityDoneFilter,
    translationKey: 'ActivityDoneFilter',
    labelKey: 'label',
    filterKey: 'done',
    valueKey: 'value',
  },
  [ACTIVITY_DATE_FILTER_KEY]: {
    component: ActivityDateFilter,
    translationKey: 'ActivityDeadlineFilter',
    filterKey: { from: 'deadline_gte', to: 'deadline_lte' },
  },
  [ACTIVITY_GROUP_FILTER_KEY]: {
    component: ActivityGroupFilter,
    translationKey: 'GroupFilter',
    filterKey: 'building_group',
    valueKey: 'value',
    labelKey: 'label',
    searchKey: 'search',
  },

  // FILES
  [FILE_NAME_FILTER]: {
    component: FileNameFilter,
    translationKey: 'FileNameFilter',
    filterKey: 'id',
    searchKey: 'name',
    valueKey: 'id',
    labelKey: 'name',
  },
  [FILE_NAME_DIR_FILTER]: {
    component: FileNameFilter,
    translationKey: 'FileNameFilter',
    filterKey: 'id',
    searchKey: 'name',
    valueKey: 'id',
    labelKey: 'name',
  },
  [FILE_DATE_FILTER_KEY]: {
    component: FileDateFilter,
    translationKey: 'FileDateFilter',
    filterKey: { from: 'created_gte', to: 'created_lte' },
  },

  // FLAT
  [FLAT_NUMBER_FILTER_KEY]: {
    component: FlatNumberFilter,
    translationKey: 'NumberFilter',
    filterKey: 'number',
    labelKey: 'number',
    searchKey: 'number_icontains',
    valueKey: 'number',
  },
  [FLAT_DWELLER_FILTER_KEY]: {
    component: FlatDwellerFilter,
    translationKey: 'DwellersFilter',
    filterKey: 'owner',
    valueKey: 'id',
    searchKey: 'fullname',
    avatarKey: 'avatar',
  },
  [FLAT_BUILDING_FILTER_KEY]: {
    component: FlatBuildingFilter,
    translationKey: 'BuildingFilter',
    filterKey: 'building',
    valueKey: 'id',
    searchKey: 'address',
  },
  [FLAT_DATE_FILTER_KEY]: {
    component: FlatDateFilter,
    translationKey: 'DateFilter',
    filterKey: { from: 'updated_gte', to: 'updated_lte' },
  },
  [FLAT_MOVE_OUT_DATE_FILTER_KEY]: {
    component: FlatMoveOutDateFilter,
    translationKey: 'FlatMoveOutDateFilter',
    filterKey: { from: 'contract_end_gte', to: 'contract_end_lte' },
  },
  [FLAT_ARCHIVED_FLAG_FILTER]: {
    component: FlatArchivedFlagFilter,
    translationKey: 'FlatArchivedFlagFilter',
    filterKey: 'archived',
    valueKey: 'value',
    labelKey: 'label',
  },
  [FLAT_GROUP_FILTER]: {
    component: FlatGroupFilter,
    translationKey: 'GroupFilter',
    filterKey: 'building_group',
    valueKey: 'value',
    labelKey: 'label',
    searchKey: 'search',
  },
  // BUILDING
  [BUILDING_ADDRESS_FILTER_KEY]: {
    component: BuildingAddressFilter,
    translationKey: 'AddressFilter',
    filterKey: 'id',
    valueKey: 'id',
    searchKey: 'address',
  },
  [BUILDING_MANAGER_FILTER_KEY]: {
    component: BuildingManagerFilter,
    translationKey: 'ManagerFilter',
    filterKey: 'same_buildings_for',
    valueKey: 'id',
    searchKey: 'fullname',
    avatarKey: 'avatar',
  },
  [BUILDING_PROPERTY_OWNER_FILTER_KEY]: {
    component: BuildingPropertyOwnerFilter,
    translationKey: 'PropertyOwnerFilter',
    filterKey: 'property_owner',
    valueKey: 'id',
    searchKey: 'fullname_or_email',
    avatarKey: 'avatar',
  },
  [BUILDING_DATE_FILTER_KEY]: {
    component: BuildingDateFilter,
    translationKey: 'DateFilter',
  },

  [BUILDING_GROUP_FILTER_KEY]: {
    component: BuildingByGroupFilter,
    translationKey: 'GroupFilter',
    filterKey: 'building_group',
    valueKey: 'value',
    labelKey: 'label',
    searchKey: 'search',
  },
  // BUILDING GROUPS

  [BUILDING_GROUP_BUILDING_FILTER_KEY]: {
    component: BuildingGroupFilter,
    translationKey: 'BuildingFilter',
    filterKey: 'building',
    valueKey: 'value',
    labelKey: 'label',
    searchKey: 'search',
  },

  [BUILDING_GROUP_MANAGER_FILTER_KEY]: {
    component: BuildingGroupFilter,
    translationKey: 'ManagerFilter',
    filterKey: 'responsible_user',
    valueKey: 'value',
    labelKey: 'label',
    searchKey: 'search',
  },

  [BUILDING_GROUP_NAME_FILTER_KEY]: {
    component: BuildingGroupFilter,
    translationKey: 'GroupFilter',
    filterKey: 'id',
    valueKey: 'value',
    labelKey: 'label',
    searchKey: 'search',
  },

  [BUILDING_GROUP_DWELLER_FILTER_KEY]: {
    component: BuildingGroupDwellerFilter,
    translationKey: 'DwellersFilter',
    filterKey: 'inhabitant',
    valueKey: 'value',
    labelKey: 'label',
    searchKey: 'search',
  },

  [BUILDING_GROUP_DWELLERS_DWELLER_FILTER_KEY]: {
    component: BuildingGroupDwellerFilter,
    translationKey: 'DwellersFilter',
    filterKey: 'id',
    valueKey: 'value',
    labelKey: 'label',
    searchKey: 'search',
  },

  [BUILDING_GROUP_DWELLERS_BUILDING_FILTER_KEY]: {
    component: BuildingGroupDwellerFilter,
    translationKey: 'BuildingFilter',
    filterKey: 'building',
    valueKey: 'value',
    labelKey: 'label',
    searchKey: 'search',
  },

  // USER
  [USER_FULLNAME_FILTER_KEY]: {
    component: UserFullNameFilter,
    translationKey: 'UserFullNameFilter',
    filterKey: 'id',
    valueKey: 'id',
    searchKey: 'fullname',
    avatarKey: 'avatar',
  },
  [USER_GROUP_FILTER]: {
    component: UserGroupFilter,
    translationKey: 'GroupFilter',
    filterKey: 'building_group',
    valueKey: 'value',
    labelKey: 'label',
    searchKey: 'search',
  },
  [USER_BUILDING_FILTER_KEY]: {
    component: UserBuildingFilter,
    translationKey: 'UserBuildingFilter',
    filterKey: 'building',
    valueKey: 'id',
    searchKey: 'address',
  },
  [USER_EMAIL_FILTER_KEY]: {
    component: UserEmailFilter,
    translationKey: 'UserEmailFilter',
    filterKey: 'email',
    searchKey: 'email',
    labelKey: 'email',
    valueKey: 'email',
    avatarKey: 'avatar',
  },
  [USER_PHONE_FILTER_KEY]: {
    component: UserPhoneFilter,
    translationKey: 'UserPhoneFilter',
    filterKey: 'phone',
    searchKey: 'phone',
    valueKey: 'phone',
    labelKey: 'phone',
  },
  [USER_STATUS_FILTER_KEY]: {
    component: UserStatusFilter,
    translationKey: 'UserStatusFilter',
    filterKey: 'status',
    valueKey: 'value',
    labelKey: 'label',
  },
  [USER_DATE_FILTER_KEY]: {
    component: UserDateFilter,
    translationKey: 'DateFilter',
    filterKey: { from: 'created_gte', to: 'created_lte' },
  },
  [USER_ROLE_FILTER_KEY]: {
    component: UserRoleFilter,
    translationKey: 'RoleFilter',
    filterKey: 'is_landlord',
    labelKey: 'label',
    valueKey: 'value',
  },
  [USER_MOVE_OUT_DATE_FILTER_KEY]: {
    component: UserMoveOutDateFilter,
    translationKey: 'UserMoveOutDateFilter',
    filterKey: { from: 'move_out_date_gte', to: 'move_out_date_lte' },
  },

  // USER STAFF SETTINGS
  [SETTINGS_STAFF_FILTER_KEY]: {
    component: SettingsStaffFilter,
    translationKey: 'SettingsStaffFilter',
    filterKey: 'id',
    searchKey: 'fullname',
    avatarKey: 'avatar',
  },
  [SETTINGS_STAFF_CATEGORIES_FILTER_KEY]: {
    component: SettingsStaffCategoryFilter,
    translationKey: 'SettingsCategoriesStaffFilter',
    filterKey: 'category',
    valueKey: 'id',
    searchKey: 'name',
  },
  [SETTINGS_STAFF_BUILDING_FILTER_KEY]: {
    component: SettingsStaffBuildingFilter,
    translationKey: 'SettingsBuildingStaffFilter',
    filterKey: 'building',
    valueKey: 'id',
    searchKey: 'address',
  },
  [SETTINGS_STAFF_GROUP_FILTER_KEY]: {
    component: UserGroupFilter,
    translationKey: 'GroupFilter',
    filterKey: 'building_group',
    valueKey: 'value',
    labelKey: 'label',
    searchKey: 'search',
  },
  [SETTINGS_STAFF_EMAIL_FILTER_KEY]: {
    component: SettingsStaffEmailFilter,
    translationKey: 'SettingsEmailStaffFilter',
    filterKey: 'email',
    searchKey: 'email',
    labelKey: 'email',
    valueKey: 'email',
    avatarKey: 'avatar',
  },
  [SETTINGS_STAFF_STATUS_FILTER_KEY]: {
    component: SettingsStaffStatusFilter,
    translationKey: 'UserStatusFilter',
    filterKey: 'status',
    valueKey: 'value',
    labelKey: 'label',
  },
  [SETTINGS_STAFF_DATE_FILTER_KEY]: {
    component: SettingsStaffDateFilter,
    translationKey: 'DateFilter',
  },

  // USER EXTERNAL CONTACTS SETTINGS
  [SETTINGS_EXTERNAL_FILTER_KEY]: {
    component: SettingsExternalFilter,
    translationKey: 'SettingsExternalFilter',
    filterKey: 'id',
    searchKey: 'fullname',
    avatarKey: 'avatar',
  },
  [SETTINGS_EXTERNAL_EMAIL_FILTER_KEY]: {
    component: SettingsExternalEmailFilter,
    translationKey: 'SettingsEmailExternalFilter',
    filterKey: 'email',
    searchKey: 'email',
    labelKey: 'email',
    valueKey: 'email',
    avatarKey: 'avatar',
  },
  [SETTINGS_EXTERNAL_PHONE_FILTER_KEY]: {
    component: SettingsExternalPhoneFilter,
    translationKey: 'SettingsPhoneExternalFilter',
    filterKey: 'phone',
    searchKey: 'phone',
    labelKey: 'phone',
    valueKey: 'phone',
    avatarKey: 'avatar',
  },
  [SETTINGS_EXTERNAL_ORIGIN_FILTER_KEY]: {
    component: SettingsExternalOriginFilter,
    translationKey: 'SettingsOriginExternalFilter',
    filterKey: 'origin',
    valueKey: 'value',
    labelKey: 'label',
  },
  [SETTINGS_EXTERNAL_DATE_FILTER_KEY]: {
    component: SettingsDateExternalFilter,
    translationKey: 'DateFilter',
  },
  // USER DWELLER SETTINGS
  [SETTINGS_DWELLER_FILTER_KEY]: {
    component: SettingsDwellerFilter,
    translationKey: 'SettingsDwellersFilter',
    filterKey: 'id',
    searchKey: 'fullname',
    avatarKey: 'avatar',
  },
  [SETTINGS_DWELLER_BUILDING_FILTER_KEY]: {
    component: SettingsDwellerBuildingFilter,
    translationKey: 'SettingsBuildingDwellersFilter',
    filterKey: 'building',
    valueKey: 'id',
    searchKey: 'address',
  },
  [SETTINGS_DWELLER_GROUP_FILTER_KEY]: {
    component: UserGroupFilter,
    translationKey: 'GroupFilter',
    filterKey: 'building_group',
    valueKey: 'value',
    labelKey: 'label',
    searchKey: 'search',
  },
  [SETTINGS_DWELLER_EMAIL_FILTER_KEY]: {
    component: SettingsDwellerEmailFilter,
    translationKey: 'SettingsEmailDwellersFilter',
    filterKey: 'email',
    searchKey: 'email',
    labelKey: 'email',
    valueKey: 'email',
    avatarKey: 'avatar',
  },
  [SETTINGS_DWELLER_PHONE_FILTER_KEY]: {
    component: SettingsDwellerPhoneFilter,
    translationKey: 'SettingsPhoneDwellersFilter',
    filterKey: 'phone',
    searchKey: 'phone',
    labelKey: 'phone',
    valueKey: 'phone',
    avatarKey: 'avatar',
  },
  [SETTINGS_DWELLER_STATUS_FILTER_KEY]: {
    component: SettingsDwellerStatusFilter,
    translationKey: 'UserStatusFilter',
    filterKey: 'status',
    valueKey: 'value',
    labelKey: 'label',
  },
  [SETTINGS_DWELLER_DATE_FILTER_KEY]: {
    component: SettingsDwellerDateFilter,
    translationKey: 'DateFilter',
  },

  // TEMPLATE
  [TEMPLATE_FULLNAME_FILTER_KEY]: {
    component: TemplateFullnameFilter,
    translationKey: 'FullnameFilter',
    filterKey: 'fullname',
    searchKey: 'fullname',
    valueKey: 'fullname',
    labelKey: 'fullname',
    avatarKey: 'avatar',
  },
  [TEMPLATE_TAG_FILTER_KEY]: {
    component: TemplateTagFilter,
    translationKey: 'TagFilter',
    filterKey: 'tag',
    searchKey: 'name',
    valueKey: 'name',
    labelKey: 'description',
  },
  [TEMPLATE_BUILDING_GROUP_FILTER_KEY]: {
    component: TemplateGroupFilter,
    translationKey: 'GroupFilter',
    filterKey: 'building_group',
    searchKey: 'search',
    valueKey: 'value',
    labelKey: 'label',
  },

  // POST TEMPLATE
  [POST_TEMPLATE_FULLNAME_FILTER_KEY]: {
    component: PostTemplateFullnameFilter,
    translationKey: 'PostFullnameFilter',
    filterKey: 'fullname',
    valueKey: 'fullname',
    searchKey: 'fullname',
    labelKey: 'fullname',
    avatarKey: 'avatar',
  },

  // EMAIL TEMPLATE
  [EMAIL_TEMPLATE_FULLNAME_FILTER_KEY]: {
    component: EmailTemplateFullnameFilter,
    translationKey: 'FullnameFilter',
    filterKey: 'fullname',
    searchKey: 'fullname',
    valueKey: 'fullname',
    labelKey: 'fullname',
    avatarKey: 'avatar',
  },

  [EMAIL_TEMPLATE_TAG_FILTER_KEY]: {
    component: EmailTemplateTagFilter,
    translationKey: 'TagFilter',
    filterKey: 'tag',
    searchKey: 'name',
    valueKey: 'name',
    labelKey: 'description',
  },

  // ALL PROVIDERS
  [ALL_PROVIDERS_NAME_FILTER_KEY]: {
    component: AllProvidersNameFilter,
    translationKey: 'AllProvidersNameFilter',
    filterKey: 'name',
    searchKey: 'name',
    valueKey: 'value',
    labelKey: 'label',
  },
  [ALL_PROVIDERS_EMAIL_FILTER_KEY]: {
    component: AllProvidersEmailFilter,
    translationKey: 'AllProvidersEmailFilter',
    filterKey: 'email',
    searchKey: 'email',
    valueKey: 'email',
    labelKey: 'email',
  },
  [ALL_PROVIDERS_PHONE_FILTER_KEY]: {
    component: AllProvidersPhoneFilter,
    translationKey: 'AllProvidersPhoneFilter',
    filterKey: 'phone',
    searchKey: 'phone',
    labelKey: 'label',
    valueKey: 'value',
  },
  [ALL_PROVIDERS_BUILDING_FILTER_KEY]: {
    component: AllProvidersBuildingFilter,
    translationKey: 'AllProvidersBuildingFilter',
    filterKey: 'address',
    valueKey: 'address',
    labelKey: 'address',
    searchKey: 'address',
  },
  [ALL_PROVIDERS_REQUEST_CATEGORY_FILTER_KEY]: {
    component: AllProvidersRequestCategoryFilter,
    translationKey: 'AllProvidersRequestCategoryFilter',
    filterKey: 'request_category',
    searchKey: 'category',
    labelKey: 'name',
    valueKey: 'name',
  },
  [ALL_PROVIDERS_B2B_CATEGORY_FILTER_KEY]: {
    component: AllProvidersOfferCategoryFilter,
    translationKey: 'AllProvidersB2bCategoryFilter',
    filterKey: 'b2b',
    filterType: 'b2b',
  },
  [ALL_PROVIDERS_B2C_CATEGORY_FILTER_KEY]: {
    component: AllProvidersOfferCategoryFilter,
    translationKey: 'AllProvidersB2cCategoryFilter',
    filterKey: 'b2c',
    filterType: 'b2c',
  },

  // FAVORITE PROVIDERS
  [FAVORITE_PROVIDERS_NAME_FILTER_KEY]: {
    component: FavoriteProvidersNameFilter,
    translationKey: 'FavoriteProvidersNameFilter',
    filterKey: 'name',
    searchKey: 'name',
    valueKey: 'value',
    labelKey: 'label',
  },
  [FAVORITE_PROVIDERS_EMAIL_FILTER_KEY]: {
    component: FavoriteProvidersEmailFilter,
    translationKey: 'FavoriteProvidersEmailFilter',
    filterKey: 'email',
    searchKey: 'email',
    valueKey: 'email',
    labelKey: 'email',
  },
  [FAVORITE_PROVIDERS_PHONE_FILTER_KEY]: {
    component: FavoriteProvidersPhoneFilter,
    translationKey: 'FavoriteProvidersPhoneFilter',
    filterKey: 'phone',
    searchKey: 'phone',
    labelKey: 'label',
    valueKey: 'value',
  },
  [FAVORITE_PROVIDERS_REQUEST_CATEGORY_FILTER_KEY]: {
    component: FavoriteProvidersRequestCategoryFilter,
    translationKey: 'FavoriteProvidersRequestCategoryFilter',
    filterKey: 'request_category',
    searchKey: 'category',
    labelKey: 'name',
    valueKey: 'name',
  },
  [FAVORITE_PROVIDERS_BUILDING_FILTER_KEY]: {
    component: FavoriteProvidersBuildingFilter,
    translationKey: 'FavoriteProvidersBuildingFilter',
    filterKey: 'address',
    valueKey: 'address',
    labelKey: 'address',
    searchKey: 'address',
  },
  [FAVORITE_PROVIDERS_B2B_CATEGORY_FILTER_KEY]: {
    component: AllProvidersOfferCategoryFilter,
    translationKey: 'FavoriteProvidersB2bCategoryFilter',
    filterKey: 'b2b',
    filterType: 'b2b',
  },
  [FAVORITE_PROVIDERS_B2C_CATEGORY_FILTER_KEY]: {
    component: AllProvidersOfferCategoryFilter,
    translationKey: 'FavoriteProvidersB2cCategoryFilter',
    filterKey: 'b2c',
    filterType: 'b2c',
  },
  [FAVORITE_PROVIDERS_OFFER_CATEGORY_FILTER_KEY]: {
    component: AllProvidersOfferCategoryFilter,
    translationKey: 'FavoriteProvidersOfferCategoryFilter',
    filterKey: 'category_id',
    valueKey: 'value',
    labelKey: 'label',
  },

  // OFFERS
  [OFFER_B2B_CATEGORY_FILTER_KEY]: {
    component: AllProvidersOfferCategoryFilter,
    translationKey: 'OfferB2bCategoryFilter',
    filterKey: 'b2b',
    filterType: 'b2b',
    checkSub: true,
  },
  [OFFER_B2C_CATEGORY_FILTER_KEY]: {
    component: AllProvidersOfferCategoryFilter,
    translationKey: 'OfferB2cCategoryFilter',
    filterKey: 'b2c',
    filterType: 'b2c',
    checkSub: true,
  },
  [OFFER_CATEGORY_NAME_FILTER_KEY]: {
    component: AllProvidersOfferCategoryFilter,
    translationKey: 'OfferCategoryNameFilter',
    filterKey: 'category_id',
  },
  [OFFER_PROVIDER_NAME_FILTER_KEY]: {
    component: OfferProviderNameFilter,
    translationKey: 'OfferProviderNameFilter',
    filterKey: 'providerName',
    searchKey: 'name',
    valueKey: 'value',
    labelKey: 'label',
  },

  // ORDERS
  [ORDER_NUMBER_FILTER_KEY]: {
    component: OrderNumberFilter,
    translationKey: 'OrderNumberFilter',
    filterKey: 'orderNo',
    valueKey: 'value',
    labelKey: 'label',
  },
  [ORDER_STATUS_FILTER_KEY]: {
    component: OrderStatusFilter,
    translationKey: 'OrderStatusFilter',
    filterKey: 'status',
    valueKey: 'value',
    labelKey: 'label',
  },
  [ORDER_PROVIDER_NAME_FILTER_KEY]: {
    component: OrderProviderNameFilter,
    translationKey: 'OrderProviderNameFilter',
    filterKey: 'providerName',
    valueKey: 'value',
    labelKey: 'label',
    searchKey: 'name',
  },
  [ORDER_B2B_CATEGORY_FILTER_KEY]: {
    component: AllProvidersOfferCategoryFilter,
    translationKey: 'OrderB2bCategoryFilter',
    filterKey: 'b2b',
    filterType: 'b2b',
  },
  [ORDER_B2C_CATEGORY_FILTER_KEY]: {
    component: AllProvidersOfferCategoryFilter,
    translationKey: 'OrderB2cCategoryFilter',
    filterKey: 'b2c',
    filterType: 'b2c',
  },
  [ORDER_OFFER_CATEGORY_FILTER_KEY]: {
    component: AllProvidersOfferCategoryFilter,
    translationKey: 'OrderOfferCategoryFilter',
    filterKey: 'category_id',
  },

  // CONTRACTORS
  [CONTRACTOR_NAME_FILTER_KEY]: {
    component: ContractorFilter,
    translationKey: 'RequestProviderFilter',
    valueKey: 'name',
    filterKey: 'name',
    labelKey: 'name',
    searchKey: 'name',
  },
  [CONTRACTOR_EMAIL_FILTER_KEY]: {
    component: ContractorFilter,
    translationKey: 'ContractorEmailFilter',
    valueKey: 'email',
    filterKey: 'email',
    labelKey: 'email',
    searchKey: 'email',
  },
  [CONTRACTOR_PHONE_FILTER_KEY]: {
    component: ContractorFilter,
    translationKey: 'ContractorPhoneFilter',
    valueKey: 'phone',
    filterKey: 'phone',
    labelKey: 'phone',
    searchKey: 'phone',
  },
  [CONTRACTOR_CATEGORY_FILTER_KEY]: {
    component: ContractorCategoryFilter,
    translationKey: 'AllProvidersRequestCategoryFilter',
    filterKey: 'category',
    searchKey: 'name',
    labelKey: 'name',
    valueKey: 'id',
  },
  [CONTRACTOR_BUILDING_FILTER_KEY]: {
    component: ContractorBuildingFilter,
    translationKey: 'AllProvidersBuildingFilter',
    filterKey: 'building',
    valueKey: 'id',
    labelKey: 'address',
    searchKey: 'address',
  },
  //AUTOMATIONS
  [AUTOMATE_NAME_FILTER_KEY]: {
    component: AutomateFilter,
    translationKey: 'AutomateFilterName',
    valueKey: 'value',
    descriptionKey: 'name',
    filterKey: 'name',
    searchKey: 'search',
    labelKey: 'label',
  },
  [AUTOMATE_INITIATOR_FILTER_KEY]: {
    component: AutomateFilter,
    translationKey: 'AutomateFilterInitiator',
    valueKey: 'value',
    descriptionKey: 'initiated_by',
    filterKey: 'initiated_by_id',
    searchKey: 'search',
    labelKey: 'label',
  },
  [AUTOMATE_DATE_FILTER_KEY]: {
    component: AutomateDateFilter,
    translationKey: 'AutomateDateFilter',
    filterKey: { from: 'created_gte', to: 'created_lte' },
  },
  [AUTOMATE_STATUS_FILTER_KEY]: {
    component: AutomateStatusFilter,
    translationKey: 'AutomateStatusFilter',
    filterKey: 'status',
    valueKey: 'value',
    labelKey: 'label',
  },
}

export const SUBMENU_COUNTERS: SubmenuCounters = {
  [REQUEST_PATH_KEY]: {
    [REQUEST_ALL_COUNTER_KEY]: 'unviewed_requests',
    tab: 'services',
  },
  [MAIL_PATH_KEY]: {
    [MAIL_INBOX_COUNTER_KEY]: 'unread_emails',
  },
  [CHAT_PATH_KEY]: {
    [CHAT_ALL_COUNTER_KEY]: 'unread_chats',
  },
}

export const FLAT_PATH_ALIASES = [MY_FLAT_ALIAS_KEY, MY_FLATS_ALIAS_KEY]
