// @flow

import { getApi, postRaw, patchRaw, deleteApi, deleteRaw } from './api.core'

export const createBuilding = credentials =>
  postRaw('api/building/', credentials)
export const updateBuilding = (id, params) =>
  patchRaw(`api/building/${id}/`, params)
export const updateBuildingInfo = (id, params) =>
  patchRaw(`api/building/${id}/info`, params)
export const getList = params => getApi('api/building/', params)
export const getListTiny = params => getApi('api/building-tiny/', params)
export const getBuildingOptions = params =>
  getApi('api/building-options/', params)
export const getListLiveSearch = params =>
  getApi('api/building/live-search/', params)
export const getListSearch = params =>
  getApi('api/building/search/', { ...params, query: params.search })
export const getArchivedListTiny = params =>
  getApi('api/archived/building-tiny/', params)
export const getBuilding = id => getApi(`api/building/${id}/`)
export const getBuildingInfo = ({ id }) => getApi(`api/building/${id}/info`)
export const getBuildingFlats = (id, params) =>
  getApi(`api/building/${id}/flats/`, params)
export const getGroupsByBuilding = (id, params) =>
  getApi(`api/building/${id}/building-groups/`, params)
export const deleteBuildingFromGroups = (id, params) =>
  deleteRaw(`api/building/${id}/building-groups/`, params)

export const getBuildingGroups = params => getApi(`api/building-group/`, params)

export const getBuildingGroupsTiny = params =>
  getApi('api/building-group/tiny/', params)

export const createGroup = params => postRaw(`api/building-group/`, params)
export const addGroupManagers = params =>
  postRaw(`api/building-group/bulk-add-responsible-users/`, params)
export const updateGroup = (id, params) =>
  patchRaw(`api/building-group/${id}/`, params)
export const updateGroupManagers = (id, params) =>
  postRaw(`api/building-group/${id}/responsible-users/`, params)

export const deleteGroup = (id, params) =>
  deleteApi(`api/building-group/${id}/`, params)
export const getGroup = id => getApi(`api/building-group/${id}/`)

export const getGroupFilter = params =>
  getApi(`api/building-group/single-field/`, params)

export const getBuildingGroupFilter = params =>
  getApi(`api/building-group/options/`, params)

export const getGroupBuildings = (id, params) =>
  getApi(`api/building-group/${id}/buildings/`, params)
export const updateGroupBuildings = (id, params) =>
  patchRaw(`api/building-group/${id}/buildings/`, params)
export const removeFromGroup = (id, params) =>
  deleteRaw(`api/building-group/${id}/buildings/`, params)

export const getGroupRequests = params =>
  getApi(`api/building-group-request-list/`, params)
export const getGroupFlats = (id, params) =>
  getApi(`api/building-group/${id}/flats/`, params)
export const getGroupDwellers = (id, params) =>
  getApi(`api/building-group/${id}/inhabitants/`, params)
export const getGroupDwellersFilter = (id, params) =>
  getApi(`api/building-group/${id}/inhabitants/single-field/`, params)

export const getLivingType = params => getApi('api/living-type/', params)
