// @flow

import React from 'react'
import { useEffect, useState } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { isEqual } from 'lodash-es'
import type { Node } from 'react'

import Button from '../../../../components/Button'
import Modal from '../../../../components/Modal'
import ModalBody from '../../../../components/Modal/ModalBody'
import ModalCloseButton from '../../../../components/Modal/ModalCloseButton'
import ModalHead from '../../../../components/Modal/ModalHead'
import ModalButtons from '../../../../components/Modal/ModalButtons'
import { useOverflow, useSelected } from '../../../../hooks'
import NewTable from '../../../../components/NewTable'
import NewTableHeader from '../../../../components/NewTable/NewTableHeader'
import NewTableHeaderRow from '../../../../components/NewTable/NewTableHeaderRow'
import NewTableHeaderCell from '../../../../components/NewTable/NewTableHeaderCell'
import NewTableBody from '../../../../components/NewTable/NewTableBody'
import NewTableBodyRow from '../../../../components/NewTable/NewTableBodyRow'
import NewTableBodyCell from '../../../../components/NewTable/NewTableBodyCell'
import Checkbox from '../../../../components/Checkbox'
import { getRequestThreads } from '../../../../core/api/api.request'
import {
  getFetchedItems,
  getFormattedDate,
  getUserName,
} from '../../../../utils/utils'
import { updateActivity } from '../../../../core/api/api.pipeline'
import Text from '../../../../components/NewTable/NewTableHeaderCell/Text'
import { ALL_PAGES } from '../../../../constants'
import EmptyList from '../../../../components/EmptyList'

import styles from './ThreadModal.module.scss'

type Props = {
  activity: { activityId: number, threads: Array<Object> },
  onClose: () => void,
  requestId: number,
}

const ThreadModal = (props: Props): Node => {
  const {
    requestId,
    activity: { activityId, threads: initialThreads },
  } = props

  const initilaUuids = initialThreads.map(thread => thread.uuid)

  const [threads, setThreads] = useState(null)
  const [isLoading, setLoading] = useState(false)

  const [selectedItems, changeSelected, setSelected, isAllSelected] =
    useSelected(initilaUuids)

  useEffect(() => {
    getRequestThreads(requestId, { page_size: ALL_PAGES }).then(data => {
      setThreads(getFetchedItems(data))
    })
  }, [])

  const handleSave = () => {
    setLoading(true)
  }

  useEffect(() => {
    if (isLoading) {
      updateActivity(activityId, {
        request_threads: selectedItems,
      }).then(() => {
        props.onClose()
        props.setReloading(true)
      })
    }
  }, [isLoading])

  useOverflow()

  const { t } = useTranslation('Request')

  if (!threads) {
    return null
  }

  const handleChange = e => {
    changeSelected(e.currentTarget.value)
  }

  const handleChangeAll = () => {
    if (isAllSelected(threads)) {
      setSelected([])
    } else {
      setSelected(threads.map(thread => thread.uuid))
    }
  }

  const handleCancel = () => {
    if (isEqual(selectedItems, initilaUuids)) {
      props.onClose()
    } else {
      setSelected(initilaUuids)
    }
  }

  const tableClass = classnames({
    'working-overlay': isLoading,
  })

  return (
    <Modal
      isOpen
      style={{
        content: { minWidth: '732px', maxWidth: '732px' },
      }}
      onRequestClose={props.onClose}
    >
      <ModalHead title={t('ThreadsAttachingTitle')} />
      <ModalCloseButton onClose={props.onClose} />
      <ModalBody style={{ maxHeight: '604px', overflow: 'auto' }}>
        <NewTable className={tableClass}>
          <NewTableHeader>
            <NewTableHeaderRow>
              <NewTableHeaderCell style={{ width: '40%' }}>
                <Checkbox
                  outer
                  disabled={!threads.length || isLoading}
                  checked={isAllSelected(threads)}
                  selected={!!selectedItems.length && !isAllSelected(threads)}
                  onChange={handleChangeAll}
                />
                <Text title={t('OwnerName')} />
              </NewTableHeaderCell>
              <NewTableHeaderCell
                title={t('Subject')}
                style={{ width: '40%' }}
              />
              <NewTableHeaderCell
                title={t('ThreadUpdated')}
                style={{ width: '20%' }}
              />
            </NewTableHeaderRow>
          </NewTableHeader>
          {threads.map(thread => (
            <NewTableBody hovered key={thread.uuid}>
              <NewTableBodyRow hovered={false} className={styles.row}>
                <NewTableBodyCell
                  title={getUserName(thread.last_message.owner_obj)}
                >
                  <Checkbox
                    value={thread.uuid}
                    checked={selectedItems.includes(thread.uuid)}
                    className={styles.checkbox}
                    onChange={handleChange}
                  />
                </NewTableBodyCell>
                <NewTableBodyCell title={thread.title} />
                <NewTableBodyCell
                  title={getFormattedDate(thread.last_message.created)}
                />
              </NewTableBodyRow>
              {thread.notes && (
                <NewTableBodyRow hovered={false}>
                  <NewTableBodyCell colSpan={3}>
                    <div className={styles.notes}>
                      <span>{thread.notes}</span>
                    </div>
                  </NewTableBodyCell>
                </NewTableBodyRow>
              )}
            </NewTableBody>
          ))}
        </NewTable>
        {!threads.length && !isLoading && (
          <EmptyList icon='email' title={t('EmailsNotFound')} />
        )}
      </ModalBody>
      <ModalButtons>
        <Button.Save working={isLoading} onClick={handleSave}>
          {t('Common:Save')}
        </Button.Save>
        <Button.Cancel working={isLoading} onClick={handleCancel}>
          {t('Common:Cancel')}
        </Button.Cancel>
      </ModalButtons>
    </Modal>
  )
}

export default ThreadModal
