// @flow

import { all, put, call, fork, takeEvery, select } from 'redux-saga/effects'
import { replace } from 'connected-react-router'

import api from '../../core/api'
import { serverError, modalError } from '../../components/Layout/Layout.actions'
import * as actions from './RequestView.constants'
import * as actionCreators from './RequestView.actions'

const getIds = files => files.map(f => f.id)

function* watchUpdateFiles() {
  yield takeEvery(actions.REQUEST_VIEW_UPDATE_FILES, updateFiles)
}

function* watchInit() {
  yield takeEvery(actions.REQUEST_VIEW_INITIATING, init)
}

function* watchUpdateInfo() {
  yield takeEvery(actions.REQUEST_VIEW_UPDATE_INFO, updateInfo)
}

function* watchUpdateDueDateInit() {
  yield takeEvery(actions.UPDATE_DUE_DATE_INIT, updateDueDate)
}

function* watchUpdateBody() {
  yield takeEvery(actions.REQUEST_VIEW_UPDATE_BODY, updateBody)
}

function* watchCreateChecklistInit() {
  yield takeEvery(actions.CREATE_CHECKLIST_INIT, createChecklist)
}

function* watchDeleteChecklistInit() {
  yield takeEvery(actions.DELETE_CHECKLIST_INIT, deleteChecklist)
}

function* updateFiles({ files, id }) {
  try {
    const ids = getIds(files)
    const params = { files: JSON.stringify(ids) }
    const data = yield call(api.request.updateRequest, id, params)
    const logs = yield call(api.request.getLogs, id)
    yield put({ type: actions.REQUEST_VIEW_UPDATED, data, logs })
  } catch (error) {
    console.log(error)
    yield put(serverError(error))
    yield put({ type: actions.REQUEST_VIEW_ERROR, error })
  }
}

function* init(action) {
  try {
    const { id } = action

    const data = yield call(api.request.getRequest, id)

    let checklists = []

    if (data.permissions.can_view_checklists) {
      const {
        results: { objects },
      } = yield call(api.request.getChecklists, id)

      checklists = objects
    }

    yield put({
      type: actions.REQUEST_VIEW_INITIATED,
      data,
      checklists,
    })
  } catch (error) {
    if (error.message.response.status === 404) {
      yield put(replace('/404'))
    } else {
      yield put(serverError(error))
      yield put({ type: actions.REQUEST_VIEW_ERROR, error })
    }
  }
}

function* updateInfo(action) {
  try {
    const { params, id } = action

    const data = yield call(api.request.updateRequestRaw, id, params)

    yield put({ type: actions.REQUEST_VIEW_UPDATED, data })
  } catch (error) {
    yield put({ type: actions.REQUEST_VIEW_ERROR, error })

    if (error.message.response.status === 400) {
      yield put(modalError(error.message.response.data))

      return
    }

    yield put(serverError(error))
  }
}

function* updateDueDate({ requestId, dueDate }) {
  try {
    const params = {
      due_date: dueDate,
    }

    const request = yield call(api.request.updateRequest, requestId, params)

    yield put(actionCreators.updateDueDateSuccess(request))
  } catch (error) {
    yield put(actionCreators.updateDueDateError(error))

    if (error.message.response.status === 400) {
      yield put(modalError(error.message.response.data))

      return
    }

    yield put(serverError(error))
  }
}

function* updateBody(action) {
  try {
    const { data, id } = action

    const result = yield call(api.request.updateRequest, id, data)

    yield put({ type: actions.REQUEST_VIEW_UPDATED, data: result })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.REQUEST_VIEW_ERROR, error })
  }
}

function* createChecklist({ requestId, name, templateId }) {
  try {
    const params = {
      request: requestId,
      name,
    }

    if (templateId !== undefined) {
      params.template = templateId
    }

    const checklist = yield call(api.checklist.createChecklist, params)
    yield put(actionCreators.createChecklistSuccess(checklist))
    yield put({ type: actions.REQUEST_VIEW_HIDE_MODAL })
  } catch (error) {
    yield put(serverError(error))
    yield put(actionCreators.createChecklistError(error))
  }
}

function* deleteChecklist({ checklistId }) {
  try {
    yield call(api.checklist.deleteChecklist, checklistId)
    yield put(actionCreators.deleteChecklistSuccess(checklistId))
  } catch (error) {
    yield put(serverError(error))
    yield put(actionCreators.deleteChecklistError(error))
  }
}

function* watchUpdateLabels() {
  yield takeEvery(actions.REQUEST_VIEW_UPDATE_LABELS, updateRequestLabels)
}

function* updateRequestLabels({ labels }) {
  try {
    const id = yield select(state => state.requestView.data.id)
    const labelsIds = labels.filter(l => l.checked).map(l => l.id)
    const requestResponse = yield call(api.request.updateRequest, id, {
      labels: JSON.stringify(labelsIds),
    })
    yield put({
      type: actions.REQUEST_VIEW_LABEL_UPDATED,
      request: requestResponse,
    })
    yield put({ type: actions.REQUEST_VIEW_HIDE_MODAL })
  } catch (error) {
    yield put(serverError(error))
  }
}

export default function* watch() {
  yield all([
    fork(watchInit),
    fork(watchUpdateInfo),
    fork(watchUpdateBody),
    fork(watchCreateChecklistInit),
    fork(watchUpdateFiles),
    fork(watchDeleteChecklistInit),
    fork(watchUpdateDueDateInit),
    fork(watchUpdateLabels),
  ])
}
