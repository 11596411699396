// @flow

import React, { Component } from 'react'
import { map, uniqBy } from 'lodash-es'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Autosuggest from 'react-autosuggest'
import TagsInput from 'react-tagsinput'
import { compose } from 'redux'
import { graphql, fetchQuery } from 'react-relay'
import type { Dispatch } from 'redux'

import environment from '../../createRelayEnvironment'
import * as actions from './UserSuggest.actionTypes'
import Loader from '../Loader/Loader'
import { EMAIL_REG_EXP } from '../../constants'

const ALL_PAGES_PARAM = 0
const INFINITY_FIRST = 100500

type Props = {
  dispatch: Dispatch,
  emailCheck: boolean,
  id: string,
  initialValue: string,
  isLoading: boolean,
  isProviderList: boolean,
  managers: Array<Object>,
  onChange: (Array<string>) => void,
  onlyManagers: boolean,
  profileCheck: boolean,
  value: string,
}

type State = {
  isValidInput: boolean,
  providers: Array<Object>,
  providersLoading: boolean,
}

const query = graphql`
  query UserSuggestQuery(
    $pageSize: Float!
    $page: Float!
    $first: Int!
    $list: String!
  ) {
    allProviders(pageSize: $pageSize, page: $page, first: $first, ids: $list) {
      edges {
        node {
          name
          image {
            thumbnails {
              url
            }
          }
          contactEmail
        }
      }
    }
  }
`

function renderTag(props) {
  const {
    tag,
    key,
    disabled,
    onRemove,
    classNameRemove,
    getTagDisplayValue,
    ...other
  } = props

  const avatar = tag.profile && tag.profile.avatar

  return (
    <span key={key} {...other}>
      {avatar && <img className='avatar' alt='userpic' src={avatar} />}
      <span className='tag-name'>{getTagDisplayValue(tag)}</span>
      {!disabled && (
        <a className={classNameRemove} onClick={() => onRemove(key)} />
      )}
    </span>
  )
}

function renderUsersSuggestion(suggestion) {
  const name = suggestion.fullname || suggestion.email

  return (
    <div className='dropdown__list-item'>
      {suggestion.avatar && <img alt='userpic' src={suggestion.avatar} />}
      <span className='dropdown__list-item-text'>{name}</span>
    </div>
  )
}

function renderProvidersSuggestion({ node }) {
  const {
    name,
    image: { thumbnails },
  } = node

  return (
    <div className='dropdown__list-item'>
      {thumbnails && thumbnails[0] && <img alt='userpic' src={thumbnails[0]} />}
      <span className='dropdown__list-item-text'>{name}</span>
    </div>
  )
}

function getSuggestionValue(suggestion) {
  return suggestion.name || suggestion.fullname
}

function getSuggestionProviderValue(suggestion) {
  return suggestion.node.contactEmail
}

class SelectUser extends Component<Props, State> {
  static defaultProps = {
    pageSize: 4,
    params: {},
  }

  static createTagFromProfile(profile, isProvider) {
    if (isProvider) {
      return { name: profile.contactEmail }
    }

    return {
      name: profile.fullname || profile.name,
      profile,
    }
  }

  tagsRef = React.createRef()

  state = {
    isValidInput: true,
    providersLoading: false,
    providers: [],
  }

  componentDidMount() {
    this.props.dispatch({
      type: actions.USERSUGGEST_INIT,
      value: this.props.initialValue || '',
    })
  }

  componentDidUpdate(prevState) {
    if (
      !this.props.isLoading &&
      prevState.isLoading &&
      this.props.isProviderList
    ) {
      const { suggestions } = this.props

      const variables = {
        page: ALL_PAGES_PARAM,
        first: suggestions.length ? INFINITY_FIRST : 0,
        pageSize: suggestions.length ? INFINITY_FIRST : 0,
        list: JSON.stringify(map(suggestions, 'id')),
      }

      fetchQuery(environment, query, variables).then(data => {
        this.setState({
          providersLoading: false,
          providers: data.allProviders.edges,
        })
      })
    }
  }

  renderSuggestion = suggestion => {
    const { isProviderList } = this.props

    if (isProviderList) {
      return renderProvidersSuggestion(suggestion)
    } else {
      return renderUsersSuggestion(suggestion)
    }
  }

  loadSuggestions = ({ value }) => {
    const {
      onlyManagers,
      addChatMembers,
      isProviderList,
      building,
      category,
      isActive,
    } = this.props
    const params = {
      ...(isActive && { status: 'active' }),
      fullname_or_email: value,
    }

    if (isProviderList) {
      this.props.dispatch({
        type: actions.PROVIDERSUGGEST_LOAD,
        params,
        value,
        key: 'assignee-suggest',
        ...(building ? { buildingId: building.id } : {}),
        ...(category ? { categoryId: category.id } : {}),
      })
    } else {
      this.props.dispatch({
        type: actions.USERSUGGEST_LOAD,
        params,
        value,
        isProviderList,
        addChatMembers,
        onlyManagers: !!onlyManagers,
        key: 'assignee-suggest',
      })
    }
  }

  onSuggestionsClearRequested = () => {
    this.props.dispatch({
      type: actions.CLEAR_SUGGESTIONS,
    })
  }

  renderInputComponent = inputProps => {
    const css = inputProps.wrapperCss
    delete inputProps.wrapperCss

    return (
      <span className={css}>
        <input {...inputProps} />
      </span>
    )
  }

  onChangeSuggestionInput = (e, { newValue, method }) => {
    if (method !== 'enter') {
      this.setState({ isValidInput: true })
    }

    this.props.dispatch({
      type: actions.UPDATE_INPUT_VALUE,
      value: newValue,
    })

    this.props.onChangeInput && this.props.onChangeInput()

    if (!newValue.length) {
      this.props.onValidate && this.props.onValidate(true)
    }
  }

  validateTag(tag) {
    const { profileCheck, emailCheck } = this.props

    if (profileCheck) return !!tag.profile

    if (emailCheck) {
      if (tag.profile) return true
      else return EMAIL_REG_EXP.test(tag.name)
    }

    if (!tag.name.length) return true

    return true
  }

  checkForIgnored(profile) {
    const { managers } = this.props

    if (!managers.length) {
      return false
    }

    const alreadyHaveManager = managers.find(m => m.id === profile.id)

    return !!alreadyHaveManager
  }

  checkForAlreadyHaveTag(tag) {
    const { ignoredUsers } = this.props

    if (!ignoredUsers || !ignoredUsers.length) {
      return false
    }

    const isEqual = tag.profile
      ? manager => manager.id === tag.profile.id
      : manager => manager.name === tag.name

    const alreadyHaveManager = ignoredUsers.find(isEqual)

    return !!alreadyHaveManager
  }

  handleManagerChange = (tags, addedTags) => {
    const tag = addedTags[0]

    const isValidInput = this.validateTag(tag)
    this.props.onValidate && this.props.onValidate(isValidInput)
    this.setState({ isValidInput })

    if (!isValidInput || this.checkForAlreadyHaveTag(tag)) return

    const managersTags = uniqBy(tags, tag =>
      tag.profile ? tag.profile.id : tag.name
    )

    this.props.dispatch({
      type: actions.MANAGER_CHANGE,
      managers: managersTags,
    })

    this.props.onChange(managersTags)
  }

  autocompleteRenderInput = ({ addTag, ...props }) => {
    const handleOnChange = (e, { newValue, method }) => {
      if (method === 'enter') {
        e.preventDefault()
      } else {
        this.onChangeSuggestionInput(e, { newValue, method })
      }
    }

    const { value, suggestions, placeholder, t } = this.props

    const currentUserIndex = suggestions.findIndex(
      ({ owner }) => owner === this.props.currentUserId
    )

    const filteredSuggestions =
      currentUserIndex === -1
        ? suggestions
        : [
            ...suggestions.slice(0, currentUserIndex),
            ...suggestions.slice(currentUserIndex + 1),
          ]

    const inputProps = {
      placeholder,
      value,
    }

    const { isValidInput } = this.state

    if (!isValidInput) {
      inputProps.title = t('EnterCorrectEmail')
      inputProps.wrapperCss = 'input-error'
    }

    return (
      <Autosuggest
        ref={props.ref}
        suggestions={filteredSuggestions}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={{
          ...props,
          ...inputProps,
          onChange: handleOnChange,
          autoFocus: true,
        }}
        renderInputComponent={this.renderInputComponent}
        onSuggestionSelected={(e, { suggestion }) => {
          if (this.checkForIgnored(suggestion)) {
            return false
          }

          addTag(SelectUser.createTagFromProfile(suggestion))
        }}
        onSuggestionsFetchRequested={this.loadSuggestions}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
      />
    )
  }

  renderProviders = ({ addTag, ...props }) => {
    const { providersLoading } = this.state

    if (providersLoading) {
      return <Loader />
    }

    const { value, t, placeholder } = this.props

    const handleOnChange = (e, { newValue, method }) => {
      if (method === 'enter') {
        e.preventDefault()
      } else {
        this.onChangeSuggestionInput(e, { newValue, method })
      }
    }

    const { isValidInput } = this.state

    const inputProps = {
      ...props,
      value,
      placeholder,
      onChange: handleOnChange,
    }

    if (!isValidInput) {
      inputProps.title = t('EnterCorrectEmail')
      inputProps.wrapperCss = 'input-error'
    }

    return (
      <Autosuggest
        ref={props.ref}
        suggestions={this.state.providers}
        getSuggestionValue={getSuggestionProviderValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={{ ...inputProps }}
        renderInputComponent={this.renderInputComponent}
        onSuggestionSelected={(e, { suggestion }) => {
          if (this.checkForIgnored(suggestion)) {
            return false
          }

          addTag(SelectUser.createTagFromProfile(suggestion.node, true))
        }}
        onSuggestionsFetchRequested={this.loadSuggestions}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
      />
    )
  }

  render() {
    const { value, managers, isProviderList } = this.props

    const css = managers.length
      ? 'react-tagsinput not_empty'
      : 'react-tagsinput empty'

    return (
      <div style={{ marginBottom: '30px' }}>
        <TagsInput
          addOnBlur
          addKeys={[9, 13, 32]}
          className={css}
          renderTag={renderTag}
          inputValue={value}
          ref={this.tagsRef}
          renderInput={
            isProviderList ? this.renderProviders : this.autocompleteRenderInput
          }
          value={managers}
          tagDisplayProp='name'
          onChange={this.handleManagerChange}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.usersuggest,
})

export default compose(
  withTranslation('AssigneeForm'),
  connect(mapStateToProps)
)(SelectUser)
