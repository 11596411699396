// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'

import UserEmail from './UserEmail'
import UserPassword from './UserPassword'
import UserLanguage from './UserLanguage'
import UserNotification from './UserNotification'
import UserVisibility from './UserVisibility'
import ProfileRemove from './ProfileRemove'
import * as actions from './SettingsPersonal.actionTypes'
import Loader from '../Loader'
import { getUser } from '../../utils/commonSelectors'
import Content from '../Content'
import { getUserId, isDwellerUser } from '../../utils/utils'

type Props = {
  dispatch: Object => void,
  initiated: boolean,
  language: Object,
  notificationFrequencyType: number,
  profileConfig: Object,
  user: Object,
  workingSave: Array<Object>,
}

class SettingsPersonal extends Component<Props> {
  componentDidMount() {
    const { user } = this.props
    this.props.dispatch({ type: actions.SETTINGS_PERSONAL_INIT, user })
  }

  onUpdateLanguage = language => {
    this.props.dispatch({
      type: actions.SETTINGS_PERSONAL_UPDATE,
      key: 'language',
      language,
    })
  }

  // TODO merge onUpdateNotificationFrequencyType into onUpdateProfileConfig
  // TODO  split `working` flag into 3 separate flags for each block

  onUpdateNotificationFrequencyType = notificationFrequencyType => {
    this.props.dispatch({
      type: actions.SETTINGS_PERSONAL_UPDATE,
      key: 'notificationFrequencyType',
      notificationFrequencyType,
    })
  }

  onUpdateProfileConfig = (name, value) => {
    const { profileConfig } = this.props
    profileConfig[name] = value
    this.props.dispatch({
      type: actions.SETTINGS_PERSONAL_UPDATE,
      key: 'profileConfig',
      profileConfig,
    })
  }

  onClickCancelLanguage = cancel => () => {
    this.props.dispatch({ type: actions.SETTINGS_PERSONAL_CANCEL, cancel })
  }

  onClickSaveLanguage = language => {
    const { user } = this.props

    this.props.dispatch({
      type: actions.SETTINGS_PERSONAL_SAVE,
      id: getUserId(user),
      workingSave: 'saveLanguage',
      language,
    })
  }

  onClickCancelNotificationSettings = cancel => () => {
    const {
      profileConfig: { email_notification_period: emailNotificationPeriod },
    } = this.props

    this.props.dispatch({
      type: actions.SETTINGS_PERSONAL_CANCEL,
      emailNotificationPeriod,
      cancel,
    })
  }

  onClickCancelVisibilitySettings = cancel => () => {
    this.props.dispatch({
      type: actions.SETTINGS_PERSONAL_CANCEL,
      cancel,
    })
  }

  onClickSaveNotificationSettings = () => {
    const { user, notificationFrequencyType } = this.props

    this.props.dispatch({
      type: actions.SETTINGS_PERSONAL_SAVE,
      id: getUserId(user),
      workingSave: 'saveNotificationFrequencyType',
      notificationFrequencyType,
    })
  }

  onClickSaveVisibilitySettings = changedValues => {
    const { user } = this.props

    this.props.dispatch({
      type: actions.SETTINGS_PERSONAL_SAVE,
      id: getUserId(user),
      workingSave: 'saveVisibilitySettings',
      changedValues,
    })
  }

  render() {
    const {
      language,
      initiated,
      user,
      notificationFrequencyType,
      profileConfig,
      workingSave,
    } = this.props

    const { email_notification_period: emailNotificationPeriod } = profileConfig
    const isDweller = isDwellerUser(user)

    if (!initiated) {
      return (
        <Content>
          <Loader text={false} type='big' />
        </Content>
      )
    }

    return (
      <Content>
        <UserEmail />
        <UserPassword user={getUserId(user)} />
        <UserLanguage
          language={language}
          working={workingSave.includes('saveLanguage')}
          onClickSaveLanguage={this.onClickSaveLanguage}
        />
        <UserNotification
          language={language}
          working={workingSave.includes('saveNotificationFrequencyType')}
          emailNotificationPeriod={emailNotificationPeriod}
          notificationOption={notificationFrequencyType}
          onClickSaveNotificationSettings={this.onClickSaveNotificationSettings}
          onClickCancelNotificationSettings={this.onClickCancelNotificationSettings(
            'cancelNotificationFrequencyType'
          )}
          onUpdate={this.onUpdateNotificationFrequencyType}
        />
        <UserVisibility
          user={user}
          working={workingSave.includes('saveVisibilitySettings')}
          profileConfig={profileConfig}
          onSave={this.onClickSaveVisibilitySettings}
        />
        {isDweller && <ProfileRemove />}
      </Content>
    )
  }
}

const mapStateToProps = state => ({
  ...state.settingsPersonal,
  user: getUser(state),
})

export default compose(
  withTranslation('User'),
  connect(mapStateToProps)
)(SettingsPersonal)
