// @flow

import React from 'react'
import type { Node } from 'react'

import PostHeader from '../../Post/PostHeader/PostHeader'

type Props = {
  collapsed: boolean,
  data?: ?Object,
  dateFormat: string,
}

const RequestBodyHeader = (props: Props): Node => {
  const { data, dateFormat } = props

  if (!data) {
    return null
  }

  const { created, created_by_obj: owner } = data

  return <PostHeader owner={owner} date={created} dateFormat={dateFormat} />
}

export default RequestBodyHeader
