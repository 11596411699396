// @flow

import React from 'react'
import type { Node } from 'react'
import { withTranslation } from 'react-i18next'
import { graphql, QueryRenderer } from 'react-relay'

import environment from '../../../../createRelayEnvironment'
import UserPopup from '../../../modals/UserPopup/UserPopup'
import Managers from '../../RequestManagers'
import { Row } from '../../../Block'
import { map } from 'lodash-es'
import { useSelector } from 'react-redux'

type Props = {
  canEditAssignee: boolean,
  onChange: Object => void,
  onEdit: () => void,
  t: string => string,
  value: Array<Object>,
}
const INFINITY_FIRST = 100500

const query = graphql`
  query AssigneesQuery(
    $page: Float!
    $first: Int!
    $pageSize: Float!
    $email: String
  ) {
    allProviders(
      first: $first
      pageSize: $pageSize
      page: $page
      email: $email
    ) {
      edges {
        node {
          id
          name
          contactEmail
          image {
            thumbnails {
              url
              size
            }
          }
        }
      }
      totalCount
    }
  }
`

const Assignees = ({
  canEditAssignee,
  building,
  category,
  value,
  isSystem,
  softArchived,
  ...props
}: Props): Node => {
  const hideModal = () => props.onEdit(null)

  const handleSave = ({ users, comment }) =>
    props.onChange({ assignees: users, comment })
  const openAssigneeModal = () => {
    const modal = (
      <UserPopup
        withComment
        emailCheck
        isActive
        onlyManagers
        building={building}
        category={category}
        title={props.t('addMembers')}
        modalLabel={props.t('InviteManagersByNameOrEmail')}
        inputPlaceholder={props.t('UserPopup:ChooseAssigneePlaceholder')}
        data={value}
        isSystem={isSystem}
        onClose={hideModal}
        onSave={handleSave}
      />
    )

    props.onEdit(modal)
  }

  const emails = map(value, 'fullname')
  const marketplaceIsActive = useSelector(
    state => state.marketplaceSettings.is_active
  )

  const rowProps = Object.assign(
    {},
    canEditAssignee && !softArchived && props.onEdit
      ? { onEdit: openAssigneeModal }
      : undefined
  )

  return (
    <Row title={props.t('AssigneesTitle')} {...rowProps}>
      {marketplaceIsActive ? (
        <QueryRenderer
          environment={environment}
          query={query}
          variables={{
            page: 0,
            pageSize: INFINITY_FIRST,
            first: INFINITY_FIRST,
            email: JSON.stringify(emails),
          }}
          render={({ props }) => {
            if (!props) {
              return
            }

            const items = map(props.allProviders.edges, 'node').reduce(
              (acc, item) => {
                acc[item.contactEmail] = [
                  item.name,
                  item.image.thumbnails[0].url,
                ]

                return acc
              },
              {}
            )

            const data = value.map(v => ({
              ...v,
              ...(items[v.fullname]
                ? {
                    avatar: items[v.fullname][1],
                    fullname: items[v.fullname][0],
                  }
                : {}),
            }))

            return <Managers items={data} />
          }}
        />
      ) : (
        <Managers items={value} />
      )}
    </Row>
  )
}

export default withTranslation('Request')(Assignees)
