// @flow

import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { push, replace } from 'connected-react-router'
import { get, map, find, isEmpty } from 'lodash-es'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import Progress from 'antd/es/progress'
import type { Location, Match } from 'react-router-dom'

import ErrorPopup from '../modals/ErrorPopup'
import Button from '../Button'
import Checkbox from '../Checkbox'
import Icon from '../Icon'
import {
  getList,
  removeFile,
  removeDirectory,
  getPermissions,
} from '../../core/api/api.file'
import FolderCreatingPopup from './FolderCreatingPopup'
import RenamingPopup from './RenamingPopup'
import ReplacingPopup from './ReplacingPopup'
import ChangeTypePopup from './ChangeTypePopup'
import { getUrlByFolderId, getSlash, getRelatedPathList } from './Files.utils'
import NewFilesUpload from '../NewFilesUpload'
import { getCurrentPage, getCurrentFilters } from '../../utils/routing'
import BottomPagination from '../Pagination/BottomPagination'
import TopPagination from '../Pagination/TopPagination'
import { FOLDER_TYPE } from './Files.constants'
import ConfirmationPopup from '../modals/ConfirmationPopup'
import Modal from '../Modal'
import NewSelectSimple from '../NewSelectSimple'
import Loader from '../Loader'
import { getThemeColor, getUser } from '../../utils/commonSelectors'
import FileList from './FileList'
import { useSelected } from '../../hooks'
import { getErrorStatus } from '../../utils/utils'
import EmailsCreatingModal from '../Emails/EmailsCreatingModal'

import { redirectTo404 } from '../../utils/routing'

import 'antd/es/progress/style/index.css'

import styles from './Files.module.scss'
import PdfPreview from '../PdfPreview'

import {
  DWELLER_AUDIENCE,
  MANAGER_AUDIENCE,
  PROVIDER_AUDIENCE,
} from '../../constants'

import { MAIL_SEARCH_SELECT_USERS } from '../MailSearch/MailSearch.actionTypes'

type Props = {
  directoryId?: number,
  isArchived?: boolean,
  isDweller?: boolean,
  location: Location,
  match: Match,
  permissions?: Object,
  requestId?: number,
  subject?: Object,
  working?: boolean,
}

const Files = (props: Props) => {
  const {
    location,
    directoryId,
    isDweller,
    isArchived,
    working,
    requestId,
    subject,
    scope,
    object,
  } = props

  const folderId = get(props, ['match', 'params', 'folderId'])
  const { t } = useTranslation('Files')
  const { uk_directory_id } = useSelector(state => state.init)
  const { selectedCustomers, selectedProviders } = useSelector(
    state => state.mailSearch
  )
  const {
    directory: { id: profileDirectoryId },
  } = useSelector(getUser)
  const color = useSelector(state => getThemeColor(state))
  const dispatch = useDispatch()
  const [selectedItems, changeSelected, setSelected, isAllSelected] =
    useSelected()
  const [files, setFiles] = useState([])
  const [checkedFiles, setCheckedFiles] = useState([])
  const [permissions, setPermissions] = useState({})
  const [canAddInInvoice, setCanAddInInvoice] = useState(false)
  const [emailModal, setEmailModal] = useState(false)
  const [audience, setAudience] = useState(null)
  const [filesAddedInInvoce, setFilesAddedInInvoce] = useState([])
  const [isOpen, setOpen] = useState(false)
  const [modal, setModal] = useState(null)
  const [loading, setLoading] = useState(false)
  const [reload, setReload] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [meta, setMeta] = useState(null)
  const [breadcrumbs, setBreadcrumbs] = useState([])
  const [processedFiles, setProcessedFiles] = useState({
    checked: 0,
    count: 0,
  })

  const [removing, setRemoving] = useState(false)
  const [error, setError] = useState(null)
  const [hint, setHint] = useState(false)
  const [pdfView, setPdf] = useState(null)

  useEffect(() => {
    if (props.permissions) {
      setPermissions(props.permissions)
    }
  }, [props.permissions])

  useEffect(() => {
    if (working && !loading) {
      setLoading(true)
    }
  }, [working])

  useEffect(() => {
    if (!loading) {
      if (reload) {
        setReload(false)
      }

      return
    }

    if (folderId) {
      const page = getCurrentPage(location)
      const filters = getCurrentFilters(location)
      const params = {
        ...filters,
        scope,
        parent: folderId,
        page,
        object_id: object,
        ...(subject?.type === 'flat_id' ? { object_id: subject.id } : {}),
        ...(subject?.type === 'request' ? { scope: 'request' } : {}),
        ...(subject?.type === 'profile' ? { object_id: subject.owner } : {}),
        ...(subject?.type === 'building'
          ? { scope: 'building', object_id: subject.id }
          : {}),
      }

      if (isEmpty(filters)) {
        return
      }

      if (!isDweller) {
        getPermissions(folderId).then(res => {
          setFilesAddedInInvoce(res.files_converted_to_invoices)
        })
      }

      getList(params)
        .then(data => {
          const { parent } = data

          setFiles(data.objects.filter(f => f.owner))
          setCheckedFiles(data.objects.filter(f => !f.owner))

          if (requestId && !isDweller) {
            setCanAddInInvoice(data.parent.can_add_invoice)
          }

          if (!props.permissions) {
            setPermissions(parent.permissions)
          }

          setBreadcrumbs(getRelatedPathList(parent.path, directoryId))

          setMeta({
            count: data.count,
            limit: 20,
            offset: 0,
            page_count: data.pages,
            curr_page: parseInt(page),
          })
          setSelected([])
        })
        .catch(error => redirectTo404(error))
        .finally(() => setLoading(false))
    } else if (
      directoryId ||
      uk_directory_id ||
      (isDweller && profileDirectoryId)
    ) {
      const slash = getSlash(props)

      dispatch(
        replace(
          `${props.match.url}${slash}${
            directoryId || uk_directory_id || profileDirectoryId
          }/`
        )
      )

      setLoading(false)
    } else {
      return
    }
  }, [loading, location])

  useEffect(() => {
    if (error) {
      const errorStatus = getErrorStatus(error)

      let errorText = get(
        error,
        ['message', 'response', 'data', 'errors'],
        'UnknownError'
      )

      if (errorStatus === 400) {
        errorText = get(errorText, 'parent', 'UnknownError')
      }

      setModal(
        <ErrorPopup
          isServerError
          text={t(errorText)}
          title={t('FileReplaceError')}
          confirm={t('Continue')}
          onOk={hideModal}
          onClose={hideModal}
        />
      )
    }
  }, [error])

  useEffect(() => {
    if (!loading) {
      setLoading(true)
    }
  }, [location])

  useEffect(() => {
    if (folderId) {
      setLoading(true)
    }
  }, [folderId])

  const canSendEmail = useMemo(() => {
    if (isDweller) {
      return false
    }

    return !!selectedItems.find(i => {
      const fileObj = files.find(f => i === f.id)

      return fileObj.type !== FOLDER_TYPE
    })
  }, [selectedItems])

  const isFolderSelected = useMemo(() => {
    return !!selectedItems.find(i => {
      const fileObj = files.find(f => i === f.id)

      return fileObj.type === FOLDER_TYPE
    })
  }, [selectedItems])

  const handleAddFolder = () => {
    setOpen(true)
    setReload(true)
  }

  const handleLoadFile = () => {
    setProcessedFiles({ count: 0, checked: 0 })
    setLoading(true)
    setUploading(false)
    setReload(true)
  }

  const handleStartLoadFile = () => setUploading(true)

  const closePopup = () => {
    setOpen(false)
  }

  const handleClick = (id, fileType) => () => {
    if (fileType !== FOLDER_TYPE) {
      return
    }

    dispatch(push(getUrlByFolderId(id, props)))
  }

  const openRemoveModal = (id, type) => () => {
    openModal(id, type)
  }

  const openRenameModal = (id, type, name) => () => {
    setModal(
      <RenamingPopup
        id={id}
        name={name}
        setError={setError}
        type={type}
        closePopup={hideModal}
        title='RenameTitle'
        parent={folderId}
        isOpen={isOpen}
        buttonTitle='RenameButton'
        setLoading={setLoading}
      />
    )
  }

  const openChangeTypeModal = (id, usersAccess, name, type) => () => {
    setModal(
      <ChangeTypePopup
        id={id}
        setError={setError}
        usersAccess={usersAccess}
        type={type}
        name={name}
        closePopup={hideModal}
        title='ChangeTypeTitle'
        parent={folderId}
        isOpen={isOpen}
        buttonTitle='ChangeTypeButton'
        setLoading={setLoading}
      />
    )
  }

  const openInvoiceModal = id => () => {
    setModal(
      <ConfirmationPopup
        confirm={t('Yes')}
        title={t('InvoicesAddTitle')}
        text={t('InvoicesAddText')}
        onClose={hideModal}
        onOk={() => {
          props.onAddToInvoice(id)
          hideModal()
        }}
      />
    )
  }

  const openReplaceModal = (id, usersAccess, name, type) => multi => {
    let selected = null

    if (multi) {
      selected = selectedItems.map(s => find(files, { id: s }))
      selected = selected.filter(item => item.permissions.can_write)
    }

    setModal(
      <ReplacingPopup
        isMulti={multi}
        id={id}
        selected={selected}
        folderId={folderId}
        files={files}
        usersAccess={usersAccess}
        type={type}
        name={name}
        closePopup={hideModal}
        title='ReplaceTitle'
        parent={folderId}
        isOpen={isOpen}
        buttonTitle='ReplaceButton'
        setLoading={setLoading}
        setError={setError}
      />
    )
  }

  const openMassRemoveModal = () => {
    openMassModal()
  }

  const openMassReplaceModal = () => {
    openReplaceModal()(true)
  }

  const removeFiles = async () => {
    return Promise.all(
      selectedItems.map(file => {
        const fileObj = files.find(f => file === f.id)

        if (!fileObj.permissions.can_write) {
          return null
        }

        let api = removeFile

        if (fileObj.type === FOLDER_TYPE) {
          api = removeDirectory
        }

        return api(file).then(data => data)
      })
    )
  }

  const handleMassRemove = async () => {
    hideModal()

    setRemoving(true)

    await removeFiles()

    setRemoving(false)
    setLoading(true)
    setReload(true)
  }

  const handleRemove = (id, type) => () => {
    hideModal()

    let api = removeFile

    if (type === FOLDER_TYPE) {
      api = removeDirectory
    }

    api(id).then(() => {
      setReload(true)
      setLoading(true)
    })
  }

  const hideModal = () => {
    setModal(null)
  }

  const openModal = (id, type) => {
    setModal(
      <ConfirmationPopup
        confirm={t('FilesRemoveButton')}
        title={t('FileRemoveTitle')}
        text={t('ConfirmFileRemoving')}
        onClose={hideModal}
        onOk={handleRemove(id, type)}
      />
    )
  }

  const openMassModal = () => {
    setModal(
      <ConfirmationPopup
        confirm={t('FilesRemoveButton')}
        title={t('FilesRemoveTitle')}
        text={t('ConfirmFilesRemoving')}
        onClose={hideModal}
        onOk={handleMassRemove}
      />
    )
  }

  const canWrite =
    permissions &&
    (permissions.can_write ||
      permissions.can_attach_file ||
      permissions.can_edit_add_files ||
      permissions.can_edit_files)

  const showToolbox =
    (canWrite || (meta && meta.page_count > 1)) &&
    !uploading &&
    !removing &&
    !loading &&
    !isArchived

  const sectionClass = classnames({
    'unit unit--default bar unit--nomargin': directoryId,
  })

  const contnetClass = classnames({
    content__col: directoryId,
  })

  const handleProgress = length => {
    setProcessedFiles(prev => ({
      checked: prev.checked + 1,
      count: length,
    }))
  }

  const percent = (100 / processedFiles.count) * processedFiles.checked

  const toggleAll = () => {
    setSelected(map(files, 'id'))
  }

  const handleMouseEnter = () => setHint(true)
  const handleMouseLeave = () => setHint(false)

  const sendOptions = [
    { label: t('Request:ToProviders'), value: PROVIDER_AUDIENCE },
    { label: t('Request:ToDwellers'), value: DWELLER_AUDIENCE },
    { label: t('Request:ToManagers'), value: MANAGER_AUDIENCE },
  ]

  const selectAudienceEmails = value => {
    if (value === PROVIDER_AUDIENCE || value === DWELLER_AUDIENCE) {
      dispatch({
        type: MAIL_SEARCH_SELECT_USERS,
        selectedUsers:
          value === PROVIDER_AUDIENCE ? selectedProviders : selectedCustomers,
      })
    }
  }

  const handleChangeAudience = option => {
    selectAudienceEmails(option.value)
    setAudience(option.value)
  }

  useEffect(() => {
    if (audience) {
      setEmailModal(true)
    }
  }, [audience])

  const hideEmailModal = () => {
    setEmailModal(false)
    setAudience(null)
  }

  return (
    <div className={contnetClass}>
      <section className={sectionClass}>
        {directoryId && <h2 className='unit__title'>{t('FileListTitle')}</h2>}
        {breadcrumbs.length > 1 && (
          <div className={styles.breadCrumbs}>
            {breadcrumbs.map((breadcrumb, index) => {
              const name = breadcrumb[2] ? t(breadcrumb[1]) : breadcrumb[1]

              if (breadcrumbs.length - 1 === index) {
                return <span key={breadcrumb[0]}>{name}</span>
              }

              return (
                <Link
                  key={breadcrumb[0]}
                  to={`${getUrlByFolderId(breadcrumb[0], props)}`}
                >
                  {name}
                </Link>
              )
            })}
          </div>
        )}
        {(loading || removing) && (
          <div className='toolbox'>
            <div className='toolbox__cell'>
              <Loader style={{ padding: '7px 0' }} />
            </div>
          </div>
        )}
        {uploading && (
          <div
            className='toolbox'
            style={{ display: 'block', padding: '6px 15px' }}
          >
            <div className='toolbox__cell'>
              <Loader showImg={false} />
            </div>
            <Progress
              strokeColor={color}
              percent={percent}
              status='active'
              showInfo={false}
              style={{ padding: '0', paddingTop: '1px' }}
            />
          </div>
        )}
        {showToolbox && (
          <>
            <div className='toolbox'>
              {canWrite && (
                <>
                  <div className='toolbox__cell'>
                    <Checkbox
                      outer
                      disabled={!files.length}
                      checked={isAllSelected(files)}
                      onChange={toggleAll}
                    />
                  </div>
                  {selectedItems.length === 0 && (
                    <div className='toolbox__cell'>
                      <button
                        className='button button--success button--large'
                        type='button'
                        style={{ marginRight: 0 }}
                        disabled={loading || uploading || removing || !canWrite}
                        onClick={handleAddFolder}
                      >
                        <Icon id='folder' className='button__icon' />
                        {t('AddFolder')}
                      </button>
                    </div>
                  )}
                </>
              )}
              {canWrite && selectedItems.length === 0 && (
                <div className='toolbox__cell'>
                  <NewFilesUpload
                    multiple
                    parent={folderId}
                    onFinishUpload={handleLoadFile}
                    onStartUpload={handleStartLoadFile}
                    onProgress={handleProgress}
                  >
                    <Button.Save
                      type='button'
                      icon='request'
                      disabled={loading || uploading || removing || !canWrite}
                    >
                      {t('LoadFile')}
                    </Button.Save>
                  </NewFilesUpload>
                </div>
              )}
              {canWrite && !!selectedItems.length && (
                <>
                  <div className='toolbox__cell'>
                    <Button.Restore
                      disabled={!selectedItems.length}
                      style={{ marginRight: '0' }}
                      onClick={openMassReplaceModal}
                    >
                      {t('ReplaceFiles')}
                    </Button.Restore>
                  </div>
                  <div className='toolbox__cell'>
                    <Button.Remove
                      disabled={!selectedItems.length}
                      onClick={openMassRemoveModal}
                    >
                      {t('RemoveFiles')}
                    </Button.Remove>
                  </div>
                </>
              )}
              {canSendEmail && (
                <div className='toolbox__cell'>
                  <div
                    className={styles.hint}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    {requestId ? (
                      <NewSelectSimple
                        buttonView='contained'
                        buttonClass={styles.sendButton}
                        disabled={loading || isFolderSelected}
                        options={sendOptions}
                        placeholder={t('Common:SendByEmail')}
                        name='create-thread'
                        clearable={false}
                        value={null}
                        onChange={handleChangeAudience}
                      />
                    ) : (
                      <Button.SendFile
                        disabled={loading || isFolderSelected}
                        subject={subject}
                        selectedFiles={files.filter(f =>
                          selectedItems.includes(f.id)
                        )}
                      />
                    )}

                    {hint && isFolderSelected && (
                      <div className={styles.tooltip}>
                        <div />
                        <span>{t('CanNotSendFolder')}</span>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className='toolbox__cell toolbox__cell--right'>
                <TopPagination
                  classes='pager pager--large pager--default-2'
                  meta={meta}
                />
              </div>
            </div>
          </>
        )}

        <FileList
          canAddInInvoice={canAddInInvoice}
          filesAddedInInvoce={filesAddedInInvoce}
          can_write={canWrite}
          files={files}
          folderId={folderId}
          loading={loading}
          uploading={uploading}
          checkedFiles={checkedFiles}
          removing={removing}
          selected={selectedItems}
          openRemoveModal={openRemoveModal}
          openRenameModal={openRenameModal}
          openChangeTypeModal={openChangeTypeModal}
          openReplaceModal={openReplaceModal}
          openInvoiceModal={openInvoiceModal}
          handleChange={changeSelected}
          handleClick={handleClick}
          setLoading={setLoading}
          setUploading={setUploading}
          setError={setError}
          scope={scope}
          subject={subject}
          object={object}
          setOpenPdf={id => setPdf(id)}
          location={location}
        />
        <BottomPagination meta={meta} classes='paginator-2' />
        {isOpen && (
          <FolderCreatingPopup
            title='AddFolder'
            parent={folderId}
            isOpen={isOpen}
            buttonTitle='Add'
            closePopup={closePopup}
            setLoading={setLoading}
          />
        )}
        <Modal isOpen={!!modal} onRequestClose={hideModal}>
          {modal}
        </Modal>
        {emailModal && (
          <EmailsCreatingModal
            directoryId={folderId}
            isOpen={!!emailModal}
            requestId={requestId}
            buildingId={null}
            reloadThreads={props.setReloading}
            audience={audience}
            flatId={null}
            selectedFiles={files.filter(f => selectedItems.includes(f.id))}
            onClose={hideEmailModal}
          />
        )}
        {pdfView && (
          <PdfPreview
            fileId={pdfView}
            setPdf={() => setPdf(false)}
            scope={'tree'}
          />
        )}
      </section>
    </div>
  )
}

export default Files
