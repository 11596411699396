// @flow

import React, { useState, useEffect, useContext } from 'react'
import type { Node } from 'react'

import Checkbox from '../../../../../../../../../../components/Checkbox'
import Button from '../../../../../../../../../../components/Button'
import { updateActivity } from '../../../../../../../../../../core/api/api.pipeline'
import RequestActivityContext from '../../../../../../../RequestActivity.context'

import styles from './CommitType.module.scss'

type Props = {
  active_automate_count: number,
  commit_type: 'done' | 'yesno',
  id: number,
  isDone: boolean,
  isYesNo: ?'yes' | 'no',
}

const CommitType = (props: Props): Node => {
  const { commit_type, isDone, isYesNo, id, active_automation_count } = props

  const [isMounted, setMounted] = useState(false)

  const { setReloading, isDweller, setPhaseId, setAutomationLoading } =
    useContext(RequestActivityContext)

  const handleChange = e => props.setDone(e.currentTarget.checked)
  const handleClick = yesno => () =>
    props.setYesNo(isYesNo === yesno ? null : yesno)

  useEffect(() => {
    if (!isMounted) {
      setMounted(true)
    }
  }, [])

  useEffect(() => {
    if (isMounted) {
      updateActivity(id, { done: isDone }).then(data => {
        setPhaseId(data.phase?.id)

        if (active_automation_count > 0) {
          setAutomationLoading(true)
        }

        setReloading(true)
      })
    }
  }, [isDone])

  useEffect(() => {
    if (isMounted) {
      updateActivity(id, { yesno: isYesNo }).then(data => {
        setPhaseId(data.phase?.id)

        if (active_automation_count > 0) {
          setAutomationLoading(true)
        }

        setReloading(true)
      })
    }
  }, [isYesNo])

  const checkboxProps = Object.assign(
    {},
    !isDweller ? { onChange: handleChange } : undefined
  )

  const yesButtonProps = Object.assign(
    {},
    !isDweller ? { onClick: handleClick('yes') } : undefined
  )

  const noButtonProps = Object.assign(
    {},
    !isDweller ? { onClick: handleClick('no') } : undefined
  )

  return (
    <div className={styles['commit-type']}>
      <>
        {commit_type === 'done' ? (
          <Checkbox
            size='large'
            className={styles.checkbox}
            checked={isDone}
            {...checkboxProps}
          />
        ) : (
          <div className={styles.buttons}>
            <Button.Square active={isYesNo === 'yes'} {...yesButtonProps}>
              yes
            </Button.Square>
            <Button.Square active={isYesNo === 'no'} {...noButtonProps}>
              no
            </Button.Square>
          </div>
        )}
      </>
    </div>
  )
}

export default CommitType
