// @flow

import React, { useState } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import type { Node } from 'react'

import SelectCustom from '../../components/Select/SelectCustom'
import { getFormattedDate, getUserEmailName } from '../../utils/utils'
import Checkbox from '../../components/Checkbox'
import Loader from '../../components/Loader'
import { toggle } from './ThreadsList.actions'
import {
  readThread,
  unreadThread,
  updateStarred,
  readMessage,
  unreadMessage,
  updateMessageStarred,
} from '../../core/api/api.mail'
import NewTableBodyCell from '../../components/NewTable/NewTableBodyCell'
import NewTableBodyRow from '../../components/NewTable/NewTableBodyRow'
import ThreadsListItemSubject from './ThreadsListItemSubject'
import CellContainer from './CellContainer'
import ThreadsListItemNotes from './ThreadsListItemNotes'
import ThreadsListItemFiles from './ThreadsListItemFiles'
import ThreadsListItemRequests from './ThreadsListItemRequests'
import ThreadsListItemErrors from './ThreadsListItemErrors'
import Icon from '../../components/Icon'

type Props = {
  canUpdateStarred?: boolean,
  init: () => void,
  isSearch?: boolean,
  onChangeNotes: Object => void,
  selected: Array<string>,
  softArchived: boolean,
  thread: Object,
}

const ThreadsListItem = (props: Props): Node => {
  const {
    selected,
    softArchived,
    params,
    isSearch,
    canUpdateStarred,
    thread: {
      has_error_message,
      has_pending_message,
      last_message,
      title,
      total_msg_count,
      unviewed_msg_count,
      uuid,
      notes,
      requests,
      starred: marked,
      isSingle,
    },
    folder,
  } = props

  const { t } = useTranslation('Mail')

  const dispatch = useDispatch()

  const [starred, setStarred] = useState(marked)

  const checked = selected.includes(uuid)
  const inboxUsername = last_message
    ? getUserEmailName(last_message.recipient)
    : ''
  const avatarUrl = last_message && last_message.avatar
  const created = last_message && getFormattedDate(last_message.created)

  const pathName = isSingle ? 'message' : 'mail'

  const threadUrl = `/${pathName}/${uuid}?soft_archived=${
    softArchived.toString() + params
  }`

  const handleToggle = e => {
    dispatch(toggle(e.target.value))
  }

  const toggleStarred = e => {
    e.stopPropagation()

    if (!canUpdateStarred) {
      return
    }

    const api = isSingle ? updateMessageStarred : updateStarred

    api(uuid, { starred: !starred })

    setStarred(!starred)
  }

  const handleClick = () => {
    dispatch(push(threadUrl))
  }

  const handleSetStatus = () => {
    let api = isSingle ? unreadMessage : unreadThread

    if (unviewed_msg_count) {
      api = isSingle ? readMessage : readThread
    }

    api(uuid).finally(() => props.init())
  }

  const handleOpenThread = () => window.open(threadUrl)

  const getOptions = () => {
    let options = []

    if (!isSingle) {
      options.push({
        value: 'changeNotes',
        label: t('ChangeNotes'),
        icon: 'pencil',
        handler: () => props.onChangeNotes({ notes, threadUuid: uuid }),
      })
    }

    if (folder === 'inbox') {
      if (unviewed_msg_count === 0) {
        options.push({
          value: 'setThreadUnRead',
          label: t('SetThreadUnRead'),
          icon: 'pencil',
          handler: handleSetStatus,
        })
      } else {
        options.push({
          value: 'setThreadRead',
          label: t('SetThreadRead'),
          icon: 'pencil',
          handler: handleSetStatus,
        })
      }
    }

    options.push({
      value: 'openInNewTab',
      label: t('OpenInNewTab'),
      icon: 'email',
      handler: handleOpenThread,
    })

    return options
  }

  const bold = unviewed_msg_count > 0

  const dateClass = classnames('table-item table-item__text', { bold })

  return (
    <NewTableBodyRow checked={checked} onClick={handleClick}>
      <NewTableBodyCell
        bold={bold}
        title={inboxUsername}
        style={{ verticalAlign: 'top', fontWeight: 500 }}
      >
        {!isSearch && (
          <Checkbox
            style={{ padding: '12px 8px' }}
            checked={checked}
            value={uuid}
            onChange={handleToggle}
          />
        )}
        <i className='table-item__star' onClick={toggleStarred}>
          <Icon id={starred ? 'starFilled' : 'newStar'} />
        </i>

        <i className='table-item__user-pic'>
          <img src={avatarUrl} alt='message author' />
        </i>
      </NewTableBodyCell>
      <NewTableBodyCell style={{ verticalAlign: 'top' }}>
        <CellContainer style={{ paddingTop: 0, overflow: 'visible' }}>
          <ThreadsListItemSubject
            title={title}
            lastMessage={last_message}
            bold={bold}
            totalMsgCount={total_msg_count}
          />
          <ThreadsListItemFiles lastMessage={last_message} />
          <ThreadsListItemRequests requests={requests} viewedCount={4} />
          <ThreadsListItemNotes notes={notes} />
          <ThreadsListItemErrors hasError={has_error_message} />
        </CellContainer>
      </NewTableBodyCell>
      <NewTableBodyCell style={{ verticalAlign: 'top', align: 'center' }}>
        <div className={dateClass} title={created}>
          {has_pending_message ? (
            <span style={{ color: '#888' }}>
              <Loader type='small' style={{ width: '16px' }} text={false} />
              {t('Sending')}
            </span>
          ) : (
            <span>{created}</span>
          )}
        </div>
        <div className='table-extra table-extra--settings'>
          <SelectCustom
            options={getOptions()}
            onChange={opt => opt.handler()}
          />
        </div>
      </NewTableBodyCell>
    </NewTableBodyRow>
  )
}

export default ThreadsListItem
