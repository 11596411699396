// @flow

import React from 'react'
import Dropzone from 'react-dropzone'
import type { Node } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import {
  chunk,
  isNil,
  omitBy,
  flatten,
  get,
  toString,
  isNumber,
} from 'lodash-es'
import { useDispatch } from 'react-redux'

import { FILE_ORIGIN_TYPES } from '../../containers/FilesUpload'
import { getFile, createFile } from '../../core/api/api.file'
import {
  formatFilesToBase64,
  isImageFile,
  getImageDimensions,
} from '../../utils/file'
import { globalModalError } from '../Layout/Layout.actions'

import styles from './NewFilesUpload.module.scss'

const MULTIPLIER = 1024 * 1024
const RETRY_TIMEOUT = 1000
const FILES_LOADING_QUANTITY = 3
const CHECKING_COUNT = 5
const DEFAULT_MAX_FILE_SIZE = 100
const DEFAULT_MAX_IMAGE_SIZE = 100
const DEFAULT_IMAGE_MIN_HEIGHT = 0
const DEFAULT_IMAGE_MIN_WIDTH = 0
const MAX_IMAGE_AREA = 13000 * 13000

const REACT_DROPZONE_ERROR_CODES = {
  'file-too-large': (t, name, size) => t('FileSize', { name, size }),
  'file-invalid-type': t => t('WrongFileType'),
}

type Props = {
  accept?: string,
  children: Node,
  className?: string,
  dropzoneProps?: Object,
  imageMaxSize: number,
  maxSize: number,
  minHeight: number,
  minWidth: number,
  multiple: boolean,
  name: string,
  noDragEventsBubbling?: boolean,
  onFinishUpload: (Array<Object>) => void,
  onShowUpload?: (Array<Object>) => void,
  onStartUpload?: (Array<string>) => void,
  onUploadErrors?: (Array<string>) => void,
  originType: number,
  parent?: number,
  style: Object,
  useBase64: boolean,
}

export async function getCheckedFile(file, onProgress, length) {
  let fileData = null

  try {
    fileData = await createFile(file)
  } catch (err) {
    const origin = get(err, ['message', 'response', 'data', 'errors', 'origin'])

    if (onProgress) {
      onProgress(length)
    }

    return origin
  }

  let count = CHECKING_COUNT

  while (fileData.checking && count) {
    count--

    fileData = await new Promise(resolve =>
      setTimeout(() => {
        getFile(fileData.id).then(data => resolve(data))
      }, RETRY_TIMEOUT)
    )
  }

  if (onProgress) {
    onProgress(length)
  }

  return fileData
}

export async function getCheckedFileList(files, onProgress, length) {
  return Promise.all(
    files.map(file => getCheckedFile(file, onProgress, length))
  )
}

export async function getChunks(files, onProgress) {
  const chunks = chunk(files, FILES_LOADING_QUANTITY)

  let checkedFiles = []

  for (const c of chunks) {
    const a = await getCheckedFileList(c, onProgress, files.length)
    checkedFiles.push(a)
  }

  return flatten(checkedFiles)
}

const NewFilesUpload = (props: Props) => {
  const {
    className,
    multiple,
    style,
    children,
    useBase64,
    maxSize,
    name,
    accept,
    minHeight,
    minWidth,
    imageMaxSize,
    originType,
    parent,
    dropzoneProps,
    noDragEventsBubbling = false,
  } = props

  const { t } = useTranslation('Errors')
  const dispatch = useDispatch()

  const uploadFiles = async (files, errors) => {
    props.onShowUpload && props.onShowUpload(files)

    const checkedFiles = await getChunks(files, props.onProgress)
    const goodFiles = checkedFiles.filter(f => f && f.id)
    const badFiles = checkedFiles.filter(f => f && !f.id)
    const err = errors.concat(badFiles)

    props.onFinishUpload(goodFiles)

    if (badFiles.length) {
      dispatch(globalModalError(toString(badFiles.map(f => t(f))), t('File')))
    }

    if (props.onUploadErrors && err && err.length) {
      const texts = err.map(name => t('FileUpload', { name }))

      props.onUploadErrors(texts)
    }
  }

  const onDrop = async (accepted, rejected) => {
    props.onDragStart && props.onDragStart(false)

    let errors = rejected.map(f => {
      const error = get(f, ['errors', '0', 'code'])
      const name = get(f, ['file', 'name'], '')

      if (error) {
        return REACT_DROPZONE_ERROR_CODES[error](t, name, maxSize)
      }

      return null
    })

    let files = []

    if (isNumber(minHeight) || isNumber(minWidth) || isNumber(imageMaxSize)) {
      for (const file of accepted) {
        if (isImageFile(file)) {
          const { width, height } = await getImageDimensions(file)

          if (width * height > MAX_IMAGE_AREA) {
            errors = errors.concat(t('WrongMaxImageArea', { name: file.name }))
            continue
          }

          if (
            (isNumber(minWidth) && minWidth > width) ||
            (isNumber(minHeight) && minHeight > height)
          ) {
            errors = errors.concat(t('ImageDimensions', { name: file.name }))
            continue
          }

          if (imageMaxSize && file.size / MULTIPLIER > imageMaxSize) {
            errors = errors.concat(
              t('FileSize', { name: file.name, size: imageMaxSize })
            )
            continue
          }
        }

        files.push(file)
      }
    }

    if (props.onStartUpload) {
      props.onStartUpload(errors)
    }

    if (errors.length) {
      const errorText = errors.reduce((acc, error) => {
        return acc + error + '<br/>'
      }, '')

      dispatch(globalModalError(errorText, t('File')))
    }

    if (errors.length && !files.length) {
      if (props.onFinishUpload) {
        props.onFinishUpload([])
      }

      return
    }

    files = files.map(f =>
      omitBy(
        {
          origin: [f],
          type: originType,
          name: f.name,
          parent: parent,
        },
        isNil
      )
    )

    if (useBase64) {
      const f = await formatFilesToBase64(accepted)

      props.onFinishUpload(f)

      return
    }

    uploadFiles(files, errors)
  }

  const dropzoneClass = classnames(className, styles.dropzone)

  return (
    <Dropzone
      noDragEventsBubbling={noDragEventsBubbling}
      accept={accept}
      multiple={multiple}
      maxSize={maxSize * MULTIPLIER}
      name={name}
      onDrop={onDrop}
      onDragEnter={() => props.onDragStart && props.onDragStart()}
      {...dropzoneProps}
    >
      {({ getRootProps, getInputProps }) => {
        return (
          <div
            className={dropzoneClass}
            style={style}
            {...getRootProps()}
            onMouseLeave={() => props.onDragStart && props.onDragStart(false)}
          >
            <input {...getInputProps()} />
            {children}
          </div>
        )
      }}
    </Dropzone>
  )
}

NewFilesUpload.defaultProps = {
  multiple: false,
  useBase64: false,
  style: {},
  name: 'file-attach',
  maxSize: DEFAULT_MAX_FILE_SIZE,
  imageMaxSize: DEFAULT_MAX_IMAGE_SIZE,
  minHeight: DEFAULT_IMAGE_MIN_HEIGHT,
  minWidth: DEFAULT_IMAGE_MIN_WIDTH,
  originType: FILE_ORIGIN_TYPES.default,
}

export default NewFilesUpload
