export const ADD_MANAGER_POPUP_INITIATE = 'ADD_MANAGER_POPUP_INITIATE'
export const ADD_MANAGER_POPUP_RESET = 'ADD_MANAGER_POPUP_RESET'
export const ADD_MANAGER_POPUP_SET_BUILDING = 'ADD_MANAGER_POPUP_SET_BUILDING'
export const ADD_MANAGER_POPUP_LOAD_FLATS = 'ADD_MANAGER_POPUP_LOAD_FLATS'
export const ADD_MANAGER_POPUP_UPDATE_FLATS = 'ADD_MANAGER_POPUP_UPDATE_FLATS'
export const ADD_MANAGER_POPUP_ADD_MANAGER = 'ADD_MANAGER_POPUP_ADD_MANAGER'
export const ADD_MANAGER_POPUP_UPDATE_USER = 'ADD_MANAGER_POPUP_UPDATE_USER'
export const ADD_MANAGER_POPUP_SAVE = 'ADD_MANAGER_POPUP_SAVE'
export const ADD_MANAGER_POPUP_SAVED = 'ADD_MANAGER_POPUP_SAVED'
export const ADD_MANAGER_POPUP_SET_MESSAGE = 'ADD_MANAGER_POPUP_SET_MESSAGE'
export const DELETE_USER_POPUP = 'DELETE_USER_POPUP'
export const ADD_MANAGER_POPUP_UPDATE_ROLE = 'ADD_MANAGER_POPUP_UPDATE_ROLE'
export const ADD_MANAGER_POPUP_ERROR = 'ADD_MANAGER_POPUP_ERROR'
