// @flow

import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'

import { Header } from '../../components/Block'
import { EntityIcon } from '../../components/Icon'
import Nbsp from '../../components/NBSP'
import Label from '../../components/Label'

import styles from './InfoHeader.module.scss'

type Props = {
  archived: boolean,
  history: Object,
  number: number,
  onClickHeader: () => void,
  t: string => string,
  title: string,
}

const InfoHeader = (props: Props) => {
  const { history, requestData, collapsed } = props

  const { soft_archived: archived, title, request_no: number } = requestData

  const getHandleBackCallback = () => {
    return () => history.push(`/requests`)
  }

  return (
    <Header
      icon={<EntityIcon id='request' style={{ display: 'block' }} />}
      goBack={getHandleBackCallback()}
      collapsed={collapsed}
      className={styles.text}
      onClickHeader={props.onClickHeader}
    >
      <span className='bar__title-text'>
        {props.t('Ticket')}
        <Nbsp />
        {number && (
          <span>
            {props.t('Common:NumberSymbol')}
            {number}:
          </span>
        )}
        <Nbsp />
        <span>
          <b>{title}</b>
        </span>
      </span>
      {archived && (
        <Label
          text={props.t('Archived')}
          style={{ marginLeft: '8px' }}
          type='outlined'
        />
      )}
    </Header>
  )
}

const mapStateToProps = state => ({
  requestData: state.requestView.data,
})

export default compose(
  withRouter,
  withTranslation('Request'),
  connect(mapStateToProps)
)(InfoHeader)
