// @flow

import { getFetchedItems } from '../../../utils/utils'

export function getOutgoingAccounts(data: Object): Array<Object> {
  const accounts = getFetchedItems(data)

  return accounts.reduce(
    (acc, { outgoing, signature, insert_signature_before_quoted_text }) => {
      if (outgoing) {
        acc.push({
          ...outgoing,
          signature,
          insert_signature_before_quoted_text,
        })
      }

      return acc
    },
    []
  )
}

export function addUserId(
  ids: Array<number>,
  uniqAddressee: Set<number>
): void {
  ids.forEach(id => uniqAddressee.add(id))
}

export function getUniqAddresseeCount(messages: Array<Object>): number {
  let uniqAddressee = new Set([])

  messages.forEach(({ to, cc, bcc, owner }) => {
    uniqAddressee.add(owner)

    addUserId(to, uniqAddressee)
    addUserId(cc, uniqAddressee)
    addUserId(bcc, uniqAddressee)
  })

  return uniqAddressee.size
}

export function getLasMessageUuid(messages: ?Array<Object>): ?string {
  const length = messages && messages.length

  return length && messages ? messages[length - 1].uuid : null
}

export function getUserNameAndEmail(user: Object): string {
  return user.name
    ? `"${user.name} ${user.second_name ? user.second_name : ''} [${
        user.email
      }]`
    : user.email
}
