// @flow

import {
  flow,
  get,
  isObject,
  isPlainObject,
  map,
  pick,
  values,
  replace,
  isArray,
  concat,
  partial,
  omitBy,
} from 'lodash-es'
import moment from 'moment'

import { GLOBAL_MODAL_ERROR } from '../components/Layout/Layout.constants'
import { NOTIFICATION_ERROR } from '../components/Notification/NotificationItem'
import { date as dateFormat, dateTime as dateTimeFormat } from '../config'
import { Convert as convert } from './convert'
import {
  FORMIK_EMPTY_VALUE,
  PRICE_REG_EXP,
  THUMBNAIL_WIDTH,
  USER_GROUPS,
  EMAIL_REG_EXP,
  NOREPLY_EMAIL_REG_EXP,
  NOT_CONFIRMED_STATUS,
  USER_STATUS,
} from '../constants'
import i18n from '../i18n'

const USER_ADMIN_GROUP = USER_GROUPS.admin
const USER_STAFF_GROUPS = [USER_ADMIN_GROUP, USER_GROUPS.manager]
const USER_DWELLER_GROUP = USER_GROUPS.dweller
const USER_LANLORD_ROLE = USER_GROUPS.landlord
const USER_MANAGER_GROUP = USER_GROUPS.manager
const USER_WORKER_GROUP = USER_GROUPS.worker

export const formatDateTime = (
  dateTime?: string,
  format?: string = dateTimeFormat
): string => {
  if (!dateTime) {
    return ''
  }

  return moment(dateTime).format(format)
}

export const formatDate = (
  date?: string,
  format?: string = dateFormat
): string => {
  if (!date) {
    return ''
  }

  return moment(date).format(format)
}

export const getUrlForImage = (imgObject, withTime = false) => {
  if (!imgObject) return ''

  const time = withTime ? `?t=${new Date().getTime()}` : ''

  return `${imgObject.origin || imgObject.preview}${time}`
}

export const forFilterDate = (date, endDate = false) => {
  if (!date) {
    return undefined
  }

  if (endDate) {
    return date.endOf('day').format('YYYY-MM-DD 23:59:59')
  }

  return date.startOf('day').format('YYYY-MM-DD 00:00:00')
}

export const forFilterDueDate = date => {
  if (!date) {
    return undefined
  }

  return date.format('YYYY-MM-DD')
}

export const getUrlForAvatar = user => {
  // TODO
  const stub = '/static/img/icon_user.png'

  if (!user) return stub

  if (user.avatar) {
    return user.avatar
  }

  if (!user.avatar_obj) return stub

  return user.avatar_obj.preview || user.avatar_obj.origin || stub
}

export const cutText = (text?: string, length: number): string => {
  if (!text) return ''

  return text.length > length ? `${text.slice(0, length)}...` : text
}

export const getFormattedDate = (date, options = {}) => {
  const now = moment()
  const then = moment(date)
  const locale = moment.locale()

  let dFormat = 'L'

  if ((then.isSame(now, 'day') && !options.onlyDate) || options.onlyHours) {
    dFormat = 'HH:mm'
  } else if (then.isSame(now, 'year')) {
    if (isDeLocale(locale)) {
      dFormat = 'DD. MMMM'
    } else {
      dFormat = 'DD MMMM'
    }

    if (options && options.specifyHours) {
      dFormat = `HH:mm, ${dFormat}`
    }

    if (options && options.specifyYear) {
      dFormat = `${dFormat} YYYY`
    }
  }

  return then.format(dFormat)
}

export const getFullDateFormat = (date: string) => {
  const locale = moment.locale()
  const dFormat = isDeLocale(locale) ? 'DD.' : 'DD'

  return moment(date).format(`${dFormat} MMMM YYYY`)
}

export const getShortDateFormat = (date: string) => {
  const locale = moment.locale()
  const dFormat = isDeLocale(locale)
  const currentDate = moment(date)

  return dFormat
    ? currentDate.format(`DD.MM.YYYY`)
    : currentDate.format(`MM/DD/YYYY`)
}

export const isNoProfile = (ownerObj?: Object) => {
  if (!ownerObj || ownerObj.deleted) {
    return true
  }

  const withoutProfileGroups = [USER_GROUPS.externalContact, USER_GROUPS.worker]

  const { group, archived } = ownerObj

  return archived || withoutProfileGroups.includes(group)
}

export const errorUtils = (errorData, types = 'notification') => {
  const error = errorData.message.response.data
  let errorMessage = ''

  if (error.detail) {
    errorMessage = error.detail
  } else if (isPlainObject(error)) {
    map(error, (item, key) => {
      errorMessage += `Поле ${key} ${item[0]} <br />`
    })
  } else {
    errorMessage = error
  }

  switch (types) {
    case 'modal':
      return errorMessage
    case 'notification':
      return {
        type: NOTIFICATION_ERROR,
        text: errorMessage,
      }
    default:
      return {
        type: NOTIFICATION_ERROR,
        text: errorMessage,
      }
  }
}

function getError(error) {
  return get(error, ['data', 'errors'], [])
}

export const getTranslationKeys = error => {
  const errors = flow([getError, values])(error)

  if (errors.length) {
    return errors
  }

  const data = get(error, ['data'])

  if (data) {
    return data
  }

  return error
}

export const getErrorText = error => {
  const translate = text => i18n.t(`Errors:${text}`)

  if (!isObject(error)) {
    return translate(error)
  }

  const { data } = error

  if (!isObject(data)) {
    return translate(data)
  }

  const { errors } = data

  if (!isObject(errors)) {
    return translate(errors)
  }

  let message = ''
  for (const key in errors) {
    //eslint-disable-line
    const connector = message.length ? '\n' : ''
    const text = translate(errors[key])
    message = `${text}${connector}${message}`
  }

  return message
}

export const notValidUserAction = user => {
  if (!user) {
    throw new Error('user object is required')
  }

  if (user.validated) {
    return false
  }

  const errorText = i18n.t('Modal:NeedToBeApprovedDwellerText')
  const errorTitle = i18n.t('Modal:NeedToBeApprovedDwellerTitle')

  return {
    type: GLOBAL_MODAL_ERROR,
    errorText,
    errorTitle,
  }
}

// TODO STICKY_DISTANCE should probably go to a separate file with constants
const STICKY_DISTANCE = 75
export const getShouldStickBool = (distance = STICKY_DISTANCE) =>
  window.scrollY > distance

export const getDisplayName = user => {
  if (!user) {
    throw new Error('user object is required')
  }

  // our backend devs send null as values for names. so we must be ready for it
  let displayName = ''

  if (user.name && user.name.length > 0) {
    displayName += user.name
  }

  if (user.second_name && user.second_name.length > 0) {
    displayName += ` ${user.second_name}`
  }

  return displayName
}

export const isDwellerUser = ({ group, role }: Object): boolean =>
  USER_DWELLER_GROUP === group ||
  USER_DWELLER_GROUP === role ||
  USER_LANLORD_ROLE === role ||
  USER_LANLORD_ROLE === group

export const isManagerUser = ({ group }: Object): boolean =>
  USER_MANAGER_GROUP === group

export const isAdminUser = ({ group }: Object): boolean =>
  USER_ADMIN_GROUP === group

export const isWorkerUser = ({ group }: Object): boolean =>
  USER_WORKER_GROUP === group

export const isDwellerWithOneFlat = (user: Object): boolean => {
  const { flats_count: flatsCount } = user

  return isDwellerUser(user) && flatsCount === 1
}

export const isStaffUser = ({ group }: Object): boolean =>
  USER_STAFF_GROUPS.includes(group)

export const userCanCreateRequest = (user: Object) =>
  isStaffUser(user) ||
  USER_STATUS[user.status] === NOT_CONFIRMED_STATUS ||
  user.flats_count

export const getUserName = (user?: Object): string => {
  if (!user) {
    return ''
  }

  if (user.deleted) {
    return i18n.t('Profile:DeletedUser')
  }

  const userData = pick(user, ['fullname', 'full_name', 'second_name', 'name'])

  const { fullname, full_name } = userData

  if (fullname) {
    return fullname
  }

  if (full_name) {
    return full_name
  }

  const { name, second_name: secondName } = userData

  if (!name) {
    return ''
  }

  return !secondName ? name : `${name} ${secondName}`
}

export const getUserEmailName = (user?: Object): string => {
  if (!user) {
    return ''
  }

  if (user.deleted) {
    return i18n.t('Profile:DeletedUser')
  }

  const userData = pick(user, ['second_name', 'name', 'email'])

  const { name, email, second_name: secondName } = userData

  if (!name) {
    return ''
  }

  return secondName === null ? email : `${name} ${secondName}`
}

export function getHref(type: string, value: string) {
  switch (type) {
    case 'contact_email':
    case 'order_email':
      return `mailto:${value}`
    case 'contact_phone':
    case 'order_phone':
      return `tel:${value}`
    default:
      return value
  }
}

export function getThumbnailUrl(
  origin: string,
  url: string,
  s3Url: string,
  thumbnailPath: string
) {
  return replace(origin, s3Url, `${s3Url}${url}${thumbnailPath}`)
}

function getResponse(error, fields) {
  let path = ['message', 'response']

  if (fields && isArray(fields)) {
    path = concat(path, fields)
  }

  return get(error, path)
}

export function getErrorStatus(error) {
  return getResponse(error, ['status'])
}

export function parseMpPrice(price: string, code: string): string {
  if (!price || !code) {
    return ''
  }

  if (!PRICE_REG_EXP.test(price)) {
    return price
  }

  const decimalRegex = /(\d{3})/

  const [decimal, fraction] = price.split('.')

  const fractionSign = isDeLocale(code) ? ',' : '.'
  const decimalSign = isDeLocale(code) ? '.' : ','

  return `${decimal
    .split('')
    .reverse()
    .join('')
    .split(decimalRegex)
    .reverse()
    .filter(s => !!s)
    .map(s => s.split('').reverse().join(''))
    .join(decimalSign)}${fractionSign}${fraction}`
}

export function isDeLocale(locale: string): boolean {
  const regExp = /de(_\w+)?$/

  return regExp.test(locale)
}

export const getAvatar = image => {
  const { thumbnails, origin } = image
  let res = origin

  if (thumbnails && thumbnails.length > 0) {
    const thumbnail = thumbnails.find(({ size = [] }) =>
      size.includes(THUMBNAIL_WIDTH)
    )

    if (thumbnail && thumbnail.url) {
      res = thumbnail.url
    }
  }

  return res
}

export const getUserId = (user?: Object): ?number => {
  if (!user) {
    return null
  }

  return user.id || user.owner || null
}

export const hexToRGB = hex => {
  const arrHex = hex.slice(1).match(/.{1,2}/g)

  const arrRGB = [
    parseInt(arrHex[0], 16),
    parseInt(arrHex[1], 16),
    parseInt(arrHex[2], 16),
  ]

  return arrRGB.join(', ')
}

export const getFetchedItems = (data: Object): Array<Object> =>
  get(data, ['results', 'objects'])

export const compare = (
  initialValues: Object,
  value: any,
  key: string
): boolean => initialValues[key] === value

export const getChangedValues = (
  initialValues: Object,
  submitValues: Object
): Object => {
  const partialCompare = partial(compare, initialValues)

  return omitBy(submitValues, partialCompare)
}

export function isBoolean(value?: ?mixed): boolean {
  return value === true || value === false
}

export function noramlizeValidationValues(
  values: Object,
  keys: Array<string>
): Object {
  return keys.reduce((acc, key) => {
    const value = values[key]
    acc[key] = isBoolean(value) || value ? values[key] : FORMIK_EMPTY_VALUE

    return acc
  }, {})
}

export function getForwardMessage(
  owner: Object,
  toList: Array<Object>,
  subject: string,
  created: string,
  text: string,
  t: string => string,
  sender_email: string
): string {
  const firstUser = toList[0]

  let forwardedText = `<p><br></p><p><br></p><p>---------- ${t(
    'Mail:ForwardMessageTitle'
  )} ---------- </p>
          <p>${t('Mail:FromTitle')}: <b>${getUserName(
    owner
  )}</b> &lt;${sender_email}&gt;</p>
          <p>${t('Mail:DateTitle')}: ${getFormattedDate(created, {
    specifyHours: true,
    specifyYear: true,
    onlyDate: true,
  })}</p>
          <p>${t('Mail:SubjectTitle')}: ${subject}</p>`

  if (firstUser && firstUser.email) {
    forwardedText = `${forwardedText}<p>${t('Mail:ToTitle')}: ${getUserName(
      firstUser
    )} &lt;${firstUser.email}&gt;</p>`
  }

  forwardedText = `${forwardedText}${text}`

  return forwardedText
}

export function getReplyMessage(
  owner: Object,
  created: string,
  t: string => string,
  sender_email: string
): string {
  return `<p><br></p><p><br></p><p>${t('Mail:OnTitle')} ${getFormattedDate(
    created,
    {
      specifyYear: true,
      onlyDate: true,
    }
  )} ${t('Mail:AtTitle')} ${moment(created).format('HH:mm')} ${t(
    'Mail:WroteTitle'
  )} ${getUserName(owner)} &lt;${sender_email}&gt;:</p>`
}

export function convertUserToManagerSearchObj(user: Object): Object {
  const userName = getUserName(user)
  const email = get(user, 'email', '')
  const emailString = email && email !== userName ? ` [${email}]` : ''

  return {
    profile: user,
    name: `${userName}${emailString}`,
  }
}

export function hasEmail(user: Object): boolean {
  const email = user.profile?.email || user.name

  return EMAIL_REG_EXP.test(email) && !NOREPLY_EMAIL_REG_EXP.test(email)
}

export function addPrinterEmailToRecipient(
  recipient: Object,
  email: string
): Object {
  return convertUserToManagerSearchObj({
    ...recipient.profile,
    email,
    printer: true,
  })
}

export function markRecipientBlacklisted(
  recipient: Object,
  blacklist: Array<string>
): Object {
  const email = recipient.profile?.email || recipient.name

  return {
    ...recipient,
    blacklist: !!email && blacklist.indexOf(email) !== -1,
  }
}

export function compareProvideObjectStringify(
  obj1: Object,
  obj2: Object
): boolean {
  return JSON.stringify(obj1) === JSON.stringify(obj2)
}

export const passwordSecureRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,30}$/

export const bicRegExp = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/

const Utils = {
  cutText,
  getErrorText,
  getUrlForImage,
  getUrlForAvatar,
  getUserName,
  formatDateTime,
  formatDate,
  isDwellerUser,
  isStaffUser,
  convert,
  forFilterDate,
  errorUtils,
  notValidUserAction,
  compareProvideObjectStringify,
  userCanCreateRequest,
}

export default Utils
