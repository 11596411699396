// @flow

import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDrag, useDrop } from 'react-dnd'

import { isPdfFile } from '../../../../utils/file'

import { getEmptyImage, NativeTypes } from 'react-dnd-html5-backend'
import { find, isNil, omitBy } from 'lodash-es'
import { useDispatch } from 'react-redux'

import {
  getFormattedDate,
  getUserName,
  isStaffUser,
} from '../../../../utils/utils'
import { getChunks } from '../../../NewFilesUpload/NewFilesUpload'
import { globalModalError } from '../../../Layout/Layout.actions'
import { FILE_ORIGIN_TYPES } from '../../../../containers/FilesUpload'
import SelectCustom from '../../../Select/SelectCustom'
import { USERS_ACCESS_ALLOW, FOLDER_TYPE } from '../../Files.constants'
import { getHumanFileSize } from '../../Files.utils'
import FilesIcon from '../../FilesIcon'
import Invoices from '../../../../components/Request/RequestList/ListItem/Cells/Info/AdminInfo/Invoices'
import OwnerIcon from './OwnerIcon'
import Checkbox from '../../../Checkbox'
import {
  changeDirectory,
  renameFile,
  // getFileById,
} from '../../../../core/api/api.file'
import NewTableBodyRow from '../../../NewTable/NewTableBodyRow'
import NewTableBodyCell from '../../../NewTable/NewTableBodyCell'
import { useUser } from '../../../../hooks/useUser'

import styles from './FileListItem.module.scss'

const FileListItem = props => {
  const {
    file: {
      type,
      size,
      id,
      name,
      is_persistent,
      users_access,
      owner,
      is_immutable,
      permissions,
      created,
      parent,
    },
    file,
    can_write,
    selected,
    canAddInInvoice,
    filesAddedInInvoce,
    setActiveFile,
  } = props

  // const [working, setWorking] = useState(false)

  const ref = useRef(null)

  const user = useUser()

  const isAddedInInvoice = filesAddedInInvoce.includes(id)

  const dispatch = useDispatch()

  const { t } = useTranslation('Files')

  const [collectedDragProps, drag, preview] = useDrag({
    item: {
      id,
      type: 'file',
      isFolder: type === FOLDER_TYPE,
      name,
      is_persistent,
    },
    canDrag: () => permissions.can_edit,
    collect: monitor => {
      return {
        isDragging: monitor.isDragging(),
      }
    },
  })

  const [collectedDropProps, drop] = useDrop({
    accept: ['file', NativeTypes.FILE],
    canDrop: (props, monitor) => {
      if (!permissions.can_write) {
        return false
      }

      const { id: targetId, files } = monitor.getItem()

      return files?.length || (type === FOLDER_TYPE && targetId !== id)
    },
    drop: (item, monitor) => {
      const { id: targetId, isFolder, files } = monitor.getItem()

      if (Array.isArray(files) && files.length) {
        props.setUploading(true)
        getChunks(
          files.map(f =>
            omitBy(
              {
                origin: [f],
                type: FILE_ORIGIN_TYPES.default,
                name: f.name,
                parent: type == FOLDER_TYPE ? id : parent,
              },
              isNil
            )
          )
        ).then(checkedFiles => {
          const badFiles = checkedFiles.filter(f => !f || !f.id)

          if (badFiles.length) {
            dispatch(globalModalError(t('Errors:File')))
          } else if (type === FOLDER_TYPE) {
            props.handleClick(id, type)()
          } else {
            props.setLoading(true)
          }

          props.setUploading(false)
        })

        return
      }

      if (selected.length > 1 && selected.includes(targetId)) {
        const selectedFiles = selected.map(s => find(props.files, { id: s }))

        Promise.all(
          selectedFiles.map(file => {
            const { type, id: fileId } = file

            let api = renameFile

            if (type === FOLDER_TYPE) {
              api = changeDirectory
            }

            return api(fileId, { parent: id }).finally(() =>
              props.setLoading(true)
            )
          })
        ).catch(error => props.setError(error))

        return
      }

      let api = renameFile

      if (isFolder) {
        api = changeDirectory
      }

      api(targetId, { parent: id })
        .catch(error => props.setError(error))
        .finally(() => props.setLoading(true))
    },
    collect: monitor => ({
      isDropTarget: monitor.canDrop() && monitor.isOver({ shallow: true }),
    }),
  })

  preview(getEmptyImage(), { captureDraggingState: true })

  drag(drop(ref))

  const formattedDate = created ? getFormattedDate(created) : '-'

  const fileCanAddInInvoice =
    isPdfFile(file) && canAddInInvoice && !isAddedInInvoice

  let options = [
    {
      value: 'onRename',
      label: t('Rename'),
      handler: props.openRenameModal(id, type, name),
      icon: 'pencil',
    },
  ]

  if (isStaffUser(user)) {
    options.push({
      value: 'onChangeType',
      label: t('ChangeType'),
      handler: props.openChangeTypeModal(id, users_access, name, type),
      icon: 'pencil',
    })
  }

  options.push({
    value: 'onReplace',
    label: t('Replace'),
    handler: props.openReplaceModal(id, users_access, name, type),
    icon: 'restore',
  })

  if (fileCanAddInInvoice) {
    options.push({
      value: 'onAddToInvoce',
      label: t('AddToInvoice'),
      handler: props.openInvoiceModal(id),
      icon: 'request',
    })
  }

  options.push({
    value: 'onRemove',
    label: t('Remove'),
    handler: props.openRemoveModal(id, type),
    icon: 'bin',
  })

  const handleChange = () => props.handleChange(id)
  const getFileName = () => (is_persistent ? t(name) : name)

  // const handleClickLink = e => {
  //   e.stopPropagation()

  //   if (!working) {
  //     setWorking(true)
  //     getFileById({ file_ids: id, scope: 'tree' }).then(updatedFile => {
  //       setWorking(false)
  //       window.open(updatedFile[0].origin, '_blank')
  //     })
  //   }
  // }

  const handleClickRow = () => {
    if (type !== FOLDER_TYPE) {
      setActiveFile(id)
    }

    props.handleClick(id, type)()
  }

  return (
    <NewTableBodyRow
      key={id}
      id={id}
      ref={ref}
      checked={collectedDropProps.isDropTarget || collectedDragProps.isDragging}
      onClick={() => handleClickRow()}
    >
      <NewTableBodyCell
        title={!isAddedInInvoice && getFileName()}
        usePropsCallback={!!setActiveFile}
        linkId={type !== FOLDER_TYPE && id}
        onClickLink={() => setActiveFile(id)}
      >
        {can_write && (
          <Checkbox
            className={styles.checkbox}
            checked={selected.includes(id)}
            onChange={handleChange}
          />
        )}
        <FilesIcon file={file} />
        {isAddedInInvoice && (
          <div className={styles.inovoice}>
            <span
              className={styles.link}
              title={getFileName()}
              // onClick={handleClickLink}
            >
              {getFileName()}
            </span>
            <Invoices />
          </div>
        )}
      </NewTableBodyCell>
      <NewTableBodyCell title={getUserName(owner)}>
        <OwnerIcon file={{ avatar: owner.avatar }} />
      </NewTableBodyCell>
      <NewTableBodyCell
        title={
          is_immutable
            ? ''
            : users_access === USERS_ACCESS_ALLOW
            ? t('Public')
            : t('Private')
        }
      />
      <NewTableBodyCell
        align='flex-end'
        title={getHumanFileSize(size)}
      ></NewTableBodyCell>
      <NewTableBodyCell>
        <div className='table-item table-item__text' title={formattedDate}>
          <span>{formattedDate}</span>
        </div>
        {permissions.can_edit && (
          <div className='table-extra table-extra--settings'>
            <SelectCustom options={options} onChange={opt => opt.handler()} />
          </div>
        )}
      </NewTableBodyCell>
    </NewTableBodyRow>
  )
}

export default FileListItem
