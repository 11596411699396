// @flow

import {
  all,
  put,
  call,
  fork,
  takeEvery,
  takeLatest,
  select,
} from 'redux-saga/effects'
import { replace } from 'connected-react-router'

import api from '../../core/api'
import {
  serverError,
  updateCountersAction,
} from '../../components/Layout/Layout.actions'
import {
  getCurrentPage,
  getNewPage,
  formatPathname,
  getNewQueryString,
} from '../../utils/routing'
import * as actionTypes from './ThreadsList.actionTypes'
import {
  mailListInitiated,
  mailListInitiating,
  chatroomsGetFilesInitiated,
} from './ThreadsList.actions'
import {
  GLOBAL_MODAL_ERROR,
  SERVER_404_ERROR,
  SERVER_ERROR,
} from '../../components/Layout/Layout.constants'
import { get } from 'lodash-es'
import { normalizeMailList } from './ThreadsList.utils'

const PAGE_LENGTH = 20

function* init({
  params: {
    page,
    folder,
    unviewed_msg: unviewedMsg,
    linked_requests,
    thread_user: threadUser,
    soft_archived: softArchived,
    created_gte: createdGte,
    created_lte: createdLte,
    title,
    search,
    credentials,
    account,
    attached_filename: attachedFilename,
    folder_external_ids,
    exclude_folder_external_ids,
    isSearch,
    query,
  },
}) {
  try {
    const params = {
      page,
      unviewed_msg: unviewedMsg,
      thread_user: threadUser,
      created_gte: createdGte,
      created_lte: createdLte,
      linked_requests,
      title,
      search,
      credentials,
      account,
      folder_external_ids,
      exclude_folder_external_ids,
      attached_filename: attachedFilename,
    }

    if (folder === 'sent') {
      params.sent = true
    }

    if (isSearch) {
      params.query = query
    }

    const accounts = yield call(api.mail.getMailAccountMenuList)

    const accountList = [].concat(...Object.values(accounts))

    let getApi = isSearch
      ? api.mail.search
      : softArchived
      ? api.mail.getArchivedInbox
      : api.mail.getInbox

    let conversationView = true

    if (account) {
      const currAccount = accountList.find(
        item => item.id === parseInt(account)
      )

      if (currAccount) {
        conversationView = currAccount.conversation_view
      }
    }

    if (!conversationView) {
      getApi = api.mail.getInboxNoThread

      if (softArchived) {
        params.archived = true
      }
    }

    const {
      results: { objects: mailList },
      meta,
      permissions,
    } = yield call(getApi, params)

    let cantCreateMessage = false

    if (mailList.length === 0) {
      const profileConfig = yield call(api.profile.getMyProfileConfig)
      cantCreateMessage =
        !profileConfig.mail_credentials_in ||
        !profileConfig.mail_credentials_out
    }

    let processedList = mailList

    if (!conversationView) {
      processedList = normalizeMailList(processedList)
    }

    yield put(
      mailListInitiated(
        processedList,
        meta,
        cantCreateMessage,
        folder,
        permissions
      )
    )
  } catch (error) {
    const action = serverError(error)

    if (action.type === SERVER_404_ERROR || action.type === SERVER_ERROR) {
      yield put(replace('/404'))
    } else {
      yield put(action)
      yield put({ type: actionTypes.MAIL_LIST_ERROR, error })
    }
  }
}

function* getFiles({ fileIdToBeShown, uuid }) {
  try {
    const {
      results: { objects: files },
      meta,
    } = yield call(api.mail.getThreadFiles, uuid)

    yield put(chatroomsGetFilesInitiated(fileIdToBeShown, files, meta))
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actionTypes.MAIL_GET_FILES_ERROR, error })
  }
}

function* patch({ ids, params, patch }) {
  try {
    let conversationView = true

    const accounts = yield call(api.mail.getMailAccountMenuList)

    const accountList = [].concat(...Object.values(accounts))

    if (params.account) {
      const currAccount = accountList.find(
        item => item.id === parseInt(params.account)
      )

      if (currAccount) {
        conversationView = currAccount.conversation_view
      }
    }

    const model = conversationView ? 'thread' : 'email_message'

    const patchParams = {
      model,
      model_pks: JSON.stringify(ids),
      patch,
    }

    yield call(api.massAction.updateRequest, patchParams)

    const location = yield select(state => state.router.location)
    const meta = yield select(state => state.threadsList.meta)

    const currentPage = getCurrentPage(location)
    const newPage = getNewPage(location, meta, PAGE_LENGTH, ids.length)

    let action = mailListInitiating(params)

    if (currentPage !== newPage) {
      const newParams = {
        ...params,
        page: newPage,
      }
      const pathname = formatPathname(location.pathname)
      const newQueryString = getNewQueryString(newParams)

      action = replace(`/${pathname}/?${newQueryString}`)
    }

    yield put(action)
    yield put(updateCountersAction())
    yield put({
      type: actionTypes.MAIL_LIST_INITIATING,
      params: {
        ...params,
        page: getNewPage(location, meta, PAGE_LENGTH, ids.length),
      },
    })
  } catch (error) {
    const status = get(error, ['message', 'response', 'status'], null)

    if (status === 400 || status === 422) {
      yield put({
        type: GLOBAL_MODAL_ERROR,
        needTranslate: true,
        errorText: 'EmailIntegrationNylas:NeedReconnect',
      })

      yield put({
        type: actionTypes.THREADS_ERROR,
      })
    } else {
      yield put(serverError(error))
    }

    yield put({ type: actionTypes.MAIL_GET_FILES_ERROR, error })
  }
}

function* watchInit() {
  yield takeLatest(actionTypes.MAIL_LIST_INITIATING, init)
}

function* watchPatch() {
  yield takeEvery(
    [
      actionTypes.DELETE,
      actionTypes.RESTORE,
      actionTypes.MARK_AS_READ,
      actionTypes.MARK_AS_UNREAD,
      actionTypes.ADD_STAR,
      actionTypes.REMOVE_STAR,
    ],
    patch
  )
}

function* watchGetFiles() {
  yield takeEvery(actionTypes.MAIL_GET_FILES_INITIATING, getFiles)
}

export default function* watch() {
  yield all([fork(watchPatch), fork(watchInit), fork(watchGetFiles)])
}
