// @flow

import { omit, isEmpty, keys } from 'lodash-es'
import { useLocation } from 'react-router-dom'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'

import {
  formatPathname,
  getCurrentFilters,
  getNewQueryString,
} from '../utils/routing'
import { FILTERS_ALIASES } from '../components/Menu/Menu.constants'

export const useClearFilters = (menuName: string): [boolean, Function] => {
  const location = useLocation()
  const dispatch = useDispatch()

  const filters = getCurrentFilters(location)

  const subMenuName = filters.sub

  const subMenuObj = subMenuName ? FILTERS_ALIASES[menuName][subMenuName] : {}

  const subMenuKeys = keys(subMenuObj)

  const isDisabled = isEmpty(
    omit(filters, [
      'page',
      'sub',
      'credentials',
      'account',
      'exclude_folder_external_ids',
      'folder_external_ids',
      ...subMenuKeys,
    ])
  )

  const pathname = formatPathname(location.pathname)

  const credentials = filters.credentials
    ? { credentials: filters.credentials }
    : {}

  const account = filters.account ? { account: filters.account } : {}

  const exclude_folder_external_ids = filters.exclude_folder_external_ids
    ? { exclude_folder_external_ids: filters.exclude_folder_external_ids }
    : {}
  const folder_external_ids = filters.folder_external_ids
    ? { folder_external_ids: filters.folder_external_ids }
    : {}

  const sub = subMenuName ? { sub: subMenuName } : {}

  const search = getNewQueryString({
    page: 1,
    ...sub,
    ...credentials,
    ...account,
    ...exclude_folder_external_ids,
    ...folder_external_ids,
    ...subMenuObj,
  })

  const handleClear = () => dispatch(push(`/${pathname}/?${search}`))

  return [isDisabled, handleClear]
}
