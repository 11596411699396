// @flow

import { get, has } from 'lodash-es'
import { createSelector } from 'reselect'

import type { InititalState as LoginState } from '../components/Auth/Login/Login.reducer'
import { USER_GROUPS } from '../constants'
import BrowserStorage from './browserStorage'
import { DEFAULT_LANGUAGE } from '../constants'
import { isDeLocale } from './utils'

export const getUser = (state: { login: LoginState }) => state.login.user

export const getLocation = state => state.router.location

export const getUk = state => state.init.uk

export const getLanguageCode = (state: { login: LoginState }): string =>
  get(getUser(state), ['language_obj', 'code']) || DEFAULT_LANGUAGE

export const getUkCity = state => get(getUk(state), 'city_obj') || null

export const getUkCountry = state => get(getUk(state), 'country_obj') || null

export const getUkLanguage = state => get(getUk(state), 'language_obj') || null

export const getUkLogo = state => get(getUk(state), 'logo_obj') || null

export const getUkMobileLogo = state =>
  get(getUk(state), 'logo_mobile_obj') || null

export const showMarketplace = state => !!state.init.show_marketplace

export const isUserAuthorized = state => {
  const user = getUser(state)

  return Boolean(
    user &&
      has(user, 'authorize') &&
      user.authorize &&
      user.group !== 'worker' &&
      BrowserStorage.get('token')
  )
}

export const isMenuCollapsed = state => state.menu.collapsed

export const getThemeColor = state =>
  get(getUk(state), ['theme', 'buttons']) || '' // TODO default color

export const getThemeHeaderColor = state =>
  get(getUk(state), ['theme', 'header']) || ''

export const getDateFormat = createSelector(
  getLanguageCode,
  (code: string) => `HH:mm, D${isDeLocale(code) ? '.' : ''} MMM YYYY`
)

export const getFullDateFormat = createSelector(
  getLanguageCode,
  code => `HH:mm, D${isDeLocale(code) ? '.' : ''} MMMM YYYY`
)

export const getUserGroup = createSelector(getUser, ({ group }) => group)

export const getUkConfig = state => get(state, ['init', 'uk_config']) || {}

export const getMarketplaceStatus = createSelector(
  getUkConfig,
  getUserGroup,
  (
    {
      can_dweller_use_marketplace: canDwellerUseMp = false,
      can_manager_use_marketplace: canManagerUseMp = false,
    },
    userGroup
  ) =>
    (canDwellerUseMp && userGroup === USER_GROUPS.dweller) ||
    (canManagerUseMp && userGroup === USER_GROUPS.manager) ||
    userGroup === USER_GROUPS.admin
)

export const isWizardEnabled = state => state.init.is_wizard_enabled
export const getEmailFonts = state => state.init.email_fonts
