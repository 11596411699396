// @flow

import React, { useRef } from 'react'
import { useEffect, useState } from 'react'
import type { Node } from 'react'

import {
  isImageFile,
  isVideoFile,
  isPdfFile,
  isVideoPlaying,
} from '../../utils/file'
import { getById, getFileScope } from '../../core/api/api.file'
import { getFileScopeParams } from '../../utils/file'
import { DocumentFilePreview } from '../Documents'
import DocFilePreview from './DocFilePreview'

import styles from './LightboxGallery.module.scss'
import ViewerComponent from '../PdfPreview/ViewerComponent'
import FileDownloader from '../FileDownloader'
import { useTranslation } from 'react-i18next'
import Button from '../Button'
import Loader from '../Loader'

const VIDEO_SIGNATURE_TIMEOUT_SECS = 60

const File = (props): Node => {
  const {
    file: { id, docId },
    isDocPreview,
    newGallery,
    object = undefined,
    takeOrigin,
    subject,
  } = props

  const [updatedFile, setUpdatedFile] = useState(null)
  const [working, setWorking] = useState(false)
  const [signExpired, setSignExpired] = useState(false)
  const { t } = useTranslation('Files')
  const timerRef = useRef()
  const videoRef = useRef()

  const getFile = () => {
    getById(docId).then(res => {
      if (res.checking) {
        timerRef.current = setTimeout(getFile, 2000)
      } else {
        const params = getFileScopeParams(props)
        getFileScope(docId, params).then(updatedFile => {
          setUpdatedFile(updatedFile)
        })
      }
    })
  }

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current)
    }
  }, [])

  useEffect(() => {
    if (!id) {
      return
    }

    if (updatedFile && !signExpired) {
      return
    }

    if (takeOrigin) {
      setUpdatedFile(props.file)

      return
    }

    if (docId) {
      getFile()
    } else {
      clearTimeout(timerRef.current)
      const params = getFileScopeParams(props)
      getFileScope(id, params).then(updatedFile => {
        setUpdatedFile(updatedFile)

        if (isVideoFile(updatedFile)) {
          setSignExpired(false)
          let time = videoRef.current.currentTime
          let playing = isVideoPlaying(videoRef.current)
          videoRef.current.load()

          if (playing) {
            videoRef.current.currentTime = time
            videoRef.current.play()
          }

          setTimeout(
            () => setSignExpired(true),
            VIDEO_SIGNATURE_TIMEOUT_SECS * 1000
          )
        }
      })
    }
  }, [id, signExpired])

  if (!updatedFile) {
    return <Loader type='medium' text={false} />
  }

  const { mime_type: type, origin } = updatedFile

  if (isVideoFile(updatedFile)) {
    return (
      <video
        controls
        style={{
          height: styles.maxImageHeight,
          width: styles.maxImageWidth,
        }}
        ref={videoRef}
      >
        <source src={origin} type={type} />
      </video>
    )
  }

  if (isImageFile(updatedFile)) {
    return <img src={origin} alt='preview' />
  }

  if (isPdfFile(updatedFile) && newGallery) {
    return <ViewerComponent file={updatedFile} />
  }

  if (isPdfFile(updatedFile) && isDocPreview) {
    return <DocFilePreview pdf fileId={id} />
  }

  if (newGallery) {
    return (
      <div className={styles.otherFiles}>
        <span>{t('PreviewNotAvailable')}</span>
        <FileDownloader
          newGallery
          working={working}
          id={updatedFile.id}
          setWorking={setWorking}
          scope={props.scope}
          subject={subject}
          object={object}
        >
          <Button.Save icon={'download'}>
            {working ? t('ScanningViruses') : t('Download')}
          </Button.Save>
        </FileDownloader>
      </div>
    )
  }

  return (
    <div className={styles.icon}>
      <DocumentFilePreview data={updatedFile} />
    </div>
  )
}

export default File
