// @flow

import React from 'react'
import type { Node } from 'react'

import NewTable from '../../NewTable'
import TableHead from './TableHead'
import TableBody from './TableBody'

type Props = {
  isLoading: boolean,
  onSelect: Function,
  selectedItems: Array<any>,
  setRemovingItems: Function,
  templates: Array<Object>,
}

const Table = (props: Props): Node => {
  const { templates, selectedItems, isLoading } = props

  return (
    <NewTable loading={isLoading}>
      <TableHead />
      <TableBody
        templates={templates}
        selectedItems={selectedItems}
        setRemovingItems={props.setRemovingItems}
        onSelect={props.onSelect}
      />
    </NewTable>
  )
}

export default Table
