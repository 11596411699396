// @flow

import type { MailItem } from './ThreadsList'

export const getMessageText = (message?: ?MailItem): ?string => {
  if (!message) {
    return null
  }

  const { is_system, text } = message

  return is_system ? text.replace(/<b>|<\/b>/g, '') : text
}

export const normalizeMailList = list => {
  return list.map(item => {
    return {
      isSingle: true,
      created: null,
      created_requests: item.created_requests,
      has_error_message: item.has_error,
      has_pending_message: item.is_pending,
      linked_requests: item.linked_requests,
      notes: null,
      owner: item.owner_obj.id,
      requests: item.linked_requests,
      shared: item.shared,
      starred: item.starred,
      title: item.title,
      total_msg_count: 0,
      unviewed_msg_count: Number(!item.viewed),
      uuid: item.uuid,
      updated: null,
      last_message: {
        avatar: item.avatar,
        created: item.created,
        uuid: item.uuid,
        files: item.files,
        text: item.text,
        request_thread: null,
        owner_obj: item.owner_obj,
        recipient: item.recipient,
      },
    }
  })
}
