// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { find, flow, isNull, flatten, last } from 'lodash-es'
import classnames from 'classnames'
import { withTranslation } from 'react-i18next'
import { parse } from 'qs'
import { withRouter } from 'react-router-dom'

import MenuList from './MenuList'
import MenuLogo from './MenuLogo'
import MenuSubList from './MenuSubList'
import {
  getCurrentItem,
  getFilterList,
  getExpandedFilterList,
  getMenuItemList,
  getSubMenuItemList,
  getMainPathName,
} from './Menu.utils'
import { openMenu, toggleMenu } from './Menu.actions'
import BrowserStorage from '../../utils/browserStorage'
import { MENU_LOCALE_STORAGE_KEY } from './Menu.constants'
import { getCurrentFilters } from '../../utils/routing'
import { isMenuCollapsed } from '../../utils/commonSelectors'
import styles from './Menu.module.scss'

type Filter = { name: string }
type FilterList = Array<Filter>
type SubmenuItem = { default?: boolean, name: string, tab?: string }
type SubMenuList = Array<SubmenuItem>
type MenuItem = {
  filters?: FilterList,
  name: string,
  submenu?: SubMenuList,
}
type MenuGroup = Array<MenuItem>

export type MenuType = Array<MenuGroup>

type Props = {
  collapsed: boolean,
  counters: Object,
  location: Object,
  mainMenu: MenuType,
  match: Object,
  openMenu: Function,
  pseudoMenu: Array<Object>,
  settingsMenu: MenuItem,
  t: (string, params?: Object) => string,
  toggleMenu: Function,
}

class Menu extends Component<Props> {
  componentDidMount() {
    if (
      isNull(JSON.parse(BrowserStorage.get(MENU_LOCALE_STORAGE_KEY))) &&
      window.innerWidth < 1170
    ) {
      BrowserStorage.set(MENU_LOCALE_STORAGE_KEY, true)
      this.props.toggleMenu()
    }
  }

  componentDidUpdate(prevProps) {
    const { collapsed: oldCollapsed } = prevProps
    const { collapsed: newCollapsed } = this.props

    if (oldCollapsed !== newCollapsed) {
      BrowserStorage.set(MENU_LOCALE_STORAGE_KEY, newCollapsed)
    }
  }

  render() {
    const {
      collapsed,
      match,
      mainMenu,
      pseudoMenu,
      settingsMenu,
      location,
      mailFolder,
    } = this.props

    if (!mainMenu) {
      return null
    }

    const menuClass = classnames(styles.menu, {
      [styles.collapsed]: collapsed,
    })

    let mainPathName = getMainPathName(match)
    let menuItem
    let isPseudo = false
    let menu = mainMenu

    if (mainPathName === 'settings') {
      mainPathName = 'settings/' + match.path.split('/')[2]
      menuItem = getCurrentItem(settingsMenu, mainPathName)
      menu = settingsMenu
    } else {
      menuItem = getCurrentItem(mainMenu, mainPathName)
    }

    if (!menuItem) {
      menuItem = getCurrentItem(pseudoMenu, mainPathName)

      if (menuItem) {
        isPseudo = true
      }
    }

    // TODO HACK
    if (!menuItem && mainPathName === 'dwellers-files') {
      menuItem = getCurrentItem(mainMenu, 'dwellersFiles')
    }

    let subMenuItems
    subMenuItems = menuItem && menuItem.submenu
    let filterItems = menuItem && menuItem.filters

    if (mainPathName === 'mails') {
      if (mailFolder.id) {
        subMenuItems =
          menuItem &&
          [
            ...menuItem?.submenu,
            { name: mailFolder.display_name, tab: match.params.tab },
          ].filter(
            folder => folder.name !== `${match.params.tab}_deleted_emails`
          )
      } else if (!mailFolder.accountId) {
        subMenuItems =
          menuItem &&
          menuItem?.submenu?.filter(
            folder => folder.name !== `${match.params.tab}_deleted_emails`
          )
        filterItems = null
      }
    }

    if (mainPathName === 'buildings' && !!match.params.search) {
      subMenuItems = null
      filterItems = null
    }

    if (mainPathName === 'flats' && !!match.params.search) {
      subMenuItems = null
      filterItems = null
    }

    if (mainPathName === 'users' && !!match.params.search) {
      subMenuItems = null
      filterItems = null
    }

    let expandedFilterItems = menuItem && menuItem.expanded_filters

    const filters = getCurrentFilters(location)
    const currentTab = match.params.tab || filters.tab

    if (!filterItems && menuItem && menuItem.tabs) {
      const tabs = menuItem.tabs
      const filterTab = tabs && currentTab && tabs[currentTab]
      filterItems = filterTab && filterTab.filters
    }

    if (!expandedFilterItems && menuItem && menuItem.tabs) {
      const tabs = menuItem.tabs
      const expandedFilterTab = tabs && currentTab && tabs[currentTab]
      expandedFilterItems =
        expandedFilterTab && expandedFilterTab.expanded_filters
    }

    const processedSubItems = getSubMenuItemList(
      subMenuItems,
      currentTab,
      this.props.t
    )

    const processedFilterItems =
      filterItems &&
      getFilterList(filterItems, this.props.t).filter(i => !i.hidden)
    const processedExpandedFilterItems =
      expandedFilterItems &&
      getExpandedFilterList(expandedFilterItems, this.props.t)
    const hasSub = !!(processedSubItems && processedSubItems.length)
    const processedItems = getMenuItemList(menu, this.props.t)

    const mainMenuLastItemName = flow([flatten, last])(processedItems).name

    const searchParams = parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })
    const defaultSubItem =
      find(processedSubItems, { default: true }) ||
      (processedSubItems && processedSubItems[0])

    const includedSub = find(processedSubItems, { name: searchParams.sub })

    const activeSubName =
      !isPseudo &&
      hasSub &&
      ((includedSub && searchParams.sub) || defaultSubItem.name)

    return (
      <nav className={menuClass}>
        <MenuLogo collapsed={this.props.collapsed} t={this.props.t} />
        <div className={styles.menuWrapper}>
          <MenuList
            menuItems={processedItems}
            collapsed={this.props.collapsed}
            mainPathName={mainPathName}
            lastItemName={mainMenuLastItemName}
            hasSub={
              hasSub || (processedFilterItems && processedFilterItems.length)
            }
            onOpenMenu={this.props.openMenu}
          />
          {(hasSub ||
            (processedFilterItems && processedFilterItems.length)) && (
            <MenuSubList
              hasSub={hasSub}
              menuItems={processedSubItems}
              menuName={menuItem.name}
              activeName={activeSubName}
              filterItems={processedFilterItems}
              expandedFilterItems={processedExpandedFilterItems}
              collapsed={this.props.collapsed}
              url={this.props.match.url}
              search={this.props.location.search}
              isPseudo={isPseudo}
              currentTab={currentTab}
            />
          )}
        </div>
      </nav>
    )
  }
}

const mapStateToProps = state => ({
  collapsed: isMenuCollapsed(state),
  mainMenu: state.init.menu.main_menu,
  settingsMenu: state.init.menu.settings_menu,
  pseudoMenu: state.init.menu.pseudo_menu,
  mailFolder: state.threadsList.currentFolder,
})

const mapDispatchToProps = { openMenu, toggleMenu }

export default compose(
  withTranslation('Menu'),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Menu)
