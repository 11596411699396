// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { CSSTransition } from 'react-transition-group'
import classnames from 'classnames'
import { isNil, isNull } from 'lodash-es'

import Button from '../Button'
import FileDownloader from '../FileDownloader'

import { allowScroll, blockScroll } from './LightboxGallery.utils'

import styles from './LightboxGallery.module.scss'
import File from './File'
import FilesGalleryHeader from '../FilesGallery'
import Icon from '../Icon'

const KEYDOWN_EVENT = 'keydown'
const TRANSITION_DURATION = 200

type Props = {
  activeIndex: ?number,
  images: Array<Object>,
  onClose: () => void,
  scope: string,
  t: string => string,
  withoutDownload?: boolean,
}
type State = {
  active: number,
  opened: boolean,
}

class LightboxGallery extends Component<Props, State> {
  state = {
    active: null,
    opened: false,
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { activeIndex } = this.props
    const { opened } = this.state

    if (isNil(prevProps.activeIndex) && !isNil(activeIndex)) {
      this.setState({
        active: activeIndex || 0,
        opened: true,
      })

      this.takeControl()
    }

    if (prevState.opened && !opened) {
      this.giveControl()
    }

    if (!isNil(activeIndex) && opened) {
      this.giveControl()
    }
  }

  componentWillUnmount() {
    if (this.state.opened) {
      this.giveControl()
    }
  }

  isActiveFirst = () => this.state.active === 0

  isActiveLast = () => this.state.active === this.props.images.length - 1

  takeControl = () => {
    blockScroll()
    window.addEventListener(KEYDOWN_EVENT, this.handleKeyboard)
  }

  giveControl = () => {
    allowScroll()
    window.removeEventListener(KEYDOWN_EVENT, this.handleKeyboard)
  }

  handleKeyboard = e => {
    if (!this.state.opened) {
      return
    }

    if (e.keyCode === 37) {
      this.showPrev(e)
    } else if (e.keyCode === 39) {
      this.showNext(e)
    } else if (e.keyCode === 27) {
      this.hide()
    }
  }

  hide = () => {
    this.setState({ opened: false })
  }

  close = () => {
    this.setState({ active: null })
    this.props.onClose()
  }

  showNext = e => {
    if (!this.isActiveLast()) {
      if (e) {
        e.preventDefault()
        e.stopPropagation()
      }

      this.setState(state => ({
        active: state.active + 1,
      }))
    }
  }

  showPrev = e => {
    if (!this.isActiveFirst()) {
      if (e) {
        e.preventDefault()
        e.stopPropagation()
      }

      this.setState(state => ({
        active: state.active - 1,
      }))
    }
  }

  renderArrow = (next: boolean = true) => {
    const hidden = next ? this.isActiveLast() : this.isActiveFirst()
    const { newGallery } = this.props

    if (hidden) {
      return null
    }

    const btnClass = classnames(styles.arrow, {
      modal__prev: !next,
      modal__next: next,
    })

    if (newGallery) {
      let classBtn = classnames(styles.btn, { [styles.btnPrev]: !next })
      let wrapperClass = classnames(!next ? styles.leftBtn : styles.rightBtn)

      return (
        <div className={wrapperClass}>
          <div
            className={styles.btnWrapper}
            onClick={next ? this.showNext : this.showPrev}
          >
            <Icon className={classBtn} id={'expend'} />
          </div>
        </div>
      )
    }

    return (
      <button
        title={this.props.t(next ? 'next' : 'prev')}
        type='button'
        className={btnClass}
        onClick={next ? this.showNext : this.showPrev}
      />
    )
  }

  renderItem = () => {
    const {
      images,
      withoutDownload,
      scope,
      newGallery,
      object,
      takeOrigin,
      subject,
    } = this.props
    const { active } = this.state

    if (!images || (!images.length && isNull(active))) {
      return null
    }

    const file = images[active]

    const itemClassName = classnames(styles.item, styles.itemNew)

    if (!file) {
      return null
    }

    return (
      <figure key={`image-${active}`} className={itemClassName}>
        <File
          takeOrigin={takeOrigin}
          key={`image-${active}`}
          newGallery={newGallery}
          file={file}
          scope={scope}
          object={object}
          subject={subject}
          isDocPreview={this.props.isDocPreview}
        />
        {!withoutDownload && !newGallery && <FileDownloader id={file.id} />}
        {!!file.caption && (
          <div className={styles.itemFooter}>
            <figcaption>{file.caption}</figcaption>
          </div>
        )}
      </figure>
    )
  }

  render() {
    const {
      activeIndex,
      newGallery,
      images,
      withoutDownload,
      scope,
      object,
      subject,
    } = this.props

    if (isNil(activeIndex)) {
      return null
    }

    const { opened, active } = this.state

    const classNames = {
      enter: styles.containerEnter,
      enterActive: styles.containerEnterActive,
      exit: styles.containerExit,
      exitActive: styles.containerExitActive,
    }

    if (newGallery && !isNull(active)) {
      const file = images[active]

      return (
        <CSSTransition
          className={styles.containerNew}
          classNames={classNames}
          in={opened}
          timeout={TRANSITION_DURATION}
          onExited={this.close}
        >
          <div>
            {file?.id && (
              <FilesGalleryHeader
                withoutDownload={withoutDownload}
                file={file}
                scope={scope}
                object={object}
                subject={subject}
                onClose={this.hide}
              />
            )}
            <div className={styles.content}>{this.renderItem()}</div>
            {this.renderArrow(false)}
            {this.renderArrow()}
          </div>
        </CSSTransition>
      )
    }

    return (
      <CSSTransition
        className={styles.container}
        classNames={classNames}
        in={opened}
        timeout={TRANSITION_DURATION}
        onExited={this.close}
      >
        <div>
          <div className={styles.content}>
            <Button.Close
              large
              className={styles.btnClose}
              title={this.props.t('close')}
              onClick={this.hide}
            />
            {this.renderItem()}
          </div>
          {this.renderArrow(false)}
          {this.renderArrow()}
        </div>
      </CSSTransition>
    )
  }
}

export default withTranslation('GalleryLightbox')(LightboxGallery)
