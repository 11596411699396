// @flow

import React from 'react'

import { Header, SimpleBlock } from '../Block'
import { useTranslation } from 'react-i18next'
import EmptyList from '../EmptyList/EmptyList'

const ConnectedRequests = () => {
  const { t } = useTranslation('Request')

  return (
    <div className='content__col'>
      <SimpleBlock className='unit unit--default'>
        <Header header>{t('RequestListTitle')}</Header>
        <EmptyList
          noShowAddIcon
          icon='connect'
          title={t('NoConnectedCompanies')}
        />
      </SimpleBlock>
    </div>
  )
}

export default ConnectedRequests
