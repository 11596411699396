// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import classnames from 'classnames'
import { useFormik } from 'formik'
import type { Node } from 'react'

import { renameFile, changeDirectory } from '../../core/api/api.file'
import { FOLDER_TYPE } from './Files.constants'
import { useOverflow } from '../../hooks'
import { trim } from 'lodash-es'

type Props = {
  buttonTitle: string,
  closePopup: () => void,
  id: number,
  name?: ?string,
  setError: string => void,
  setLoading: boolean => void,
  title: string,
  type: number,
}

const RenamingPopup = (props: Props): Node => {
  const { title, buttonTitle, name: initialName, type, id } = props

  const [loading, setLoading] = useState(false)

  useOverflow()

  const { t } = useTranslation('Files')

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      name: initialName || '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(100, t('MaxNameLength'))
        .required(t('NameRequired')),
    }),
    onSubmit: values => {
      if (trim(values.name).length === 0) {
        formik.setFieldValue('name', '')

        return
      }

      setLoading(true)

      let api = renameFile

      if (FOLDER_TYPE === type) {
        api = changeDirectory
      }

      api(id, {
        name: values.name,
      })
        .then(() => {
          formik.setSubmitting(false)
          formik.resetForm()
          setLoading(false)
          props.setLoading(true)
          props.closePopup()
        })
        .catch(error => props.setError(error))
    },
  })

  const handleClose = e => {
    e.preventDefault()

    formik.setSubmitting(false)
    formik.resetForm()

    props.closePopup()
  }

  const blockClassName = classnames(
    'input input--medium input--block input--default',
    { 'input--error': formik.errors.name }
  )

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='modal__title'>{t(title)}</div>
      <button className='modal__close' type='button' onClick={handleClose} />
      <div className='modal__body'>
        <div className='m-a10__row'>
          <div className='m-a10__cell m-a10__cell_1'>
            <span className='m-a10__label'>{t('FileOrFolderName')}</span>
          </div>
          <div className='m-a10__cell m-a10__cell_2'>
            <div className={blockClassName}>
              <input
                autoFocus
                type='text'
                name='name'
                placeholder={t('FolderNamePlaceholder')}
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              {formik.errors.name && (
                <span className='input__msg'>{formik.errors.name}</span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='modal__submit'>
        <button
          disabled={loading}
          className='button button--large button--success'
          type='submit'
        >
          {loading && <span className='button__spinner' />}
          {t(buttonTitle)}
        </button>
        <button
          disabled={loading}
          className='button button--large button--danger-3'
          type='button'
          onClick={handleClose}
        >
          {t('Cancel')}
        </button>
      </div>
    </form>
  )
}

export default RenamingPopup
