// @flow

import React, { useState, useContext, useEffect } from 'react'
import type { Node } from 'react'

import PhaseBody from './PhaseBody/PhaseBody'
import PhaseHeader from './PhaseHeader'
import PhaseItemContext from './PhaseItem.context'
import RequestActivityContext from '../../../RequestActivity/RequestActivity.context'

import {
  getPhase,
  getPhaseActivities,
} from '../../../../../core/api/api.pipeline'

import styles from './PhaseItem.module.scss'

type Props = { index: number, phase: Object }

const PhaseItem = (props: Props): Node => {
  const { index, count } = props
  const {
    isDweller,
    phaseId,
    setPhaseId,
    newPhaseId,
    setNewPhaseId,
    isReloading,
    setReloading,
  } = useContext(RequestActivityContext)

  const [isOpen, setOpen] = useState(false)
  const [activities, setActivities] = useState(null)
  const [phase, setPhase] = useState(props.phase)

  useEffect(() => {
    if (phase && phase.progress < 100) {
      setOpen(true)
    }

    if (phase && phase.progress === 100 && isOpen) {
      setOpen(false)
    }
  }, [phase])

  useEffect(() => {
    if (
      (isOpen && !activities) ||
      (phaseId === phase.id && isReloading) ||
      (newPhaseId === phase.id && isReloading)
    ) {
      if (
        (phaseId === phase.id && isReloading) ||
        (newPhaseId === phase.id && isReloading)
      ) {
        getPhase(phase.id).then(data => {
          setPhase(data)

          if (phaseId === phase.id) {
            setPhaseId(null)
          }

          if (newPhaseId === phase.id) {
            setNewPhaseId(null)
          }
        })
      }

      getPhaseActivities(phase.id).then(data => {
        if (Array.isArray(data)) {
          setActivities(data)
        }
      })
    }

    if (isReloading && index === count - 1) {
      setReloading(false)
    }
  }, [isOpen, isReloading])

  const handleToggle = () => setOpen(!isOpen)

  if (isDweller && activities && !activities.length) {
    return null
  }

  return (
    <PhaseItemContext.Provider value={{ phase, index, isOpen, activities }}>
      <div className={styles['phase-item']}>
        <PhaseHeader onClick={handleToggle} />
        {isOpen && activities && !!activities.length && <PhaseBody />}
      </div>
    </PhaseItemContext.Provider>
  )
}

export default PhaseItem
