// @flow

import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import type { Node } from 'react'

import Button from '../../../components/Button'
import PhaseList from './PhaseList'
import {
  getRequestPipeline,
  updateActivity,
} from '../../../core/api/api.pipeline'
import Loader from '../../../components/Loader'
import EmptyList from '../../../components/EmptyList'
import RequestActivityContext from './RequestActivity.context'
import ThreadModal from './ThreadModal'
import ActivityThreadModal from './ActivityThreadModal'
import PhaseThreadModal from './PhaseThreadModal'
import ActivityFileModal from './ActivityFileModal'
import NewFilesUpload from '../../../components/NewFilesUpload'
import EmailsCreatingModal from '../../../components/Emails/EmailsCreatingModal'
import { getFetchedItems } from '../../../utils/utils'

import styles from './RequestActivity.module.scss'

import { PROVIDER_AUDIENCE, DWELLER_AUDIENCE } from '../../../constants/email'

import { MAIL_SEARCH_SELECT_USERS } from '../../../components/MailSearch/MailSearch.actionTypes'

type Props = {
  buildingId?: number,
  directoryId?: number,
  files?: Array<Object>,
  isDweller: boolean,
  isReloading: boolean,
  isWorker: boolean,
  newPhaseId?: number,
  phaseId?: number,
  requestId?: number,
  requestNo?: number,
  requestTitle?: string,
  selectedCustomers: Array<Object>,
  selectedProviders: Array<Object>,
  setActivity: Object => void,
  setModalOpen: boolean => void,
  setPhaseId: number => void,
  setReloading: boolean => void,
}

const AUTOMATION_TIMEOUT = 5000

const RequestActivity = (props: Props): Node => {
  const {
    requestId,
    directoryId,
    buildingId,
    isDweller,
    isWorker,
    files,
    isReloading,
    setReloading,
    setActivity,
    selectedCustomers,
    selectedProviders,
    setPhaseId,
    setNewPhaseId,
    phaseId,
    newPhaseId,
    automationLoading,
    setAutomationLoading,
    flatId,
  } = props

  const { t } = useTranslation('Activities')
  const dispatch = useDispatch()

  const buttonRef = useRef(null)

  const [threadActivity, setThreadActivity] = useState(null)
  const [threadAudience, setThreadAudience] = useState(null)
  const [emailsCreatingModal, setEmailsCreatingModal] = useState(false)
  const [fileActivityModal, setFileActivityModal] = useState(null)
  const [thread, setThread] = useState(null)
  const [threadModal, setThreadModal] = useState(false)
  const [threadPhase, setThreadPhase] = useState(null)
  const [pipeline, setPipeline] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState(null)
  const [fileActivity, setFileActivity] = useState(null)

  useEffect(() => {
    if (isReloading && !pipeline) {
      getRequestPipeline(requestId).then(data => {
        setPipeline(data)
      })
    }
  }, [isReloading])

  useEffect(() => {
    if (pipeline && isReloading) {
      setReloading(false)
    }
  }, [pipeline])

  useEffect(() => {
    if (requestId || automationLoading) {
      setLoading(true)
      setTimeout(
        () => {
          getRequestPipeline(requestId).then(data => {
            setPipeline(data)
            setLoading(false)
            setAutomationLoading(false)
          })
        },
        automationLoading ? AUTOMATION_TIMEOUT : 0
      )
    }
  }, [requestId, automationLoading])

  useEffect(() => {
    if (
      emailsCreatingModal &&
      (threadAudience === PROVIDER_AUDIENCE ||
        threadAudience === DWELLER_AUDIENCE)
    ) {
      dispatch({
        type: MAIL_SEARCH_SELECT_USERS,
        selectedUsers:
          threadAudience === PROVIDER_AUDIENCE
            ? selectedProviders
            : selectedCustomers,
      })
    }
  }, [emailsCreatingModal])

  useEffect(() => {
    if (uploadedFiles && fileActivity && requestId) {
      updateActivity(fileActivity.activityId, {
        files: [
          ...fileActivity.activityFiles,
          ...uploadedFiles.map(file => file.id),
        ],
      }).then(() => {
        setUploadedFiles(null)
        setUploading(false)
        setFileActivity(null)
        setReloading(true)
      })
    }
  }, [uploadedFiles, fileActivity, requestId])

  useEffect(() => {}, [automationLoading])

  const sectionClass = classNames(
    styles['activity-section'],
    'unit unit--default',
    { 'working-overlay': isReloading || uploading }
  )

  const handleOpen = () => props.setModalOpen(true)
  const handleCloseActivityThread = () => setThreadModal(false)
  const handleClosePhaseThread = () => setThreadPhase(null)
  const handleCloseThread = () => setThread(null)
  const handleCloseFileModal = () => setFileActivityModal(null)
  const handleUpload = activity => {
    setFileActivity(activity)
    buttonRef?.current?.click()
  }
  const handleStartUpload = () => setUploading(true)
  const handleEmailsCreatingModal = data => {
    if (data) {
      const newThreads = getFetchedItems(data) || []

      if (newThreads.length > 0) {
        const { activityId, threads } = threadActivity
        const currentUuids = threads.map(thread => thread.uuid)
        const newUuids = newThreads.map(t => t.request_thread)

        updateActivity(activityId, {
          request_threads: [...currentUuids, ...newUuids],
        }).then(() => setReloading(true))
      }
    }

    setEmailsCreatingModal(false)
  }

  const handleFinishUpload = acceptedFiles => setUploadedFiles(acceptedFiles)

  return (
    <>
      <section className={sectionClass}>
        {pipeline && !!pipeline.length && !isDweller && (
          <Button.Save
            icon='activity'
            iconClassName={styles.icon}
            onClick={handleOpen}
          >
            {t('AddActivityButton')}
          </Button.Save>
        )}
        <NewFilesUpload
          multiple
          name='attach-file'
          imageMaxSize={10}
          onFinishUpload={handleFinishUpload}
          onStartUpload={handleStartUpload}
        >
          <button type='button' style={{ display: 'none' }} ref={buttonRef}>
            Upload file
          </button>
        </NewFilesUpload>
        {isLoading ? (
          <Loader text={false} type='big' />
        ) : pipeline && pipeline.length ? (
          <RequestActivityContext.Provider
            value={{
              setReloading,
              setAutomationLoading,
              isReloading,
              setPhaseId,
              setNewPhaseId,
              phaseId,
              newPhaseId,
              setActivity,
              isDweller,
              setThreadActivity,
              setThreadAudience,
              setThreadPhase,
              setThread,
              handleUpload,
              setThreadModal,
              setFileActivityModal,
              setEmailsCreatingModal,
            }}
          >
            <PhaseList pipeline={pipeline} isDweller={isDweller} />
          </RequestActivityContext.Provider>
        ) : (
          <EmptyList
            noShowAddIcon
            canAdd={!isDweller && !isWorker}
            icon='activity'
            title={t('ActivitiesNotFound')}
            btnText={t('AddNewActivity')}
            onClick={handleOpen}
          />
        )}
      </section>
      {threadModal && (
        <ThreadModal
          activity={threadActivity}
          requestId={requestId}
          setReloading={setReloading}
          setAutomationLoading={setAutomationLoading}
          onClose={handleCloseActivityThread}
        />
      )}
      {threadPhase && (
        <PhaseThreadModal
          phase={threadPhase}
          requestId={requestId}
          buildingId={buildingId}
          directoryId={directoryId}
          files={files}
          onClose={handleClosePhaseThread}
        />
      )}
      {thread && (
        <ActivityThreadModal
          activity={thread}
          requestId={requestId}
          buildingId={buildingId}
          files={files}
          directoryId={directoryId}
          onClose={handleCloseThread}
        />
      )}
      {fileActivityModal && (
        <ActivityFileModal
          requestId={requestId}
          data={fileActivityModal}
          setReloading={setReloading}
          setPhaseId={setPhaseId}
          onClose={handleCloseFileModal}
        />
      )}
      {emailsCreatingModal && (
        <EmailsCreatingModal
          isOpen={emailsCreatingModal}
          requestId={requestId}
          buildingId={buildingId}
          flatId={flatId}
          directoryId={directoryId}
          audience={threadAudience}
          onClose={handleEmailsCreatingModal}
        />
      )}
    </>
  )
}

const mapStateToProps = state => ({
  ...state.mailSearch,
})

export default connect(mapStateToProps)(RequestActivity)
