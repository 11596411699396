// @flow

import React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import Thread from '../../../../components/Emails/Thread'
import Button from '../../../../components/Button'
import Modal from '../../../../components/Modal'
import ModalBody from '../../../../components/Modal/ModalBody'
import ModalCloseButton from '../../../../components/Modal/ModalCloseButton'
import ModalHead from '../../../../components/Modal/ModalHead'
import { getRequestThreads } from '../../../../core/api/api.request'
import NewTable from '../../../../components/NewTable'
import NewTableHeader from '../../../../components/NewTable/NewTableHeader'
import NewTableHeaderRow from '../../../../components/NewTable/NewTableHeaderRow'
import NewTableHeaderCell from '../../../../components/NewTable/NewTableHeaderCell'
import ModalButtons from '../../../../components/Modal/ModalButtons'
import NewTableBody from '../../../../components/NewTable/NewTableBody'
import NewTableBodyRow from '../../../../components/NewTable/NewTableBodyRow'
import NewTableBodyCell from '../../../../components/NewTable/NewTableBodyCell'
import {
  getFetchedItems,
  getFormattedDate,
  getUserName,
} from '../../../../utils/utils'
import { useOverflow } from '../../../../hooks'

import styles from './PhaseThreadModal.module.scss'

type Props = {
  buildingId?: number,
  directoryId: number,
  onClose: () => void,
  phase: Object,
  requestId: number,
}

const PhaseThreadModal = (props: Props): Node => {
  const {
    requestId,
    directoryId,
    buildingId,
    phase: { id },
    files,
  } = props

  const { t } = useTranslation('Request')

  useOverflow()

  const [threads, setThreads] = useState(null)
  const [thread, setThread] = useState(null)

  useEffect(() => {
    getRequestThreads(requestId, { phase: id })
      .then(getFetchedItems)
      .then(setThreads)
  }, [])

  const handleClose = () => {
    if (thread) {
      setThread(null)
    } else {
      props.onClose()
    }
  }

  if (!threads) {
    return null
  }

  const handleClick = threadUuid => {
    const currentThread = threads.find(thread => thread.uuid === threadUuid)

    if (currentThread) {
      setThread(currentThread)
    }
  }

  return (
    <Modal
      isOpen
      style={{
        content: { minWidth: '732px', maxWidth: '732px' },
      }}
      onRequestClose={handleClose}
    >
      {thread ? (
        <>
          <ModalHead title={t('ThreadViewTitle')} />
          <ModalCloseButton onClose={handleClose} />
          <ModalBody style={{ maxHeight: '540px', overflow: 'auto' }}>
            <Thread
              isDeny
              uuid={thread.uuid}
              requestId={requestId}
              buildingId={buildingId}
              files={files}
              audience={thread.audience}
              subject={thread.title}
              directoryId={directoryId}
            />
          </ModalBody>
        </>
      ) : (
        <>
          <ModalHead title={t('PhaseAttachedThreadsTitle')} />
          <ModalCloseButton onClose={props.onClose} />
          <ModalBody style={{ maxHeight: '604px', overflow: 'auto' }}>
            <NewTable>
              <NewTableHeader>
                <NewTableHeaderRow>
                  <NewTableHeaderCell
                    style={{ width: '30%' }}
                    title={t('OwnerName')}
                  />
                  <NewTableHeaderCell
                    title={t('Subject')}
                    style={{ width: '30%' }}
                  />
                  <NewTableHeaderCell
                    title={t('ThreadUpdated')}
                    style={{ width: '10%' }}
                  />
                  <NewTableHeaderCell
                    title={t('ActivityList')}
                    style={{ width: '30%' }}
                  />
                </NewTableHeaderRow>
              </NewTableHeader>
              {threads.map(thread => (
                <NewTableBody
                  hovered
                  key={thread.uuid}
                  onClick={() => handleClick(thread.uuid)}
                >
                  <NewTableBodyRow hovered={false} className={styles.row}>
                    <NewTableBodyCell
                      title={getUserName(thread.last_message.owner_obj)}
                    />
                    <NewTableBodyCell title={thread.title} />
                    <NewTableBodyCell
                      title={getFormattedDate(thread.last_message.created)}
                    />
                    <NewTableBodyCell
                      title={thread.activities
                        .map(activity => activity.title)
                        .join(', ')}
                    />
                  </NewTableBodyRow>
                  {thread.notes && (
                    <NewTableBodyRow hovered={false}>
                      <NewTableBodyCell colSpan={4}>
                        <div className={styles.notes}>
                          <span>{thread.notes}</span>
                        </div>
                      </NewTableBodyCell>
                    </NewTableBodyRow>
                  )}
                </NewTableBody>
              ))}
            </NewTable>
          </ModalBody>
          <ModalButtons>
            <Button.Cancel onClick={props.onClose}>
              {t('Common:Ok')}
            </Button.Cancel>
          </ModalButtons>
        </>
      )}
    </Modal>
  )
}

export default PhaseThreadModal
