// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { get } from 'lodash-es'

import { Row } from '../../../../Block'

import styles from './Address.module.scss'

type Props = {
  building: ?Object,
  flat: ?Object,
  onEdit?: () => void,
}

const Address = (props: Props) => {
  const { building, flat } = props

  const flatNumber = get(flat, 'number')
  const flatId = get(flat, 'id')
  const address = get(building, 'address')
  const buildingId = get(building, 'id')

  const { t } = useTranslation('Request')

  const renderAddress = () => (
    <>
      <div className={styles.value}>
        {buildingId ? (
          <Link to={`/building/${buildingId}`}>{address}</Link>
        ) : (
          address
        )}
      </div>
      {flatNumber ? (
        <div className={styles.value}>
          {flatId ? (
            <Link to={`/flat/${flatId}`}>
              {t('flat_number_title')} {flatNumber}
            </Link>
          ) : (
            `${t('flat_number_title')} ${flatNumber}`
          )}
        </div>
      ) : null}
    </>
  )

  return (
    <Row title={t('Address')} onEdit={props.onEdit}>
      {address ? (
        renderAddress()
      ) : (
        <span className='bar__cell-value'>{t('NotSpecified')}</span>
      )}
    </Row>
  )
}

export default Address
