// @flow

import React, { useContext } from 'react'
import type { Node } from 'react'

import ExpandArrow from '../../../../../../../../components/ExpandArrow'
import Icon from '../../../../../../../../components/Icon'
import PhaseIcon from './Icon'
import { togglePhaseVisibility } from '../../../../../../../../core/api/api.pipeline'
import PhaseItemContext from '../../../PhaseItem.context'
import RequestActivityContext from '../../../../../RequestActivity.context'

import styles from './Icons.module.scss'

const Icons = (): Node => {
  const {
    isOpen,
    phase: { id, visible_to_tenant },
  } = useContext(PhaseItemContext)

  const { setReloading, isDweller, setPhaseId } = useContext(
    RequestActivityContext
  )

  const direction = isOpen ? 'bottom' : 'top'

  const handleClcick = () =>
    togglePhaseVisibility(id).then(() => {
      setPhaseId(id)
      setReloading(true)
    })

  return (
    <div className={styles.icons}>
      {!isDweller && (
        <PhaseIcon onClick={handleClcick}>
          <Icon id={visible_to_tenant ? 'showEye' : 'hideEye'} />
        </PhaseIcon>
      )}
      <PhaseIcon>
        <ExpandArrow direction={direction} />
      </PhaseIcon>
    </div>
  )
}

export default Icons
